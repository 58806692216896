import { Modal, Text } from "app/components";
import { rApp, rNewDataSourceModalData, rOrganization } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import Airtable from "./Airtable";
import FrontlyDatabase from "./Frontly";
import Google from "./Google";
import Supabase from "./Supabase";
import { dataSourceIconMap } from "../utils";
import { errorNotification } from "app/utils/Notification";
import { get } from "lodash";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const NewDataSourceModal = () => {
  const [newDataSource, setNewDataSource] = useRecoilState(
    rNewDataSourceModalData
  );
  const type = get(newDataSource, "type");
  const navigate = useNavigate();

  const app = useRecoilValue(rApp);

  const organization = useRecoilValue(rOrganization);

  const supabaseProjectId = get(app, "supabase_project_id");

  const isSupabaseIntegrated = supabaseProjectId && get(app, "supabase_key");
  const airtableConnected = !!get(app, "airtable_token");
  const googleConnected = get(app, "google_sheets_connected");

  const hasFrontlyDb = get(organization, "enable_frontly_db");

  // Show the data source selection modal if no type is selected
  if (newDataSource && !type) {
    let dataSourceOptions = [
      // GOOGLE
      {
        type: "google",
        name: "Google Sheets",
        description:
          "Connect your app to your Google spreadsheets to use as a database",
        onClick: () => {
          if (googleConnected) {
            setNewDataSource({ type: "google" });
          } else {
            errorNotification("Please connect your Google Sheets account");
            navigate("/spreadsheets?tab=accountConnections");
          }
        },
      },
      // SUPABASE
      {
        type: "supabase",
        name: "Supabase",
        description: "Connect a scalable and secure database to your app.",
        onClick: () => {
          if (isSupabaseIntegrated) {
            setNewDataSource({ type: "supabase" });
          } else {
            errorNotification(
              "Please enter your Supabase Project ID and Key in the Settings / Integrations tab first."
            );
          }
        },
      },
      // AIRTABLE
      {
        type: "airtable",
        name: "Airtable (Beta)",
        description: "Use an Airtable database to power your Frontly app.",
        onClick: () => {
          if (airtableConnected) {
            setNewDataSource({ type: "airtable" });
          } else {
            errorNotification("Please connect your Airtable account");
            navigate("/spreadsheets?tab=accountConnections");
          }
        },
      },
    ];

    if (hasFrontlyDb) {
      // FRONTLY DB
      dataSourceOptions = [
        {
          type: "frontly_db",
          name: "Frontly Database (Beta)",
          description:
            "Connect to a Frontly managed database for seamless integration.",
          icon: get(dataSourceIconMap, "frontly_db"),
          onClick: () => setNewDataSource({ type: "frontly" }),
        },
        ...dataSourceOptions,
      ];
    }

    return (
      <Modal
        minWidth="fit-content"
        hide={() => setNewDataSource(null)}
        header={{
          title: "Select a data source",
        }}
      >
        <DataSourcesContainer hasFrontlyDb={hasFrontlyDb}>
          {dataSourceOptions.map((o) => (
            <DataSourceCard
              name={o.name}
              description={o.description}
              icon={get(dataSourceIconMap, o.type)}
              onClick={o.onClick}
            />
          ))}
        </DataSourcesContainer>
      </Modal>
    );
  }

  // If type is selected, show the corresponding data source import modal
  switch (type) {
    case "airtable":
      return <Airtable />;
    case "supabase":
      return <Supabase />;
    case "google":
      return <Google />;
    case "frontly":
      return <FrontlyDatabase />;
    default:
      return null;
  }
};

export default NewDataSourceModal;

const DataSourceCard = ({ name, description, onClick, icon, disabled }) => {
  return (
    <DataSourceContainer onClick={onClick} disabled={disabled}>
      <DataSourceIcon src={icon} />
      <Text
        data={{
          text: name,
          fontStyle: "headingLg",
          textAlign: "center",
          cursor: disabled ? "not-allowed" : "pointer",
        }}
      />
      <Text
        data={{
          text: description,
          textAlign: "center",
          cursor: disabled ? "not-allowed" : "pointer",
        }}
      />
    </DataSourceContainer>
  );
};

const DataSourcesContainer = styled.div`
  ${(p) =>
    p.hasFrontlyDb
      ? `
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
    `
      : `

    display: flex;
    gap: 20px;
    
    `}
`;

const DataSourceIcon = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-bottom: 10px;
`;

const DataSourceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  padding: 20px;
  width: 300px;
  border-radius: 10px;
  background: white;
  box-shadow: rgba(29, 29, 31, 0.05) 0px 4px 5px;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  transition: box-shadow 0.2s;
  border: 1px solid var(--grey21);
  ${(p) => p.disabled && `opacity: 0.6;`}
  &:hover {
    box-shadow: rgba(29, 29, 31, 0.1) 0px 8px 10px;
  }
`;
