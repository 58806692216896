import { Icon, Row } from "app/components";

import { colors } from "app/utils/theme";
import { get } from "lodash";
import styled from "styled-components";

const PaddingConfig = ({ data }) => {
  const padding = get(data, "value", {});

  const onChange = (k, v) => {
    data.onChange({
      ...padding,
      [k]: v,
    });
  };

  const iconMap = {
    top: "FiArrowUp",
    right: "FiArrowRight",
    bottom: "FiArrowDown",
    left: "FiArrowLeft",
  };

  return (
    <div>
      <Container>
        <Row>
          {["all", "top", "right", "bottom", "left"].map((l) => {
            const icon = get(iconMap, l);

            if (icon) {
              return (
                <Label style={{ padding: "0px" }}>
                  <Icon
                    data={{
                      icon,
                      size: 16,
                      color: "var(--grey7)",
                      margin: "1px 0 0 0",
                    }}
                  />
                </Label>
              );
            }

            return <Label>All</Label>;
          })}
        </Row>
        <Row style={{ borderTop: `1px solid var(--grey21)` }}>
          {["all", "top", "right", "bottom", "left"].map((k) => (
            <StyledInput
              value={get(padding, k, "")}
              type="number"
              onChange={(e) => onChange(k, e.target.value)}
            />
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default PaddingConfig;

const Container = styled.div`
  border: 1px solid ${colors.pitchBorder};
  border-radius: 8px;
  background: white;
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  border: 0px;
  color: var(--grey7);
  :not(:last-child) {
    border-right: 1px solid ${colors.pitchBorder};
  }
  flex: 1;
  width: calc(100% / 5);
`;

const StyledInput = styled.input`
  border: 0px;
  :not(:last-child) {
    border-right: 1px solid ${colors.pitchBorder};
  }
  background: transparent;
  flex: 1;
  width: calc(100% / 5);
  padding: 5px;
  text-align: center;
`;
