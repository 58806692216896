import { Button, Icon, Menu, Text } from "app/components";
import { Container, Draggable } from "@edorivai/react-smooth-dnd";
import { arrayMove, getHighest } from "app/utils/utils";
import { colors, spacing } from "app/utils/theme";
import { get, startCase } from "lodash";
import { useEffect, useState } from "react";

import AdminForm from "./AdminForm";
import { rActiveEditField } from "app/utils/recoil";
import styled from "styled-components";
import useActiveBlock from "app/utils/useActiveBlock";
import { useRecoilState } from "recoil";

const DynamicFields = ({ data }) => {
  const [editField, setEditField] = useState(false);

  let newObj = get(data, "newObject", {
    key: null,
    fontStyle: "bodyMd",
  });

  const fields = get(data, "value", []).map((f, i) => ({
    id: f.id || i + 1, // auto populate (backfill) id if not present. should get saved after any change
    ...f,
  }));

  const [activeEditField, setActiveEditField] =
    useRecoilState(rActiveEditField);

  const activeBlock = useActiveBlock();

  useEffect(() => {
    if (
      activeEditField &&
      get(activeEditField, "blockId") === get(activeBlock, "id") &&
      get(activeEditField, "blockId") === get(data, "block")
    ) {
      setEditField(activeEditField.id);
      setAnchorElement(activeEditField.target);
    }
  }, [activeEditField]);

  useEffect(() => {
    if (
      activeEditField &&
      get(activeEditField, "blockId") !== get(data, "block")
    ) {
      setActiveEditField(null);
    }
  }, [activeEditField]);

  const addField = () => {
    const newId = getHighest(fields, "id") + 1;
    data.onChange([
      ...fields,
      {
        ...newObj,
        id: newId,
      },
    ]);
    setEditField(newId);
  };

  const updateField = (fieldKey, key, value) => {
    const newFields = fields.map((f) => {
      if (f.id === fieldKey) {
        return { ...f, [key]: value };
      }
      return f;
    });
    data.onChange(newFields);
  };

  const deleteField = (fieldId) => {
    const newFields = fields.filter((f) => f.id !== fieldId);
    data.onChange(newFields);
  };

  const activeField = fields.find((f) => f.id === editField);

  const editFields = get(data, "keys", []).map((f) => ({
    ...f,
    value: get(activeField, f.id),
  }));

  const [anchorElement, setAnchorElement] = useState(null);

  return (
    <div>
      <Menu
        anchorElement={anchorElement}
        hide={() => {
          setAnchorElement(null);
          setEditField(null);
        }}
        label={
          data.customLabel ||
          (get(activeField, "column")
            ? startCase(get(activeField, "column"))
            : "New Field")
        }
      >
        <AdminForm
          sectionPadding="0px"
          fields={editFields}
          onChange={(k, v) => updateField(editField, k, v)}
        />
      </Menu>

      {fields.length > 0 && (
        <Container
          style={{
            border: `1px solid var(--grey21)`,
            display: "flex",
            borderRadius: "8px",
            flexDirection: "column",
            background: "white",
          }}
          dragHandleSelector=".drag-item"
          lockAxis="y"
          onDrop={(e) => {
            const { addedIndex, removedIndex } = e;
            const movedFields = arrayMove(fields, removedIndex, addedIndex);
            data.onChange(movedFields);
          }}
        >
          {fields.map((f, fid) => (
            <Draggable key={fid}>
              <Field
                field={f}
                setAnchorElement={setAnchorElement}
                editField={() => setEditField(f.id)}
                deleteField={() => deleteField(f.id)}
                getLabel={data.getLabel}
              />
            </Draggable>
          ))}
        </Container>
      )}
      {fields.length === 0 && <Text data={{ text: "No Fields" }} />}

      <Button
        data={{
          text: "Add",
          onClick: addField,
          size: "small",
          type: "basic",
          icon: "FiPlus",
          margin: "5px 0 0 0",
        }}
      />
    </div>
  );
};

export default DynamicFields;

const Field = ({
  field,
  editField,
  deleteField,
  setAnchorElement,
  getLabel,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <FieldContainer
      onMouseOver={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      onClick={(e) => {
        setAnchorElement(e.currentTarget);
        editField();
      }}
      className="drag-item"
    >
      <Text
        data={{
          text:
            (getLabel && getLabel(field)) ||
            startCase(field.key) ||
            "New Field",
          cursor: "pointer",
        }}
      />
      {isHovering && (
        <Icon
          data={{
            icon: "FiTrash",
            hover: true,
            onClick: (e) => {
              e.stopPropagation();
              deleteField();
            },
            color: "var(--grey7)",
            size: 18,
          }}
        />
      )}
    </FieldContainer>
  );
};

const FieldContainer = styled.div`
  padding: ${spacing.s3};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: 200ms all;
  :not(:last-child) {
    border-bottom: 1px solid var(--grey1);
  }
  &:hover {
    background: var(--grey1);
  }
`;
