import { Button, Modal, Row, Text } from "app/components";
import { apiRequest, handleError } from "app/utils/apiRequests";
import { boxShadow, colors, spacing } from "app/utils/theme";
import { errorNotification, successNotification } from "app/utils/Notification";
import {
  findDefaultPage,
  getCurrentDomain,
  getPixels,
  safeArray,
} from "app/utils/utils";
import { get, isEmpty, startCase } from "lodash";
import {
  rApp,
  rPages,
  rTranslations,
  userStateSelector,
} from "app/utils/recoil";
import { useRecoilValue, useSetRecoilState } from "recoil";

import AdminForm from "../components/AdminForm";
import Cookies from "js-cookie";
import { Checkbox as MUICheckbox } from "@mui/material";
import MadeWithFrontly from "../components/MadeWithFrontly";
import { RoundIconWrapper } from "../components/Navigation/ClientNavigation";
import TemplateBanner from "app/renderPage/TemplateBanner";
import { demoSubdomains } from "../new_templates/templates";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";
import useActionResolver from "app/renderingApp/useActionResolver";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import useUtils from "app/renderingApp/useUtils";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const Login = ({ type = "login" }) => {
  const navigate = useNavigate();

  const [termsAccepted, setTermsAccepted] = useState(false);

  const [isSendingPasswordEmail, setIsSendingPasswordEmail] = useState(false);

  const setUser = useSetRecoilState(userStateSelector);

  const { passesDisplayConditions } = useUtils();

  const translations = useRecoilValue(rTranslations);

  const app = useRecoilValue(rApp);

  const buttonBorderRadius = get(app, ["styling", "buttonBorderRadius"]);

  const pages = useRecoilValue(rPages);

  const primaryColor = "var(--primary-admin)";

  const userGroups = safeArray(app, "user_groups");
  const userSignupAction = get(app, "user_signup_action");
  const userLoginAction = get(app, "user_login_action");

  const styling = get(app, "styling");
  const blockBorderRadius = get(styling, "blockBorderRadius");

  const hideBranding = get(app, "hide_branding");

  const [isFetching, setIsFetching] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const authentication = get(app, "authentication", {});

  // Public Text Overrides
  const bgPrimaryColor = get(authentication, "authBgColorPrimary");
  const bgSecondaryColor = get(authentication, "authBgColorSecondary");
  const signupLinkTextColor = get(authentication, "signupLinkTextColor");
  const forgotPasswordTextColor = get(
    authentication,
    "forgotPasswordTextColor"
  );
  const loginHeader = get(authentication, "loginHeader") || "Login";
  const signupHeader = get(authentication, "signupHeader") || "Sign Up";
  const emailLabel = get(authentication, "emailLabel") || "Email";
  const passwordLabel = get(authentication, "passwordLabel") || "Password";
  const firstNameLabel = get(authentication, "firstNameLabel") || "First Name";
  const lastNameLabel = get(authentication, "lastNameLabel") || "Last Name";
  const forgotPasswordLink =
    get(authentication, "forgotPasswordLink") || "Forgot Password";
  const noAccountLink =
    get(authentication, "noAccountLink") || "No account? Sign up";
  const haveAccountLink =
    get(authentication, "haveAccountLink") || "Have an account? Login";

  const enterEmailPasswordReset =
    get(authentication, "enterEmailPasswordReset") ||
    "Enter your email to request a password reset link";

  const sendPasswordResetEmail =
    get(authentication, "sendPasswordResetEmail") ||
    "Send Password Reset Email";

  const emailSent =
    get(authentication, "emailSent") || "Password Reset Email Sent";

  const requireTerms =
    type === "signup" && get(authentication, "requireTerms", false);
  const termsText = get(authentication, "termsText", "I agree to the");
  const termsLinkText = get(
    authentication,
    "termsLinkText",
    "Terms & Conditions"
  );
  const termsLink = get(authentication, "termsLink");

  const [state, setState] = useState({
    email: "",
    password: "",
    first_name: "",
    last_name: "",
  });
  const [errors, setErrors] = useState({});

  const logoIcon = true;

  const { handleCustomAction } = useActionResolver();

  const validate = () => {
    // Validate form values

    let errors = {};

    if (!emailRegex.test(state.email)) {
      errors["email"] = get(
        translations,
        "invalidEmail",
        "Please enter a valid email"
      );
    }

    if (state.password.length < 5) {
      errors["password"] = get(
        translations,
        "invalidPassword",
        "Please enter a more secure password"
      );
    }

    setErrors(errors);

    return isEmpty(errors);
  };

  const customSignupFields = safeArray(app, "user_schema")
    .filter((f) => f.includeInSignup)
    .map((f) => ({
      id: f.name,
      label: startCase(f.name),
      componentId: f.type,
      value: get(state, f.name),
      options: f.options,
      // columnSpan: 1,
    }));

  const login = () => {
    // Make login request
    if (validate()) {
      setIsFetching(true);

      let finalData = { ...state, domain: getCurrentDomain() };

      let customValues = customSignupFields.map((f) => ({
        key: f.id,
        value: f.value,
      }));

      finalData["custom_values"] = customValues;

      let endpoint = type === "signup" ? "/client_signup/" : "/client_login/";

      apiRequest
        .post(endpoint, finalData)
        .then((response) => {
          handleSignupResponse(response);
        })
        .catch((e) => {
          const { message } = handleError(e);
          setErrors({ password: "Invalid email or password" });
          setIsFetching(false);
          errorNotification(message);
        });
    }
  };

  let fields = [
    {
      id: "email",
      label: emailLabel,
      placeholder: emailLabel,
      componentId: "Input",
      // columnSpan: 2,
    },
    {
      id: "password",
      label: passwordLabel,
      placeholder: passwordLabel,
      type: "password",
      componentId: "Input",
      // columnSpan: 2,
    },
  ];

  if (type === "signup") {
    fields = [
      {
        id: "first_name",
        label: firstNameLabel,
        placeholder: firstNameLabel,
        componentId: "Input",
        // columnSpan: 1,
      },
      {
        id: "last_name",
        label: lastNameLabel,
        placeholder: lastNameLabel,
        componentId: "Input",
        // columnSpan: 1,
      },
      ...customSignupFields,
      ...fields,
    ];
  }

  const renderLogo = () => {
    if (app.logo) {
      return <ClientLogo src={app.logo} />;
    }

    const fontFamily = get(app, "logo_font");
    const firstTwoChars = app?.name?.slice(0, 2);

    return (
      <Row gap="8px" alignItems="center" margin="0 0 30px 0">
        {logoIcon && (
          <RoundIconWrapper background={primaryColor}>
            <Text
              data={{
                text: firstTwoChars,
                fontStyle: "headingLg",
                color: "white",
              }}
            />
          </RoundIconWrapper>
        )}
        <AppName fontFamily={fontFamily}>{app.name}</AppName>
      </Row>
    );
  };

  const isDemoApp = demoSubdomains.includes(get(app, "subdomain"));

  const handleSignupResponse = (response) => {
    const error = get(response, ["data", "error"]);

    // Handle email verification
    if (type === "login") {
      const resData = get(response, ["data"]);

      const emailVerificationRequired = get(resData, [
        "app",
        "email_verification_required",
      ]);

      if (emailVerificationRequired) {
        const resUser = get(resData, ["user"]);
        setUser(resUser);
        navigate("/verify-email");
        return;
      }
    }

    if (error) {
      errorNotification(error);
      setIsFetching(false);
      setErrors({ password: "Invalid email or password" });
    } else {
      const user = get(response, ["data", "user"]);
      const token = get(response, ["data", "token"]);
      const responseType = get(response, ["data", "type"]);

      Cookies.set("accessToken", token, { expires: 30 });
      setIsFetching(false);

      const customVariables = get(response, ["data", "custom_variables"], {});

      const defPage = get(app, "default_page");
      const fallback = get(pages, 0, {});
      const defaultPage = pages.find((p) => p.id === defPage) || fallback;

      if (responseType === "signup") {
        mixpanel.track("Client User Signup");
      } else {
        mixpanel.track("Client User Login");
      }

      if (pages.length === 0) {
        errorNotification(
          "This app has no pages. Please create pages as an admin before trying to login as an end-user."
        );
      } else {
        if (user) {
          setUser(user);

          const defPage = findDefaultPage({
            user,
            userGroups,
            pages,
            defaultPage,
            app,
            passesDisplayConditions,
          });

          if (responseType === "signup") {
            if (userSignupAction) {
              handleCustomAction({
                rawAction: userSignupAction,
                action: userSignupAction,
                context: { user, custom: customVariables },
              });
            }

            const signupActionHasNavigateStep = get(
              userSignupAction,
              "steps",
              []
            ).find((s) => s.type === "NAVIGATE");

            const skipDefaultPage =
              signupActionHasNavigateStep &&
              get(app, "disable_signup_default_page");
            if (!skipDefaultPage) {
              navigate(defPage);
            }
          } else {
            if (userLoginAction) {
              handleCustomAction({
                rawAction: userLoginAction,
                action: userLoginAction,
                context: { user, custom: customVariables },
              });
            }

            const loginActionHasNavigateStep = get(
              userLoginAction,
              "steps",
              []
            ).find((s) => s.type === "NAVIGATE");

            const skipDefaultPage =
              loginActionHasNavigateStep &&
              get(app, "disable_login_default_page");
            if (!skipDefaultPage) {
              navigate(defPage);
            }
          }
        }
      }
    }
  };

  const bgColor = bgSecondaryColor
    ? `linear-gradient(-45deg, ${bgPrimaryColor}, ${bgSecondaryColor})`
    : bgPrimaryColor;

  return (
    <BackgroundDiv
      style={{
        background: bgColor,
      }}
    >
      {isDemoApp && <TemplateBanner template={get(app, "subdomain")} />}
      <Container isDemoApp={isDemoApp}>
        {!hideBranding && <MadeWithFrontly />}
        {showForgotPassword && (
          <Modal
            hide={() => setShowForgotPassword(false)}
            header={{
              title: forgotPasswordLink,
              description: enterEmailPasswordReset,
            }}
          >
            <AdminForm
              isFetching={isSendingPasswordEmail}
              submit={() => {
                setIsSendingPasswordEmail(true);
                apiRequest
                  .post("/forgot_password/", {
                    email: state.email,
                    domain: getCurrentDomain(),
                  })
                  .then((response) => {
                    successNotification(emailSent);
                    setShowForgotPassword(false);
                    setIsSendingPasswordEmail(false);
                    mixpanel.track("Reset Password");
                  })
                  .catch((error) => {
                    const msg = get(error, ["response", "data", "error"]);
                    errorNotification(msg);
                    setIsSendingPasswordEmail(false);
                  });
              }}
              errors={errors}
              submitText={sendPasswordResetEmail}
              onChange={(k, v) => setState((s) => ({ ...s, [k]: v }))}
              sectionPadding="0px"
              fields={[
                {
                  id: "email",
                  componentId: "Input",
                  label: emailLabel,
                  value: state.email,
                },
              ]}
            />
          </Modal>
        )}

        {renderLogo()}

        <FormContainer
          borderRadius={blockBorderRadius}
          width={requireTerms ? "360px" : "320px"}
        >
          <Text
            data={{
              text: type === "login" ? loginHeader : signupHeader,
              fontStyle: "headingMd",
            }}
          />
          <AdminForm
            sectionPadding="0px"
            fields={fields}
            errors={errors}
            buttonColor={primaryColor}
            buttonFullWidth
            isFetching={isFetching}
            onChange={(k, v) => setState((s) => ({ ...s, [k]: v }))}
          />

          {requireTerms && (
            <Row alignItems="center" gap="10px" margin="5px 0 0 0">
              <MUICheckbox
                color="primary"
                checked={termsAccepted}
                onChange={() => setTermsAccepted(!termsAccepted)}
                value={termsAccepted}
                style={{ padding: "0px" }}
                size="small"
              />

              <div style={{ fontSize: 14 }}>
                {termsText}{" "}
                <span
                  onClick={() => window.open(termsLink)}
                  style={{
                    color: "var(--primary-admin)",
                    fontWeight: 600,
                    cursor: "pointer",
                  }}
                >
                  {termsLinkText}
                </span>
              </div>
            </Row>
          )}

          <Button
            data={{
              text: type === "login" ? loginHeader : signupHeader,
              onClick: login,
              width: "100%",
              padding: "10px",
              disabled: requireTerms && !termsAccepted && type === "signup",
              size: "large",
              margin: "5px 0 0 0",
              isFetching,
              backgroundColor: "var(--primary)",
              borderRadius: buttonBorderRadius,
            }}
          />
        </FormContainer>

        {(type !== "login" || get(app, ["authentication", "enableSignup"])) && (
          <Text
            data={{
              text: type === "signup" ? haveAccountLink : noAccountLink,
              onClick: () => navigate(type === "signup" ? "/login" : "/signup"),
              fontStyle: "headingMd",
              margin: "30px 0 0 0",
              color: signupLinkTextColor || "var(--primary-admin)",
            }}
          />
        )}

        {!isDemoApp && (
          <Text
            data={{
              text: forgotPasswordLink,
              onClick: () => setShowForgotPassword(true),
              fontStyle: "bodyMd",
              color: forgotPasswordTextColor,
              margin: "30px 0 0 0",
            }}
          />
        )}

        {isDemoApp && type === "login" && (
          <DemoAppLogin>
            <Text
              data={{
                text: "This is a demo app. To login, use the following credentials:",
              }}
            />
            <Row gap="5px" margin="10px 0 0 0">
              <Text
                data={{
                  text: "Email:",
                  fontStyle: "headingSm",
                }}
              />
              <Text
                data={{
                  allowSelect: true,
                  text: `${get(app, "subdomain")}-test@frontlyapp.com`,
                }}
              />
            </Row>

            <Row gap="5px">
              <Text
                data={{
                  text: "Password:",
                  fontStyle: "headingSm",
                }}
              />
              <Text
                data={{
                  allowSelect: true,
                  text: "test-password874",
                }}
              />
            </Row>
          </DemoAppLogin>
        )}
      </Container>
    </BackgroundDiv>
  );
};

export default Login;

const AppName = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${(p) => p.color};
  ${(p) => p.fontFamily && `font-family: ${p.fontFamily};`};
  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
`;

const BackgroundDiv = styled.div`
  position: fixed;
  top: ${(p) => (p.isDemoApp ? "60px" : "0px")};
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  @media screen and (max-width: 800px) {
    position: initial;
    overflow: auto;
    justify-content: flex-start;
    margin-top: 20px;
  }
`;

export const FormContainer = styled.div`
  padding: 32px;
  background: white;
  box-shadow: ${boxShadow.card};
  display: flex;
  flex-direction: column;
  gap: ${spacing.s3};
  border-radius: ${(p) => getPixels(p.borderRadius || 8)};
  width: ${(p) => p.width || "300px"};
  @media screen and (max-width: 600px) {
    width: 86%;
    padding: 22px;
  }
`;

export const Logo = styled.img`
  height: 50px;
  margin: 0 0 30px 0;
`;

export const ClientLogo = styled.img`
  height: 100px;
  width: 240px;
  object-fit: contain;
  margin: 0 0 30px 0;
  @media screen and (max-width: 600px) {
    height: 60px;
    width: 180px;
  }
`;

const DemoAppLogin = styled.div`
  border: 1px dashed var(--grey7);
  padding: 20px;
  border-radius: 10px;
  margin-top: 30px;
`;
