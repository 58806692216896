import { AppButton, AppButtonsRow } from "../styles";
import { Icon, Text } from "app/components";

import Cookies from "js-cookie";
import { Main } from "../components";
import { colors } from "app/utils/theme";
import { openPageInNewTab } from "app/utils/utils";
import { rApp } from "app/utils/recoil";
import { rFirstPage } from "../state";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

// App Completion Page
const Complete = () => {
  const firstPage = useRecoilValue(rFirstPage);

  const app = useRecoilValue(rApp);

  const navigate = useNavigate();

  const token = Cookies.get("accessToken");

  return (
    <Main
      label="Your App Is Complete"
      description="Your app is ready to view and customize in the no-code app editor."
    >
      <AppButtonsRow>
        <AppButton
          onClick={() =>
            openPageInNewTab({
              page: firstPage,
              app,
              token,
            })
          }
        >
          <Icon
            data={{ icon: "FiEye", color: "#d0d3d6", size: 45, hover: true }}
          />
          <Text
            data={{
              text: "View Live App",
              fontStyle: "headingLg",
              color: "var(--grey8)",
              cursor: "pointer",
            }}
          />
        </AppButton>
        <AppButton onClick={() => navigate(`/page/${firstPage.id}`)}>
          <Icon
            data={{
              icon: "FiEdit",
              color: "#d0d3d6",
              size: 45,
              hover: true,
            }}
          />
          <Text
            data={{
              text: "Open No-Code Editor",
              fontStyle: "headingLg",
              color: "var(--grey8)",
              cursor: "pointer",
            }}
          />
        </AppButton>
      </AppButtonsRow>
    </Main>
  );
};

export default Complete;
