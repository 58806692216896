import { Button, Icon, Spinner, Text } from "app/components";
import { apiRequest, frontlyBaseApi, webUrl } from "app/utils/apiRequests";
import { boxShadow, colors } from "app/utils/theme";
import { errorNotification, successNotification } from "app/utils/Notification";
import { rApp, rSavedSpreadsheets } from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Main } from "../generate/AiGeneration/components";
import { get } from "lodash";
import { getUrlParameter } from "app/utils/utils";
import googleSheetsLogo from "../assets/google-sheets-logo.png";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";
import { useRecoilState } from "recoil";

const GoogleIntegration = ({ inChat, label, description }) => {
  const [app, setApp] = useRecoilState(rApp);

  const [isFetching, setIsFetching] = useState(false);

  const location = useLocation();

  const googleSheetsCode = getUrlParameter("code", location);

  const navigate = useNavigate();

  const [showExamples, setShowExamples] = useState(false);

  const [savedSpreadsheets, setSavedSpreadsheets] =
    useRecoilState(rSavedSpreadsheets);

  useEffect(() => {
    if (!get(app, "google_sheets_connected") && googleSheetsCode) {
      // SWAP CODE FOR TOKENS
      authorizeSheets(googleSheetsCode);
    }
  }, []);

  const authorizeSheets = (code) => {
    setIsFetching(true);
    apiRequest
      .post(`${frontlyBaseApi}/google_sheets/auth/`, {
        auth_code: code,
      })
      .then((response) => {
        const success = get(response, ["data", "success"]);

        if (success) {
          const spreadsheets = get(response, ["data", "spreadsheets"], []);
          const mergedSpreadsheets = [...savedSpreadsheets, ...spreadsheets];

          mixpanel.track("Connect Google Sheets");

          // If the user has previously connected Google before this
          const hasConnectedGoogle = get(app, "has_connected_google");

          // Show generated example sheets
          if (!hasConnectedGoogle) {
            setShowExamples(true);
            setSavedSpreadsheets(mergedSpreadsheets);
          } else {
            navigate("/home");

            setSavedSpreadsheets(mergedSpreadsheets);
            setApp({
              google_sheets_connected: true,
            });
          }

          successNotification("Google Sheets Connected");
        } else {
          errorNotification("Error connecting Google Sheets");
        }

        setIsFetching(false);
      });
  };

  if (showExamples) {
    return (
      <Main
        label="Google Sheets Connected!"
        description="We've generated some sample spreadsheets in your Google account:"
      >
        <SamplesRow>
          {savedSpreadsheets
            .filter((s) =>
              ["Ecommerce Orders", "Users", "Inventory"].includes(s.title)
            )
            .filter((s, i) => i < 3)
            .map((s) => (
              <SampleSheet
                onClick={() => {
                  window.open(
                    `https://docs.google.com/spreadsheets/d/${s.spreadsheet_id}/edit#gid=${s.tab_id}`
                  );
                }}
              >
                <GoogleLogoSmall src={googleSheetsLogo} />
                <ButtonText>{s.title}</ButtonText>
              </SampleSheet>
            ))}
        </SamplesRow>

        <Explanation>
          Click each sheet to review in Google and understand the basic
          spreadsheet structure that Frontly apps require.
        </Explanation>

        <Button
          data={{
            text: "Complete Setup",
            icon: "FiArrowRight",
            borderRadius: "20px",
            size: "extraLarge",
            border: "2px solid white",
            margin: "30px 0 0 0",
            backgroundColor: "rgb(0, 85, 255)",
            flippedIcon: true,
            onClick: () => {
              navigate("/home");
              setShowExamples(true);
              setApp({
                google_sheets_connected: true,
              });
            },
          }}
        />
      </Main>
    );
  }

  return (
    <Main label={label} description={description}>
      {isFetching ? (
        <ConnectButton>
          <Spinner color="#0F9D58" size={38} />
          <ButtonText>Connecting Google Sheets</ButtonText>
        </ConnectButton>
      ) : (
        <ConnectButton
          onClick={() => {
            setIsFetching(true);
            const googleSheetsAuthLink = `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A//www.googleapis.com/auth/spreadsheets&access_type=offline&prompt=consent&include_granted_scopes=true&response_type=code&redirect_uri=${webUrl}/spreadsheets&client_id=${process.env.REACT_APP_GOOGLE_SHEETS_CLIENT_ID}`;
            window.location.href = googleSheetsAuthLink;
          }}
        >
          <GoogleLogo src={googleSheetsLogo} />
          <ButtonText>Connect Google Sheets</ButtonText>
        </ConnectButton>
      )}

      <CustomLink
        onClick={() =>
          window.open(
            "https://help.frontly.ai/en/articles/8049487-google-sheet-setup"
          )
        }
      >
        Learn more about how Frontly uses Google Sheets
      </CustomLink>

      {!inChat && (
        <BackButton onClick={() => navigate("/chat/new")}>
          <Text
            data={{
              text: "Not Ready? Generate An App With AI First",
              fontStyle: "bodyXl",
              color: "white",
              cursor: "pointer",
            }}
          />
          <Icon
            data={{
              icon: "HiSparkles",
              color: "white",
              hover: true,
              size: 20,
            }}
          />
        </BackButton>
      )}
    </Main>
  );
};
export default GoogleIntegration;

const BackButton = styled.div`
  border: 2px solid white;
  font-weight: 500;
  font-size: 20px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  margin-top: 80px;
  width: fit-content;
  height: 60px;
  cursor: pointer;
  &:hover {
    filter: brightness(90%);
  }
`;

const CustomLink = styled.div`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: var(--grey21);
  text-decoration: underline;
  margin-top: 20px;
  cursor: pointer;
`;

const GoogleLogo = styled.img`
  height: 50px;
  @media screen and (max-width: 800px) {
    height: 40px;
  }
`;

const ButtonText = styled.div`
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  @media screen and (max-width: 800px) {
    font-size: 18px;
  }
`;

const ConnectButton = styled.div`
  padding: 25px;
  border-radius: 20px;
  background: white;
  box-shadow: ${boxShadow.card};
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    box-shadow: ${boxShadow.deep};
  }
  @media screen and (max-width: 800px) {
    padding: 18px;
    width: 90%;
  }
`;

const GoogleLogoSmall = styled.img`
  height: 35px;
  @media screen and (max-width: 800px) {
    height: 30px;
  }
`;

const Header = styled.div`
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
  @media screen and (max-width: 800px) {
    font-size: 30px;
  }
`;

const Description = styled.div`
  font-size: 25px;
  font-weight: 300;
  text-align: center;
  max-width: 800px;
  @media screen and (max-width: 800px) {
    font-size: 18px;
  }
`;

const Explanation = styled.div`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: var(--grey21);
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`;

const SampleSheet = styled.div`
  padding: 20px;
  border-radius: 20px;
  background: white;
  box-shadow: ${boxShadow.card};
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    box-shadow: ${boxShadow.deep};
  }
  @media screen and (max-width: 800px) {
    padding: 16px;
    width: 90%;
  }
`;

const SamplesRow = styled.div`
  display: flex;
  gap: 20px;
  margin: 0 0 30px 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    gap: 10px;
  }
`;
