import { Icon, Modal, Text } from "app/components";

import { colors } from "app/utils/theme";
import styled from "styled-components";
import { useState } from "react";

const promptExamples = [
  {
    label: "Health and Fitness Tracking",
    text: "Create an app where users can log workouts, track nutrition, set fitness goals, and monitor progress over time with detailed analytics",
  },
  {
    label: "Event Planning and Coordination",
    text: "Design an app to help event planners manage schedules, vendors, guest lists, and budgets for various types of events",
  },
  {
    label: "Language Learning",
    text: "Build an app for language learners with interactive lessons, pronunciation practice, grammar exercises, and a community forum for language exchange",
  },
  {
    label: "Client Portal",
    text: "I run a marketing agency. I need a login portal for my clients to check on the status of our ongoing projects. This app should include a Kanban board for projects and also have an analytics dashboard for a high level view of statistics.",
  },
  {
    label: "Analytics Dashboard",
    text: "I need an analytics dashboard for my landscaping business. I need some Charts and Stats showing important metrics like active project count and total revenue, and a Table showing all my contracts.",
  },
  {
    label: "Inventory Management",
    text: "I need an inventory management tool for my shoe store. I'd like a Grid showing all my shoe images and inventory, and a Table showing my order history with my suppliers.",
  },
];

const ShowExamples = ({ setPrompt }) => {
  const [showExamples, setShowExamples] = useState(false);

  return (
    <>
      {showExamples && (
        <Modal
          hide={() => setShowExamples(false)}
          header={{
            title: "AI Prompt Examples",
            description: "Click on a sample prompt to use it",
          }}
        >
          <PromptExamples>
            {promptExamples.map((p) => (
              <PromptExample
                onClick={() => {
                  setShowExamples(false);
                  setPrompt(p.text);
                }}
              >
                <ExampleLabel>{p.label}</ExampleLabel>
                <ExampleText>{p.text}</ExampleText>
              </PromptExample>
            ))}
          </PromptExamples>
        </Modal>
      )}
      <ExamplesButton onClick={() => setShowExamples(true)}>
        <Icon data={{ icon: "BsLightbulb", color: "white", size: 18 }} />
        <Text
          data={{
            text: "Need ideas? View sample prompts",
            color: "white",
            fontStyle: "bodyLg",
            cursor: "pointer",
          }}
        />
      </ExamplesButton>
    </>
  );
};

export default ShowExamples;

const PromptExamples = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  @media screen and (max-width: 800px) {
    gap: 10px;
  }
`;

const ExampleLabel = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`;

const ExampleText = styled.div`
  font-size: 18px;
  font-weight: 300;
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`;

const PromptExample = styled.div`
  border: 1px solid var(--grey3);
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background: var(--grey1);
  }
`;

const ExamplesButton = styled.div`
  padding: 10px;
  color: white;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  width: fit-content;
  display: flex;
  justify-content: center
  align-items: center;
  gap: 5px;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.10);
  color: white;
  transition: 400ms;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 296px;
  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }
`;
