import { colors, spacing } from "app/utils/theme";

import { Checkbox as MUICheckbox } from "@mui/material";
import { Text } from "app/components";
import { get } from "lodash";
import { rFormState } from "app/utils/recoil";
import { useRecoilState } from "recoil";

const Checkbox = ({ page, block }) => {
  const [formState, setFormState] = useRecoilState(rFormState);

  const fieldId = get(block, "id");
  const concatId = `${page.id}-${fieldId}`;

  const onChange = (evt) => {
    setFormState((prev) => ({
      ...prev,
      [concatId]: evt.target.checked,
    }));
  };

  const isActive = get(formState, concatId, "");

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        gap: spacing.s1,
        marginTop: spacing.s1,
      }}
    >
      <MUICheckbox
        color="primary"
        onChange={onChange}
        value={isActive}
        style={{ padding: "0px" }}
        sx={{
          color: colors.darkModeLightGrey,
          "&.Mui-checked": {
            color: "var(--primary)",
          },
        }}
      />
      <Text
        data={{
          text: "Sample",
          margin: "0 0 4px 0",
          fontStyle: "bodySm",
        }}
      />
    </div>
  );
};

export default Checkbox;
