import {
  rActiveDetailViewId,
  rConfirmationModalData,
  rCustomBlockChanges,
  rPageChanges,
} from "app/utils/recoil";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { Icon } from "app/components";
import { colors } from "app/utils/theme";
import styled from "styled-components";
import useIsCustomBlock from "app/utils/useIsCustomBlock";
import { useNavigate } from "react-router-dom";

const BackSection = () => {
  const pageChanges = useRecoilValue(rPageChanges);
  const customBlockChanges = useRecoilValue(rCustomBlockChanges);
  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);
  const setActiveDetailViewId = useSetRecoilState(rActiveDetailViewId);

  const navigate = useNavigate();

  const isCustomBlock = useIsCustomBlock();

  return (
    <Container
      onClick={() => {
        const back = () => {
          navigate(isCustomBlock ? "/settings?tab=customBlocks" : "/pages");
          setActiveDetailViewId(null);
        };

        if (
          (isCustomBlock && customBlockChanges) ||
          (!isCustomBlock && pageChanges)
        ) {
          setConfirmationModalData({
            title: "You have unsaved changes",
            confirmText: "Leave without saving",
            text: "Are you sure you want to leave this page without saving? Your changes will be lost.",
            confirm: back,
          });
        } else {
          back();
        }
      }}
    >
      <Rotate>
        <Icon
          data={{
            icon: "FiLogOut",
            size: 20,
            hover: true,
            color: "var(--grey7)",
          }}
        />
      </Rotate>
    </Container>
  );
};

export default BackSection;

const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 20px 0 20px;
  margin: 0 15px 0 0;
  border-right: 1px solid var(--grey21);
  cursor: pointer;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`;

const Rotate = styled.div`
  transform: rotate(180deg);
`;
