import {
  euroToFloat,
  formatEuro,
  formatNumber,
  getPixels,
  isFrontlyAdmin,
  parseNumber,
  passesCondition,
  safeToFixed,
} from "app/utils/utils";
import { get, isArray, isNil } from "lodash";
import { rApp, rDarkMode, rLiveSpreadsheets } from "app/utils/recoil";

import { colors } from "app/utils/theme";
import styled from "styled-components";
import useActionResolver from "../useActionResolver";
import useDynamicText from "../useDynamicText";
import useListData from "app/useListData";
import useProcessObjects from "app/useProcessObjects";
import { useRecoilValue } from "recoil";

const Stat = ({ page, block }) => {
  const { processObjects } = useProcessObjects();

  const spreadsheets = useRecoilValue(rLiveSpreadsheets);

  const { getListData } = useListData();

  const darkMode = useRecoilValue(rDarkMode);

  const activeApp = useRecoilValue(rApp);

  const { actionResolver } = useActionResolver(page);
  const { processDynamicText } = useDynamicText();

  const size = get(block, "size", "medium");

  let data = getListData(block);
  data = processObjects(page, block, [], data, processDynamicText);

  // Handle Click Action
  let handleClick = null;
  const clickAction = get(block, "clickAction");
  if (!isFrontlyAdmin && clickAction) {
    handleClick = (event) => {
      event.stopPropagation();
      actionResolver({
        rawAction: get(block, ["actionMap", "clickAction"]),
        actionId: clickAction,
        context: { repeatingRecord: get(block, "repeatingRecord") },
      });
    };
  }

  const dbValue = get(spreadsheets, get(block, "id"));

  const metric = get(block, "metric", "Count");
  const field = get(block, "field");
  const valueType = get(block, "valueType");
  const manualValue = get(block, "manualValue", 0);
  const statPrefix = get(block, "statPrefix", "");
  const statSuffix = get(block, "statSuffix", "");
  const value = processDynamicText({
    text: get(block, "value", ""),
    reusableBlockId: block.reusableBlockId,
    context: { repeatingRecord: get(block, "repeatingRecord") },
  });
  const operator = get(block, "operator");
  const isEuroDecimal = get(activeApp, "number_format") === "euro";
  const result = calculateMetric(
    data,
    metric,
    field,
    value,
    operator,
    isEuroDecimal
  );
  const fontColor = get(block, "fontColor");

  const fontSize = get(
    {
      extraSmall: 45,
      small: 65,
      medium: 100,
      large: 120,
    },
    size
  );

  const decimalPlaces = get(block, "decimalPlaces", 0);

  let displayValue = get(result, "value");

  const isDataBase =
    get(block, "spreadsheet") &&
    get(block, "spreadsheet").toString().includes("db__");

  if (isDataBase && dbValue) {
    displayValue = dbValue;
  }

  if (!isNil(decimalPlaces)) {
    displayValue = formatNumber(displayValue, decimalPlaces);
  }

  displayValue =
    valueType === "manual"
      ? processDynamicText({
          text: manualValue,
          reusableBlockId: block.reusableBlockId,
          context: { repeatingRecord: get(block, "repeatingRecord") },
        })
      : displayValue;

  if (metric === "Percentage") {
    displayValue += "%";
  }

  if (isEuroDecimal) {
    displayValue = formatEuro(get(result, "value"), decimalPlaces);
  }

  return (
    <StatContainer inLayout={get(block, "inLayout")} onClick={handleClick}>
      <StatText size={fontSize} color={fontColor} darkMode={darkMode}>
        {statPrefix}
        {displayValue}
        {statSuffix}
      </StatText>
    </StatContainer>
  );
};

export default Stat;

const StatContainer = styled.div`
  ${(p) => !p.inLayout && "min-height: 200px;"}
  ${(p) => p.inLayout && "height: 100%;"}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
`;

const StatText = styled.div`
  font-size: ${(p) => getPixels(p.size || "100px")};
  font-weight: ${(p) => p.fontSize || 700};
  color: ${(p) => (p.color ? p.color : p.darkMode ? "white" : colors.default)};
`;

const calculateMetric = (
  data,
  metric,
  field,
  value,
  operator = "equals",
  isEuroDecimal
) => {
  const parse = (value) => {
    if (isEuroDecimal) {
      return euroToFloat(value);
    }
    return parseNumber(value);
  };

  if (!data || !isArray(data)) {
    return {
      label: "Count",
      value: 0,
    };
  }

  switch (metric) {
    case "Count":
      return {
        label: "Count",
        value: data.length,
      };
    case "Field Count":
      return {
        label: "Field Count",
        value: data.filter((item) =>
          passesCondition({ value1: item[field], value2: value, operator })
        ).length,
      };
    case "Percentage":
      const count = data.filter((item) =>
        passesCondition({ value1: item[field], value2: value, operator })
      ).length;

      const percentage = (count / data.length) * 100;

      return {
        label: "Percentage",
        value: percentage,
      };
    case "Average":
      const sum = data.reduce((total, item) => total + parse(item[field]), 0);

      const average = sum / data.length;

      return {
        label: "Average",
        value: safeToFixed(average, 2),
      };
    case "Sum":
      const total = data.reduce((acc, item) => acc + parse(item[field]), 0);
      return {
        label: "Sum",
        value: total,
      };
    case "Maximum":
      const max = Math.max(...data.map((item) => parse(item[field])));
      return {
        label: "Maximum",
        value: max,
      };
    case "Minimum":
      const min = Math.min(...data.map((item) => parse(item[field])));
      return {
        label: "Minimum",
        value: min,
      };
    case "Unique Count":
      const uniqueValues = new Set(
        data
          .filter((item) => get(item, field) !== "")
          .map((item) => item[field])
      );
      return {
        label: "Unique Count",
        value: uniqueValues.size,
      };
    case "Ratio":
      const numeratorSum = data.reduce(
        (acc, item) => acc + parse(item[field]),
        0
      );
      const denominatorSum = data.reduce((acc, item) => acc + item[value], 0);
      const ratio = numeratorSum / denominatorSum;
      return {
        label: "Ratio",
        value: safeToFixed(ratio, 2),
      };
    case "Boolean Count":
      return {
        label: "Boolean Count",
        value: data.filter((item) => item[field] === true).length,
      };
    case "Median":
      const sortedValues = data
        .map((item) => item[field])
        .sort((a, b) => a - b);
      const middleIndex = Math.floor(sortedValues.length / 2);
      const median =
        sortedValues.length % 2 === 0
          ? (sortedValues[middleIndex - 1] + sortedValues[middleIndex]) / 2
          : sortedValues[middleIndex];
      return {
        label: "Median",
        value: safeToFixed(median, 2),
      };
    case "Mode":
      const fieldValues = data.map((item) => item[field]);
      const frequencies = {};
      fieldValues.forEach((value) => {
        frequencies[value] = (frequencies[value] || 0) + 1;
      });
      const maxFrequency = Math.max(...Object.values(frequencies));
      const mode = Object.keys(frequencies).filter(
        (value) => frequencies[value] === maxFrequency
      );
      return {
        label: "Mode",
        value: mode.join(", "),
      };

    // case "Weighted Average":
    //   const totalWeight = data.reduce(
    //     (acc, item) => acc + item[additionalField],
    //     0
    //   );
    //   const weightedSum = data.reduce(
    //     (acc, item) => acc + item[field] * item[additionalField],
    //     0
    //   );
    //   const weightedAverage = weightedSum / totalWeight;
    //   return {
    //     label: "Weighted Average",
    //     value: weightedAverage.toFixed(2),
    //   };

    // case "Percentage Change":
    //   const oldValue = data.reduce((acc, item) => acc + item[field], 0);
    //   const newValue = data.reduce((acc, item) => acc + item[value], 0);
    //   const percentageChange = ((newValue - oldValue) / oldValue) * 100;
    //   return {
    //     label: "Percentage Change",
    //     value: percentageChange.toFixed(2) + "%",
    //   };

    // case "Range":
    //   const maxRange = Math.max(...data.map((item) => item[field]));
    //   const minRange = Math.min(...data.map((item) => item[field]));
    //   const range = maxRange - minRange;
    //   return {
    //     label: "Range",
    //     value: range,
    //   };

    // case "Cumulative Sum":
    //   const sortedData = data.sort(
    //     (a, b) => a[additionalField] - b[additionalField]
    //   );
    //   const cumulativeSum = sortedData.reduce(
    //     (acc, item, index) => {
    //       const sum = acc.sum + item[field];
    //       acc.cumulative.push(sum);
    //       acc.sum = sum;
    //       return acc;
    //     },
    //     { cumulative: [], sum: 0 }
    //   ).cumulative;
    //   return {
    //     label: "Cumulative Sum",
    //     value: cumulativeSum,
    //   };

    // case "Count by Category":
    //   const categoryCounts = {};
    //   data.forEach((item) => {
    //     const category = item[field];
    //     categoryCounts[category] = (categoryCounts[category] || 0) + 1;
    //   });
    //   return {
    //     label: "Count by Category",
    //     value: categoryCounts,
    //   };

    // case "Average Duration":
    //   const totalDuration = data.reduce((acc, item) => acc + item[field], 0);
    //   const averageDuration = totalDuration / data.length;
    //   return {
    //     label: "Average Duration",
    //     value: averageDuration.toFixed(2) + " minutes",
    //   };

    default:
      return "count";
    // throw new Error("Invalid metric");
  }
};
