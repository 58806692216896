import { boxShadow, colors } from "app/utils/theme";

import React from "react";
import { Text } from "..";
import { formatNumber } from "app/utils/utils";
import { get } from "lodash";
import { getColor } from "./utils";
import styled from "styled-components";

const ChartHoverPopup = ({ location, item, index, colorScheme, data }) => {
  const decimalPlaces = get(data, "decimalPlaces", 0);
  let displayValue = formatNumber(get(item, "value"), decimalPlaces);

  const valuePrefix = get(data, "valuePrefix");
  const valueSuffix = get(data, "valueSuffix");

  if (valuePrefix) {
    displayValue = valuePrefix + displayValue;
  }

  if (valueSuffix) {
    displayValue = displayValue + valueSuffix;
  }

  const customColorScheme = get(data, "customColorScheme");

  return (
    <Popup top={location.top} left={location.left}>
      <ColorBar color={getColor(index, colorScheme, customColorScheme)}>
        &nbsp;
      </ColorBar>
      <div>
        <Text data={{ text: get(item, "label"), margin: "0 0 5px 0" }} />
        <Text data={{ text: displayValue, fontStyle: "headingXl" }} />
      </div>
    </Popup>
  );
};

export default ChartHoverPopup;

const ColorBar = styled.div`
  flex: 1;
  width: 4px;
  background: ${(p) => p.color};
  border-radius: 4px;
`;

const Popup = styled.div`
  position: fixed;
  display: flex;
  gap: 15px;
  height: fit-content;
  top: ${(p) => p.top + 20}px;
  left: ${(p) => p.left + 20}px;
  background: white;
  width: fit-content;
  border: 1px solid var(--grey21);
  box-shadow: ${boxShadow.card};
  padding: 15px;
  border-radius: 10px;
  min-height: 40px;
  z-index: 9999;
`;
