import { get, isArray } from "lodash";
import {
  isFrontlyAdmin,
  passesCondition,
  safeArray,
  safeString,
} from "./utils/utils";
import {
  rApp,
  rAppDateFormat,
  rFilters,
  rPageFilters,
  rSavedSpreadsheets,
} from "./utils/recoil";

import { getUniqueBadgeColors } from "./components/Badge";
import moment from "moment";
import { useRecoilValue } from "recoil";

const useProcessObjects = () => {
  const filters = useRecoilValue(rFilters);
  const pageFilterState = useRecoilValue(rPageFilters);
  const app = useRecoilValue(rApp);
  const appDateFormat = useRecoilValue(rAppDateFormat);

  const disableAdminAnimations = get(app, "disable_admin_animations", false);

  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  // Used to process Table, Kanban, Grid, Calendar, Chart, DataGrid
  const processObjects = (page, block, fields, rows, processText) => {
    // I could consider re-enabling this if I wanted to make new locally created / modified records abide hidden filters

    const hiddenFilters = !isFrontlyAdmin
      ? get(block, "hiddenFilters", [])
      : [];

    const hiddenFiltersConditionType = get(
      block,
      "hiddenFiltersConditionType",
      "all"
    );

    const pageFilterSettings = get(page, "filterSettings", {});
    const pageFilters = get(pageFilterSettings, "filters", []);

    const visibleFilters = get(block, "visibleFilters", []);
    const blockFilters = get(filters, block.id, {});

    const spreadsheet = savedSpreadsheets.find(
      (s) => s.id === block.spreadsheet
    );

    const fieldData = safeArray(spreadsheet, "field_data");

    const isDataBase = ["supabase", "airtable", "frontly_db"].includes(
      get(spreadsheet, "service")
    );

    const rowActions = get(block, "rowActions", []).map((ac) => {
      const match =
        get(page, "actions", []).find((a) => a.id === ac.value) || {};

      return { ...ac, ...match };
    });

    const doesPass = (data) => {
      const { column, record, filter } = data;

      let { filterValue } = data;

      const componentId = get(filter, "componentId");

      const header = get(fieldData, column, {});

      const v = get(record, column);

      if (filterValue) {
        const dateFormat = get(header, "dateFormat");

        const df = { ...appDateFormat, ...dateFormat };

        let operator = get(filter, "operator", "equals");

        if (componentId === "RelativeDate") {
          if (filterValue === "today") {
            operator = "date_equals";
            const timeNow = moment().format(get(df, "inputDate", "YYYY/MM/DD"));
            filterValue = timeNow;
          } else {
            operator = "date_after";
            const timeNow = moment()
              .subtract(filterValue || 0, "days")
              .format(get(df, "inputDate", "YYYY/MM/DD"));
            filterValue = timeNow;
          }
        } else if (componentId === "DateRangePicker") {
          operator = "date_in_range";
        } else if (componentId === "NumericRange") {
          operator = "number_in_range";
        }

        return passesCondition({
          value1: v,
          value2: filterValue,
          operator,
          value1DateFormat: df,
        });
      }

      return true;
    };

    const passesFilters = (r) => {
      // Hidden Filters (FOR DETAIL VIEW CHILDREN ONLY)
      let hiddenFilterResults = [];
      hiddenFilters.forEach((f) => {
        const val1 = get(r, f.key);
        const val2 = processText({ text: f.value });
        const operator = get(f, "operator", "equals");

        const isBooleanOperator = [
          "exists",
          "does_not_exist",
          "is_true",
          "is_false",
        ].includes(operator);

        // Supabase Overlaps
        if (operator === "overlaps") {
          hiddenFilterResults.push(true);
        } else if (val2 || isBooleanOperator) {
          hiddenFilterResults.push(
            passesCondition({
              value1: val1,
              value2: val2,
              operator,
              value1DateFormat: appDateFormat,
            })
          );
        }
      });

      const passHiddenFilters =
        hiddenFilters.length === 0 || hiddenFiltersConditionType === "all"
          ? hiddenFilterResults.every((row) => row)
          : hiddenFilterResults.some((row) => row);

      // Visible Filters
      let visibleFilterResults = [];
      visibleFilters.forEach((f) => {
        const b = get(blockFilters, f.key);
        visibleFilterResults.push(
          doesPass({
            column: f.key,
            record: r,
            filter: f,
            filterValue: b,
          })
        );
      });

      const passVisibleFilters =
        isDataBase ||
        visibleFilters.length === 0 ||
        visibleFilterResults.every((r) => r);

      // PAGE FILTERS
      let pageFilterResults = [];
      pageFilters.forEach((f) => {
        const relatedSheets = get(f, "sheets", {});
        let b = get(pageFilterState, f.id);
        const matchingSheetColumn = get(relatedSheets, block.spreadsheet);
        if (matchingSheetColumn) {
          pageFilterResults.push(
            doesPass({
              column: matchingSheetColumn,
              record: r,
              filter: f,
              filterValue: b,
            })
          );
        }
      });
      const pageFiltersConditionType = get(
        block,
        "pageFiltersConditionType",
        "all"
      );
      const passPageFilters =
        pageFilters.length === 0 || pageFiltersConditionType === "all"
          ? pageFilterResults.every((r) => r)
          : pageFilterResults.some((r) => r);

      // SEARCH FILTER
      const searchFilter = get(blockFilters, "search", "").toLowerCase();
      let passSearchFilter = true;
      if (searchFilter && searchFilter !== "") {
        let hasSearchValue = false;
        Object.keys(r).forEach((k) => {
          const v = safeString(get(r, k, "")).toLowerCase();
          if (v.includes(searchFilter)) {
            hasSearchValue = true;
          }
        });
        if (!hasSearchValue) {
          passSearchFilter = false;
        }
      }

      const pass =
        passHiddenFilters &&
        passPageFilters &&
        passVisibleFilters &&
        passSearchFilter;

      return isFrontlyAdmin || pass;
    };

    const badgeKey = get(block, "badge");

    const dateFormat = get(block, "dateFormat");
    let uniqueBadges = [];

    // Reduce displayed row count if admin animations are disabled
    let renderedRows = [];
    if (isArray(rows)) {
      if (disableAdminAnimations && isFrontlyAdmin) {
        renderedRows = rows.filter((r, ri) => ri < 100);
      } else {
        renderedRows = [...rows];
      }
    }

    let records = isArray(renderedRows)
      ? renderedRows
          .filter((r) => passesFilters(r))
          .map((r) => {
            let row = { ...r };

            if (rowActions.length > 0) {
              row["actions"] = rowActions;
            }

            fields.forEach((f) => {
              row[f.mapKey || f.key] = get(r, f.key);
            });

            let badgeValue = get(row, badgeKey);
            const splitBadge = get(block, "splitBadge", false);

            if (badgeValue) {
              badgeValue = badgeValue.toString();
            }

            const badgeArray =
              splitBadge && badgeValue
                ? badgeValue.split(",").map((v) => v.trim())
                : badgeValue
                ? [badgeValue.trim()]
                : [];

            if (badgeArray.length > 0) {
              row["badges"] = badgeArray;
              badgeArray.forEach((b) => {
                if (!uniqueBadges.includes(b)) {
                  uniqueBadges.push(b);
                }
              });
            }

            return row;
          })
      : [];

    const badgeColors = getUniqueBadgeColors(uniqueBadges);

    records = records.map((i) => ({
      ...i,
      badgeColors,
      dateFormat,
    }));

    return records;
  };

  return { processObjects };
};

export default useProcessObjects;
