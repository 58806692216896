import { Label } from "./Pages";
import { colors } from "app/utils/theme";
import moment from "moment";
import { rAppGenerationSessions } from "app/utils/recoil";
import styled from "styled-components";
import { truncateText } from "app/utils/utils";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

const RecentAiSessions = () => {
  const navigate = useNavigate();

  const appGenerationSessions = useRecoilValue(rAppGenerationSessions);

  if (appGenerationSessions.length === 0) {
    return <></>;
  }

  return (
    <div>
      <Label>Incomplete AI App Generation Sessions</Label>
      <CardSection style={{ margin: "25px 0 0 0" }}>
        {appGenerationSessions.map((session) => {
          return (
            <Card
              key={session.id}
              onClick={() => navigate(`/chat/${session.id}`)}
            >
              <CardLabel>
                {truncateText(session.prompt || "No Prompt", 50)}
              </CardLabel>
              <CardText>{moment(session.created_date).fromNow()}</CardText>
            </Card>
          );
        })}
      </CardSection>
    </div>
  );
};

export default RecentAiSessions;

const CardSection = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
  overflow: auto;
`;

const CardLabel = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const CardText = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: var(--grey7);
  margin-top: 4px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  justify-content: space-between;
  padding: 15px;
  cursor: pointer;
  gap: 20px;
  max-width: 300px;
  min-width: 300px;
  min-height: 120px;
  border: 1px solid var(--grey3);
  border-radius: 12px;
  &:hover {
    filter: brightness(0.98);
  }
`;
