import {
  Button,
  FormInputWrapper,
  Icon,
  Input,
  Row,
  SimpleDropdown,
  Tabs,
} from "app/components";
import { DeleteButton, EditOptions } from "../styles";
import { Main, NewOptionButton } from "../components";
import { rCurrentContext, rOptionTab } from "../state";

import { NewBlock } from "app/adminApp/components/BlockManager/NewBlock";
import { allBlocks } from "app/utils/utils";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { useState } from "react";

// Page Blocks
const PageBlocks = () => {
  const [currentContext, setCurrentContext] = useRecoilState(rCurrentContext);
  const [optionTab, setOptionTab] = useRecoilState(rOptionTab);

  const pagesObject = get(currentContext, "page_blocks", {});

  const sheetNames = Object.keys(pagesObject);

  const activeTab = optionTab || get(sheetNames, 0);

  const activePageBlocksArray = get(pagesObject, activeTab, []);

  const [blockSelectorIndex, setBlockSelectorIndex] = useState(null);

  const updateBlock = (k, v, i) => {
    // Update block
    setCurrentContext((prev) => {
      const newBlocks = activePageBlocksArray.map((block, j) => {
        if (j === i) {
          return {
            ...block,
            [k]: v,
          };
        }

        return block;
      });

      const newObj = {
        ...pagesObject,
        [activeTab]: newBlocks,
      };

      return { ...prev, page_blocks: newObj };
    });
  };

  return (
    <Main
      label="Page Blocks"
      description="For each page, we've generated some feature blocks and selected their spreadsheet connections. Feel free to make changes, then submit to continue."
    >
      {blockSelectorIndex !== null && (
        <NewBlock
          title="Select A Block"
          excludeTabs={"layout"}
          blockTypes={[
            "Table",
            "Form",
            "Grid",
            "Kanban",
            "Calendar",
            "Chart",
            "Stat",
            "Map",
            "Timeline",
            "InfoList",
            "QuoteCalculator",
            "RichText",
            "Image",
            "Video",
          ]}
          hide={() => setBlockSelectorIndex(null)}
          onBlockSelect={(type) => {
            updateBlock("type", type, blockSelectorIndex);
            setBlockSelectorIndex(null);
          }}
        />
      )}

      <Tabs
        data={{
          margin: "0 0 20px 0",
          fontSize: 20,
          fontWeight: 600,
          color: "white",
          tabs: sheetNames.map((s, i) => ({
            label: s,
            active: optionTab === s || (!optionTab && i === 0),
            onClick: () => setOptionTab(s),
          })),
        }}
      />

      <EditOptions>
        {activePageBlocksArray.map((b, i) => {
          return (
            <BlockContainer key={i}>
              <Row
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                margin="0 0 10px 0"
              >
                <Input
                  data={{
                    value: b.label || "",
                    background: "transparent",
                    border: "0px",
                    fontStyle: "headingMd",
                    padding: "0px",
                    onChange: (v) => updateBlock("label", v, i),
                  }}
                />
                <DeleteButton
                  onClick={() => {
                    // Delete block
                    setCurrentContext((prev) => {
                      const newBlocks = [...prev.page_blocks[activeTab]];
                      newBlocks.splice(i, 1);
                      let newPageBlocks = {
                        ...prev.page_blocks,
                        [activeTab]: newBlocks,
                      };
                      return { ...prev, page_blocks: newPageBlocks };
                    });
                  }}
                >
                  <Icon
                    data={{
                      icon: "FiX",
                      color: "var(--grey7)",
                      hover: true,
                      size: 15,
                    }}
                  />
                </DeleteButton>
              </Row>

              <Row gap="15px">
                <FormInputWrapper label="Type">
                  <Button
                    data={{
                      text: b.type,
                      icon: allBlocks.find((bl) => bl.type === b.type).icon,
                      type: "basic",
                      onClick: () => setBlockSelectorIndex(i),
                      borderRadius: "16px",
                    }}
                  />
                </FormInputWrapper>
                <FormInputWrapper label="Spreadsheet">
                  <SimpleDropdown
                    data={{
                      options: get(currentContext, "spreadsheets", []).map(
                        (s) => ({
                          label: s,
                          onClick: () => updateBlock("spreadsheet", s, i),
                          active: s === b.spreadsheet,
                        })
                      ),
                      button: {
                        text: b.spreadsheet,
                        icon: "BsGoogle",
                        type: "basic",
                        borderRadius: "16px",
                      },
                    }}
                  />
                </FormInputWrapper>
              </Row>
            </BlockContainer>
          );
        })}
        <NewOptionButton
          onClick={() =>
            setCurrentContext((prev) => {
              // Add a new block to the active tab
              const newBlocks = [
                ...prev.page_blocks[activeTab],
                {
                  type: "Table",
                  label: "New Block",
                  spreadsheet: get(currentContext, ["spreadsheets", 0]),
                },
              ];
              let newPageBlocks = {
                ...prev.page_blocks,
                [activeTab]: newBlocks,
              };
              return { ...prev, page_blocks: newPageBlocks };
            })
          }
        />
      </EditOptions>
    </Main>
  );
};

export default PageBlocks;

const BlockContainer = styled.div`
  outline: 5px solid #ffffff50;
  background: white;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  @media screen and (max-width: 800px) {
    outline: 5px solid #ffffff50;
  }
`;
