// This renders the Grid, Calendar, and Kanban card text content

import { colors, fontObject } from "app/utils/theme";
import { get, isEmpty } from "lodash";
import {
  getPixels,
  isFrontlyAdmin,
  safeArray,
  truncateText,
} from "app/utils/utils";
import { rActiveEditField, rDarkMode } from "app/utils/recoil";
import { useRecoilValue, useSetRecoilState } from "recoil";

import Badge from "./Badge";
import { SkeletonLoader } from "app/components";
import styled from "styled-components";
import useUtils from "app/renderingApp/useUtils";

const CardContent = ({
  fields,
  item,
  blockId,
  skeleton,
  customBadgeColors,
  gridColumns,
}) => {
  const badges = safeArray(get(item, "badges", []));
  const badgeColors = get(item, "badgeColors", {});

  const setActiveEditField = useSetRecoilState(rActiveEditField);
  const { passesDisplayConditions } = useUtils();

  const darkMode = useRecoilValue(rDarkMode);

  const badgeColorMap = get(item, "badgeColorMap", {});

  let combinedBadgeColorMap = { ...badgeColors, ...badgeColorMap };

  const handleCustomBadgeColor = (v) => {
    const colorMap = customBadgeColors?.find((item) => {
      const conditions = item?.displayConditions?.map((d) => ({
        ...d,
        value1: v,
      }));
      const isPass = passesDisplayConditions({ conditions });
      return isPass;
    });
    if (colorMap) return colorMap?.color;
    return get(badgeColorMap, "grey");
  };

  const processedFields = determinePositions(
    gridColumns,
    fields.map((f) => ({
      ...f,
      columnSpan: get(f, "columnSpan", gridColumns),
      rowSpan: get(f, "rowSpan", 1),
    }))
  );

  return (
    <CardContainer gridColumns={gridColumns}>
      {processedFields.map((field, i) => {
        let value = truncateText(get(item, field.key), field.truncate || 35);

        if (field.valuePrefix) {
          value = `${field.valuePrefix}${value}`;
        }

        if (field.valueSuffix) {
          value = `${value}${field.valueSuffix}`;
        }

        let fieldTextColor = darkMode ? "white" : null;
        if (field.fontColor) {
          fieldTextColor = field.fontColor;
        }

        const component = get(field, "componentId", "Text");

        const fontStyle = get(field, "fontStyle", "bodyMd");
        const styleObject = get(fontObject, fontStyle);

        return (
          <>
            {skeleton ? (
              <SkeletonLoader
                widthRange={[80, 160]}
                columnSpan={field.columnSpan}
                rowSpan={field.rowSpan}
              />
            ) : (
              <TextWrapper
                position={field.position}
                columnSpan={field.columnSpan}
                rowSpan={field.rowSpan}
                onClick={(e) => {
                  if (isFrontlyAdmin) {
                    setActiveEditField({
                      id: field.id,
                      target: e.currentTarget,
                      blockId,
                    });
                  }
                }}
                handleFieldClick={isFrontlyAdmin}
              >
                {component === "Text" && (
                  <StyledText
                    {...field}
                    {...styleObject}
                    color={fieldTextColor}
                  >
                    {value}
                  </StyledText>
                )}
              </TextWrapper>
            )}
          </>
        );
      })}
      {badges.length > 0 && (
        <BadgesContainer>
          {badges.map((v) => {
            const color =
              !isEmpty(customBadgeColors) && customBadgeColors
                ? handleCustomBadgeColor(v)
                : get(combinedBadgeColorMap, v, "grey");
            return <Badge value={v || "No Value"} color={color} />;
          })}
        </BadgesContainer>
      )}
    </CardContainer>
  );
};

export default CardContent;

function determinePositions(gridColumns, items) {
  let currentColumn = 0; // Tracks the current column within a row

  return items.map((item) => {
    const positionData = { ...item };

    const colSpan = parseInt(item.columnSpan || 1);

    // Check if the item fits in the current row
    if (currentColumn + colSpan > gridColumns) {
      // Move to the next row if it overflows
      currentColumn = 0;
    }

    // Calculate the start and end column
    const startColumn = currentColumn + 1;
    const endColumn = currentColumn + colSpan;

    // Determine position based on start and end column
    if (startColumn === 1) {
      positionData.position = "left";
    } else if (endColumn === gridColumns) {
      positionData.position = "right";
    } else {
      positionData.position = "center";
    }

    // Update the current column for the next item
    currentColumn += colSpan;

    return positionData;
  });
}

const TextWrapper = styled.div`
  ${(p) =>
    p.handleFieldClick &&
    `
    &:hover{
      outline: 2px solid ${colors.frontlyBlue};
      cursor: pointer;
      border-radius: 4px;
    }
  `};
  grid-column: span ${(p) => p.columnSpan};
  grid-row: span ${(p) => p.rowSpan};
  text-align: ${(p) => p.position};
`;

const CardContainer = styled.div`
  gap: 5px;
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(${(p) => p.gridColumns}, 1fr);
  grid-template-rows: auto;
`;

const BadgesContainer = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 5px;
  flex-wrap: wrap;
`;

const StyledText = styled.div`
  font-size: ${(p) => getPixels(p.fontSize)};
  font-weight: ${(p) => p.fontWeight};
  color: ${(p) => p.color || p.fontColor || colors.default};
`;
