import { Row, SkeletonLoader } from "..";
import {
  defaultTrue,
  formatEuro,
  formatNumber,
  getPixels,
} from "app/utils/utils";
import { rApp, rDarkMode } from "app/utils/recoil";

import ChartHoverPopup from "./ChartHoverPopup";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { getColor } from "./utils";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import { useState } from "react";

const BarVertical = ({ data }) => {
  const activeApp = useRecoilValue(rApp);
  const values = get(data, "values", []);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [mouseLocation, setMouseLocation] = useState({ top: 100, left: 100 });
  const hoveredItem = get(values, hoveredIndex);

  const darkMode = useRecoilValue(rDarkMode);

  const itemClickAction = get(data, "itemClickAction");

  const colorScheme = get(data, "colorScheme");
  const customColorScheme = get(data, "customColorScheme");

  const isEuroDecimal = get(activeApp, "number_format") === "euro";

  const handleMouseMove = (e) => {
    setMouseLocation({ top: e.clientY, left: e.clientX });
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const max = Math.max(...values.map((item) => parseFloat(item["value"])));

  const showLabels = defaultTrue(get(data, "showLabels"));
  const showValues = defaultTrue(get(data, "showValues"));
  const decimalPlaces = get(data, "decimalPlaces", 0);

  const flexContainer = get(data, "flexContainerWidth", true);
  const flexBarThickness = get(data, "flexBarThickness", false);
  const barGap = get(data, "barGap", 20);
  const barBorderRadius = get(data, "barBorderRadius", 10);
  const barThickness = flexBarThickness
    ? "100%"
    : get(data, "barThickness", 10);

  const valuePrefix = get(data, "valuePrefix") || "";
  const valueSuffix = get(data, "valueSuffix") || "";

  return (
    <Container
      onMouseMove={handleMouseMove}
      gap={barGap}
      skeleton={data.isFetching}
    >
      {hoveredIndex !== null && (
        <ChartHoverPopup
          item={hoveredItem}
          location={mouseLocation}
          index={hoveredIndex}
          colorScheme={colorScheme}
          data={data}
        />
      )}

      {data.isFetching && (
        <Row gap="20px" width="100%" alignItems="flex-end">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(
            (r, i) => {
              return (
                <SkeletonLoader
                  heightRange={[60, 300]}
                  width="30px"
                  calcWidth={"calc((100% / 16) - 20px)"}
                />
              );
            }
          )}
        </Row>
      )}

      {!data.isFetching &&
        values.map((item, index) => {
          const heightPercent = (parseFloat(item.value) / max) * 100;

          return (
            <ItemContainer
              onClick={() => itemClickAction(item)}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              flex={flexContainer}
            >
              <ValueContainer>
                {showValues && values.length < 30 && (
                  <Value darkMode={darkMode}>
                    {valuePrefix}
                    {isEuroDecimal
                      ? formatEuro(item.value, decimalPlaces)
                      : formatNumber(item.value, decimalPlaces)}
                    {valueSuffix}
                  </Value>
                )}
                <ValueBar
                  width={barThickness}
                  color={getColor(index, colorScheme, customColorScheme)}
                  height={heightPercent}
                  borderRadius={barBorderRadius}
                >
                  &nbsp;
                </ValueBar>
              </ValueContainer>
              {showLabels && values.length < 30 && (
                <ItemLabel darkMode={darkMode}>{item.label || "-"}</ItemLabel>
              )}
            </ItemContainer>
          );
        })}
    </Container>
  );
};

export default BarVertical;

const Container = styled.div`
  width: 100%;
  display: flex;
  gap: ${(p) => getPixels(p.gap)};
  flex: 1;
  overflow-y: auto;
  overflow-x: auto;
  // max-height: 294px;
  ${(p) => p.skeleton && "align-items: flex-end;"}
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  ${(p) => (p.flex ? "flex: 1;" : "")};
  cursor: pointer;
  gap: 5px;
`;

const ItemLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align: right;
  white-space: nowrap;
  color: ${(p) => (p.darkMode ? colors.grey1 : "var(--grey8)")};
`;

const ValueBar = styled.div`
  background: ${(p) => p.color || "blue"};
  border-radius: ${(p) => getPixels(p.borderRadius)};
  width: ${(p) => (p.width ? getPixels(p.width) : "100%")};
  height: ${(p) => p.height}%;
  &:hover {
    filter: brightness(90%);
  }
`;

const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 200px;
  gap: 5px;
`;

const Value = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${(p) => (p.darkMode ? colors.grey1 : "var(--grey8)")};
`;
