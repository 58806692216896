import {
  Button,
  FullScreenLoader,
  Icon,
  MultiSelect,
  Row,
  SearchBar,
  Select,
} from "app/components";
import { boxShadow, colors } from "app/utils/theme";
import { useEffect, useState } from "react";

import { Header } from "../landing/Header";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import styled from "styled-components";
import { truncateText } from "app/utils/utils";
import { useNavigate } from "react-router-dom";
import useWindowSize from "app/utils/useWindowSize";

const Experts = () => {
  const { width: windowWidth } = useWindowSize();

  return (
    <div>
      <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
        <Header />
      </div>
      <Banner>
        <BannerContent>
          <HeaderText>Hire a Frontly Expert</HeaderText>
          <Description>
            Frontly Certified Experts are independent consultants and agencies
            who build powerful custom solutions with Frontly.
          </Description>

          <Row gap="15px" style={{ flexWrap: "wrap" }}>
            {windowWidth > 800 && (
              <Button
                data={{
                  text: "Get Matched With An Expert",
                  type: "basic",
                  onClick: () =>
                    (window.location.href = "mailto:support@frontlyapp.com"),
                }}
              />
            )}
            <Button
              data={{
                text: "Apply To Become An Expert",
                backgroundColor: "transparent",
                border: "1px solid white",
                onClick: () =>
                  window.open(
                    "https://docs.google.com/forms/d/e/1FAIpQLSehsRAGoXyBgWFsgnTg_zl-GTztUk-VLu2SrRokhw2xlkfOAQ/viewform?usp=sf_link"
                  ),
              }}
            />
          </Row>
        </BannerContent>
      </Banner>
      <Body>
        <ExpertsList />
      </Body>
    </div>
  );
};

export const ExpertsList = ({ internal }) => {
  const [experts, setExperts] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const { width: windowWidth } = useWindowSize();

  useEffect(() => {
    setIsFetching(true);
    apiRequest.get("/experts/").then((res) => {
      const expertsList = get(res, "data", []).map((e) => ({
        ...e,
        id: get(e, "id"),
        name: get(e, "name"),
        ...get(e, "data", {}),
      }));
      setExperts(expertsList);
      setIsFetching(false);
    });
  }, []);

  const [search, setSearch] = useState("");
  const [location, setLocation] = useState(null);
  const [language, setLanguage] = useState(null);
  const [specialization, setSpecialization] = useState(null);
  const [budget, setBudget] = useState(null);
  const [sorting, setSorting] = useState(null);

  const navigate = useNavigate();

  // Get all languages
  const languages = [
    ...new Set(
      experts.flatMap((obj) => obj.languages.split(",").map((s) => s.trim()))
    ),
  ].map((l) => ({
    label: l,
    value: l,
  }));

  // Get all locations
  const locations = [...new Set(experts.map((obj) => obj.location))].map(
    (l) => ({
      label: l,
      value: l,
    })
  );

  // Get all specializations
  const specializations = [
    ...new Set(
      experts.flatMap((obj) =>
        obj.specializations.split(",").map((s) => s.trim())
      )
    ),
  ].map((l) => ({
    label: l,
    value: l,
  }));

  const filteredExperts = experts.filter((e) => {
    const specializationsArray =
      specialization && specialization.split(",").map((s) => s.trim()); // Split by comma and trim spaces

    if (location && e.location !== location) return false;
    if (language && !e.languages.includes(language)) return false;

    if (
      specializationsArray &&
      specializationsArray.length > 0 &&
      !e.specializations
        .split(",")
        .map((s) => s.trim())
        .some((element) => specializationsArray.includes(element))
    )
      return false;
    if (budget && e.minBudget > budget) return false;
    if (
      search !== "" &&
      !e.name.toLowerCase().includes(search.toLowerCase()) &&
      !e.description.toLowerCase().includes(search.toLowerCase())
    )
      return false;
    return true;
  });

  if (isFetching) {
    return <FullScreenLoader />;
  }

  return (
    <div>
      <Filters>
        {windowWidth > 800 && (
          <Filter>
            <Label>Location / Region</Label>
            <Select
              data={{
                value: location,
                label: "Location",
                options: locations,
                onChange: (v) => setLocation(v),
              }}
            />
          </Filter>
        )}

        <Filter>
          <Label>Language</Label>
          <Select
            data={{
              value: language,
              label: "Language",
              options: languages,
              onChange: (v) => setLanguage(v),
            }}
          />
        </Filter>

        <Filter>
          <Label>Specializations</Label>
          <MultiSelect
            data={{
              value: specialization,
              label: "Specializations",
              options: specializations,
              onChange: (v) => setSpecialization(v),
            }}
          />
        </Filter>

        <Filter>
          <Label>Project Budget</Label>
          <Select
            data={{
              value: budget,
              label: "Max Budget",
              onChange: (v) => setBudget(v),
              options: [
                { label: "$500", value: 500 },
                { label: "$1000", value: 1000 },
                { label: "$5,000", value: 5000 },
                { label: "$10,000", value: 10000 },
              ],
            }}
          />
        </Filter>
      </Filters>

      {windowWidth > 800 && (
        <Row gap="30px" margin="0 0 30px 0">
          <SearchBar
            data={{
              width: "100%",
              value: search,
              placeholder: "Search by name or description",
              onChange: (v) => setSearch(v),
            }}
          />
          {/* <Select
          data={{
            width: "200px",
            label: "Sorting",
            value: sorting,
            onChange: (v) => setSorting(v),
            options: [
              { label: "Name", value: "name" },
              { label: "Rating", value: "rating" },
              { label: "Projects #", value: "projects" },
            ],
          }}
        /> */}
        </Row>
      )}

      <Grid count={filteredExperts.length}>
        {filteredExperts.map((expert) => (
          <ExpertCard
            onClick={() =>
              navigate(
                internal ? `/profile/${expert.id}` : `/expert/${expert.id}`
              )
            }
          >
            <ExpertImage src={expert.image} />
            <ExpertName>{expert.name}</ExpertName>
            <ExpertDescription>
              {truncateText(expert.short_description, 80)}
            </ExpertDescription>
            <Row alignItems="center">
              <RatingStars rating={expert.average_rating || 5} />
              <RatingText>{expert.average_rating || 0.0}</RatingText>
              <RatingCount>({expert.review_count || 0})</RatingCount>
            </Row>
          </ExpertCard>
        ))}
      </Grid>
    </div>
  );
};

export default Experts;

const BannerContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 30px 50px 30px;
  @media (max-width: 800px) {
    padding: 30px 20px 40px 20px;
  }
`;

const HeaderText = styled.div`
  font-size: 40px;
  font-weight: 700;
  color: white;
  @media (max-width: 800px) {
    font-size: 28px;
  }
`;

const Description = styled.div`
  font-size: 18px;
  margin: 15px 0 30px 0;
  color: white;
  @media (max-width: 800px) {
    font-size: 14px;
    margin: 10px 0 20px 0;
  }
`;

const Body = styled.div`
  padding: 30px;
  max-width: 1200px;
  margin: 0 auto;
  @media (max-width: 800px) {
    padding: 20px;
  }
`;

const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0 20px 0;
  gap: 30px;
  flex-wrap: wrap;
  @media (max-width: 800px) {
    gap: 15px;
    flex-direction: column;
    margin-top: 5px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  ${(p) => p.count && p.count < 4 && "grid-template-columns: 1fr 1fr 1fr;"}
  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;

const ExpertCard = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: ${boxShadow.card};
  cursor: pointer;
  @media (max-width: 800px) {
    padding: 15px;
  }
`;

const ExpertName = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0 0 0;
  @media (max-width: 800px) {
    font-size: 16px;
    margin: 8px 0 0 0;
  }
`;

const ExpertDescription = styled.div`
  font-size: 16px;
  margin: 10px 0;
  @media (max-width: 800px) {
    font-size: 14px;
    margin: 8px 0;
  }
`;

export const RatingText = styled.div`
  font-size: 16px;
  margin-left: 5px;
  font-weight: 600;
  @media (max-width: 800px) {
    font-size: 14px;
  }
`;

export const RatingCount = styled.div`
  font-size: 16px;
  margin-left: 5px;
  @media (max-width: 800px) {
    font-size: 14px;
  }
`;

const Banner = styled.div`
  background: linear-gradient(
    150deg,
    rgb(170, 0, 255) 0%,
    rgb(0, 85, 255) 100%
  );
`;

// const BannerContent = styled.div`
//   max-width: 1200px;
//   margin: 0 auto;
//   padding: 40px 30px 50px 30px;
// `;

// const HeaderText = styled.div`
//   font-size: 40px;
//   font-weight: 700;
//   color: white;
// `;

// const Description = styled.div`
//   font-size: 18px;
//   margin: 15px 0 30px 0;
//   color: white;
// `;

// const Body = styled.div`
//   padding: 30px;
//   max-width: 1200px;
//   margin: 0 auto;
// `;

// const Filters = styled.div`
//   display: flex;
//   justify-content: space-between;
//   margin: 20px 0;
//   gap: 30px;
//   flex-wrap: wrap;
//   @media screen and (max-width: 800px) {
//     gap: 15px;
//   }
// `;

const Filter = styled.div`
  flex: 1;
`;

const Label = styled.div`
  font-size: 15px;
  font-weight: 600;
  margin: 0 0 8px 0;
`;

// const Grid = styled.div`
//   display: grid;
//   grid-gap: 20px;
//   grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
//   ${(p) => p.count && p.count < 4 && "grid-template-columns: 1fr 1fr 1fr;"}
//   @media (max-width: 800px) {
//     grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
//   }
// `;

// const ExpertCard = styled.div`
//   background: #fff;
//   padding: 20px;
//   border-radius: 8px;
//   box-shadow: ${boxShadow.card};
//   cursor: pointer;
// `;

// const ExpertName = styled.div`
//   font-size: 18px;
//   font-weight: 600;
//   margin: 10px 0 0 0;
// `;

// const ExpertDescription = styled.div`
//   font-size: 16px;
//   margin: 10px 0;
// `;

// export const RatingText = styled.div`
//   font-size: 16px;
//   margin-left: 5px;
//   font-weight: 600;
// `;

// export const RatingCount = styled.div`
//   font-size: 16px;
//   margin-left: 5px;
// `;

export const RatingStars = ({ rating }) => {
  return (
    <Row>
      {[...Array(5)].map((_, i) => (
        <Icon
          data={{
            size: 20,
            icon: "BsFillStarFill",
            color: parseInt(rating) >= i ? "#FFD700" : "var(--grey21)",
          }}
        />
      ))}
    </Row>
  );
};

const NoImageWrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: center;
  width: 100%;
  height: 200px;
  border: 1px solid var(--grey21);
  background: var(--grey1);
  border-radius: 8px;
`;

const Thumb = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border: 1px solid var(--grey21);
  border-radius: 8px;
  object-fit: cover;
`;

const ExpertImage = ({ src }) => {
  const [error, setError] = useState(false);

  if (!src || error) {
    return (
      <NoImageWrapper src={src}>
        <Icon data={{ icon: "FiImage", color: "var(--grey21)", size: 40 }} />
      </NoImageWrapper>
    );
  }
  return <Thumb src={src} onError={() => setError(true)} />;
};
