import { Icon, Row, Text } from "app/components";

import { BackButton } from "app/adminApp/components";
import { Item } from "app/adminApp/home/AdminWrapper";
import { colors } from "app/utils/theme";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

export const SettingsWrapper = ({ label, sections, tab, children }) => {
  return (
    <Container>
      <SettingsSections label={label} sections={sections} tab={tab} />
      <Content>{children}</Content>
    </Container>
  );
};

const SettingsSections = ({ sections, tab }) => {
  const navigate = useNavigate();
  return (
    <Sidebar>
      <Row
        alignItems="center"
        gap="5px"
        onClick={() => navigate("/home")}
        margin="5px 0 10px 5px"
        style={{ cursor: "pointer" }}
      >
        <Icon
          data={{
            icon: "FiArrowLeft",
            color: "var(--grey8)",
            margin: "1px 0 0 0",
            size: 22,
            hover: true,
          }}
        />
        <Text
          data={{
            text: "Back",
            fontSize: 20,
            fontWeight: 500,
            cursor: "pointer",
          }}
        />
      </Row>
      <SectionsContainer>
        {sections.map((section) => (
          <Item item={section} active={section.id === tab} />
        ))}
      </SectionsContainer>
    </Sidebar>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background: var(--grey1);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  overflow-y: auto;
  padding: 40px;
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  min-width: 240px;
  overflow: hidden;
  padding: 15px 10px 10px 10px;
  box-shadow: var(--sidebar-shadow);
  height: 100%;
`;

const SectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
  overflow-y: auto;
  height: 100%;
`;
