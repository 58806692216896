import { startCase } from "lodash";

export const colors = {
  frontlyBlue: "#3043cd",
  default: "#202223",
  primary: "#000000",
  primaryUltraLight: "#f0f2ff",

  grey1: "#f8f9fc",
  grey15: "#edefF2",
  grey2: "#e1e4e8",
  grey3: "#8e949e",
  grey4: "#3e4b60",
  grey5: "#1d293d",
  divider: "#d5d7da",
  background: "#F5F5F5",

  // TRYING NEW
  clickUpBackground: "#F0F0F3",
  clickUpMedGrey: "#979797",
  clickUpIconGrey: "#7d828c",
  clickUpDarkGrey: "#54575d",
  clickUpDefault: "#2a2e34",
  clickUpInputGrey: "#f6f7f9",

  clickUpSidebarHighlight: "#eff1fc",

  pitchBorder: "#d5d7da",
  pitchBackground: "#f8f8fa",

  darkModeBackground: "#1c1c1c",
  darkModeLightBackground: "#232323",
  darkModeLightBorder: "#2e2e2e",
  darkModeGrey: "#343434",
  darkModeInputBackground: "#282828",
  darkModeButtonBorder: "#3e3e3e",
  darkModeLightGrey: "#767676",
  darkModeLightText: "#A0A0A0",
  darkModeLightText2: "#ededed",
};

export const badges = {
  grey: "#7f8297",
  red: "#df516e",
  orange: "#f79e44",
  yellow: "#f7c744",
  green: "#74c98f",
  blue: "#429ef0",
  purple: "#6945e1",
};

// For use with badges
export const hslColor = (hue, saturation, lightness) => {
  return `hsl(${hue},${saturation}%,${lightness}%)`;
};

// TODO - update every instance of padding / margin in the app to use this, when possible
export const spacing = {
  s0: "0px",
  s025: "1px",
  s05: "2px",
  s1: "4px",
  s2: "8px",
  s3: "12px",
  s4: "16px",
  s5: "20px",
  s6: "24px",
  s8: "32px",
  s10: "40px",
  s12: "48px",
  s16: "64px",
  s20: "80px",
  s24: "96px",
  s32: "128px",
};

export const boxShadow = {
  insetButtonPressed: "box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.05)",
  button: "0px 1px 0px rgba(0, 0, 0, 0.05)",
  card: "0px 0px 5px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15)",
  popover:
    "0px 3px 6px -3px rgba(23, 24, 24, 0.08), 0px 8px 20px -4px rgba(23, 24, 24, 0.12)",
  popoverDark:
    "0px 3px 6px -3px rgba(23, 24, 24, 0.18), 0px 8px 20px -4px rgba(23, 24, 24, 0.22)",
  deep: "0px 0px 0px 1px rgba(6, 44, 82, 0.1), 0px 2px 16px rgba(33, 43, 54, 0.08)",
  modal: "0px 0px 1px rgba(0, 0, 0, 0.2), 0px 25px 80px rgba(0, 0, 0, 0.2)",
  base: "0px 1px 3px rgba(63, 63, 68, 0.15), 0px 0px 0px 1px rgba(63, 63, 68, 0.05)",
  innerShadow: "inset -1px 0px 0px #E4E5E7",
};

export const fontObject = {
  heading5xl: {
    // Patrick added this one
    fontSize: 60,
    // lineHeight: 48,
    fontWeight: 600,
  },
  //
  heading4xl: {
    fontSize: 40,
    // lineHeight: 48,
    fontWeight: 600,
  },
  heading3xl: {
    fontSize: 32,
    // lineHeight: 40,
    fontWeight: 600,
  },
  heading2xl: {
    fontSize: 28,
    // lineHeight: 32,
    fontWeight: 600,
  },
  headingXl500: {
    fontSize: 24,
    // lineHeight: 28,
    fontWeight: 500,
  },
  headingXl: {
    fontSize: 24,
    // lineHeight: 28,
    fontWeight: 600,
  },
  headingLg: {
    fontSize: 20,
    // lineHeight: 24,
    fontWeight: 600,
  },
  headingMd: {
    fontSize: 16,
    // lineHeight: 24,
    fontWeight: 600,
  },
  headingSm: {
    fontSize: 14,
    // lineHeight: 20,
    fontWeight: 600,
  },
  headingSmBold: {
    fontSize: 14,
    // lineHeight: 20,
    fontWeight: 700,
  },
  headingXs: {
    fontSize: 12,
    // lineHeight: 16,
    fontWeight: 600,
  },
  heading2Xs: {
    fontSize: 10,
    // lineHeight: 10,
    fontWeight: 600,
  },
  // Patrick added this one
  body2xl: {
    fontSize: 24,
    // lineHeight: 24,
    fontWeight: 400,
  },
  // Patrick added this one
  bodyXl: {
    fontSize: 20,
    // lineHeight: 24,
    fontWeight: 400,
  },
  bodyLgMedium: {
    fontSize: 16,
    // lineHeight: 20,
    fontWeight: 500,
  },
  bodyLg: {
    fontSize: 16,
    // lineHeight: 20,
    fontWeight: 400,
  },
  bodyMd: {
    fontSize: 14,
    // lineHeight: 18,
    fontWeight: 400,
  },
  bodyMdMedium: {
    fontSize: 14,
    // lineHeight: 18,
    fontWeight: 500,
  },
  bodySm: {
    fontSize: 12,
    // lineHeight: 16,
    fontWeight: 400,
  },

  // Patrick added this one
  bodyXs: {
    fontSize: 10,
    // lineHeight: 12,
    fontWeight: 400,
  },
};

export const fontOptions = Object.keys(fontObject).map((k) => ({
  label: startCase(k),
  value: k,
}));
