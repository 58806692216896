import { CenterContainer, HeaderDescription, HeaderText } from "../components";
import { Icon, Text } from "app/components";

import ShowExamples from "../../Examples";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { getPixels } from "app/utils/utils";
import { rDescription } from "../state";
import styled from "styled-components";
import { useRecoilState } from "recoil";

// Initial Prompt Input Screen
const Prompt = ({ handleSubmit }) => {
  const [description, setDescription] = useRecoilState(rDescription);

  const charCount = get(description, "length", 0);
  const overLimit = charCount > 1000;

  const disableSubmit = charCount < 10 || charCount > 1000;

  const submit = () => {
    if (disableSubmit) return;
    handleSubmit();
  };

  return (
    <CenterContainer>
      <HeaderText>Start your app with AI</HeaderText>
      <HeaderDescription>
        Describe your app to generate the first version, then customize with no
        code
      </HeaderDescription>
      <SearchContainer>
        <SearchInput
          autoFocus
          height={() => getPixels(charCount / 2)}
          placeholder="An analytics dashboard for my landscaping business showing total sales and average revenue per client."
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <SubmitButton disabled={disableSubmit} onClick={submit}>
          <Text
            data={{
              text: "Submit",
              fontStyle: "headingMd",
              color: "white",
              cursor: disableSubmit ? "not-allowed" : "pointer",
            }}
          />
          <Icon data={{ icon: "HiSparkles", color: "white", hover: true }} />
        </SubmitButton>
        <CharCountMessage overLimit={overLimit}>
          {charCount} / 1000
        </CharCountMessage>
      </SearchContainer>
      <ShowExamples
        setPrompt={(p) => {
          setDescription(p);
        }}
      />
    </CenterContainer>
  );
};

export default Prompt;

const SubmitButton = styled.div`
  background: rgb(0, 85, 255);
  color: white;
  font-weight: 500;
  font-size: 20px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  pading: 15px;
  width: 150px;
  height: 50px;
  cursor: pointer;
  ${(p) => p.disabled && "cursor: not-allowed;"}
  &:hover {
    filter: brightness(90%);
  }
`;

const CharCountMessage = styled.div`
  margin: 5px 0 0 3px;
  color: ${(p) => (p.overLimit ? "red" : "var(--grey8)")};
  position: absolute;
  font-size: 12px;
  bottom: 6px;
  right: 20px;
`;

const SearchInput = styled.textarea`
  padding: 15px;
  width: 100%;
  border: 0px;
  outline: none;
  resize: none;
  font-size: 20px;
  min-height: 100px;
  height: ${(p) => p.height};
  border-radius: 20px;
  &::placeholder {
    color: var(--grey7);
  }
  @media screen and (max-width: 800px) {
    padding: 12px;
  }
`;

const SearchContainer = styled.div`
  outline: 10px solid #ffffff50;
  background: white;
  border-radius: 30px;
  display: flex;
  padding: 8px 15px 8px 8px;
  align-items: center;
  width: 100%;
  max-width: 800px;
  position: relative;
  @media screen and (max-width: 1000px) {
    max-width: calc(100% - 40px);
  }
  @media screen and (max-width: 800px) {
    outline: 5px solid #ffffff50;
  }
`;
