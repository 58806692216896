import { AdminForm, BackButton } from "../../components";
import { Button, Icon, Modal, Row, Spinner, Text } from "app/components";
import { get, snakeCase, startCase } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import AdminWrapper from "../../home/AdminWrapper";
import { Label } from "../../home/Pages";
import { Tooltip } from "react-tooltip";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import { formatNumber } from "app/utils/utils";
import { rOrganization } from "app/utils/recoil";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";
import { useRecoilValue } from "recoil";

const Project = () => {
  const { id } = useParams();

  const [project, setProject] = useState(null);

  const organization = useRecoilValue(rOrganization);
  const orgId = get(organization, "id");

  const expertOrgId = get(project, "expert_org");
  const isExpertProfile = expertOrgId === orgId;

  const projectIsNotNull = project !== null;

  // useEffect(() => {
  //   if (projectIsNotNull) {
  //     const wsURL = `wss://f8lzd569pg.execute-api.ca-central-1.amazonaws.com/production?project_id=${id}&org_id=${orgId}&function=project_chat`;
  //     console.log("wsURL", wsURL);
  //     const ws = new WebSocket(wsURL);

  //     ws.onclose = () => {
  //       console.log("DISCONNECTED");
  //     };

  //     ws.onopen = () => {
  //       console.log("CONNECTED");
  //     };

  //     ws.onmessage = (event) => {
  //       const wsResponse = JSON.parse(event.data);

  //       const incomingMessage = get(wsResponse, "message");
  //       const incomingMessageType = get(wsResponse, "type");

  //       setProject((prev) => {
  //         const prevMessages = get(prev, "messages", []);
  //         const newId = getHighest(messages, "id") + 1;

  //         const newMsg = {
  //           id: 123,
  //           message: incomingMessage,
  //           type: incomingMessageType,
  //           expert_id: isExpertProfile ? null : project.expert,
  //           client_id: isExpertProfile ? orgId : null,
  //           date: moment().format(),
  //         };

  //         setNewMessageId(newId);

  //         return {
  //           ...prev,
  //           messages: [...prevMessages, newMsg],
  //         };
  //       });
  //     };

  //     window.ws = ws;

  //     return () => {
  //       ws.close();
  //     };
  //   }
  // }, [projectIsNotNull]);

  const [newMessage, setNewMessage] = useState("");

  const [newMessageId, setNewMessageId] = useState(null);

  // useEffect(() => {
  //   const element = document.getElementById(`message${newMessageId}`);
  //   if (element) {
  //     // Scroll to active block
  //     element.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [newMessageId]);

  // const sendMessage = (type, obj) => {
  //   const isValidFile = type === "file" && get(obj, "message");
  //   const isValidChat = type === "chat" && newMessage;

  //   if (isValidFile || isValidChat) {
  //     const newId = getHighest(messages, "id") + 1;

  //     setProject({
  //       ...project,
  //       messages: [
  //         ...messages,
  //         {
  //           id: newId,
  //           type,
  //           ...obj,
  //           client_id: isExpertProfile ? null : orgId,
  //           expert_id: isExpertProfile ? project.expert : null,
  //           date: moment().format(),
  //         },
  //       ],
  //     });

  //     setNewMessage("");
  //     setNewMessageId(newId);

  //     const body = {
  //       project_id: id,
  //       ...obj,
  //       org_id: orgId,
  //       type,
  //     };

  //     apiRequest.post("/send_project_message/", body);

  //     if (window.ws && window.ws.readyState === WebSocket.OPEN) {
  //       window.ws.send(
  //         JSON.stringify({
  //           action: "sendMessage",
  //           ...body,
  //         })
  //       );
  //     } else {
  //       console.error("WebSocket is not open.");
  //     }
  //   }
  // };

  // const messages = get(project, "messages", []);
  const quote = get(project, "quote");

  const [isFetching, setIsFetching] = useState(false);
  const [showEditQuoteModal, setShowEditQuoteModal] = useState(false);
  const [quoteData, setQuoteData] = useState({});
  const [showRejectQuoteModal, setShowRejectQuoteModal] = useState(false);
  const [rejectQuoteReason, setRejectQuoteReason] = useState("");
  const [showCloseProjectModal, setShowCloseProjectModal] = useState(false);
  const [closeProjectReason, setCloseProjectReason] = useState("");
  const [isCreatingQuote, setIsCreatingQuote] = useState(false);
  const [showApproveQuoteModal, setShowApproveQuoteModal] = useState(false);

  // const statusColorMap = {
  //   pending: badges.yellow,
  //   approved: badges.orange,
  //   paid: badges.green,
  //   rejected: badges.red,
  // };

  console.log("project", project);

  useEffect(() => {
    setIsFetching(true);
    apiRequest.get(`/project/?id=${id}`).then((res) => {
      const projectData = get(res, "data", {});
      setProject(projectData);
      setIsFetching(false);
    });
  }, []);

  const navigate = useNavigate();

  const createQuote = (obj) => {
    setIsCreatingQuote(true);
    apiRequest
      .post("/create_project_quote/", { project_id: id, ...obj })
      .then((r) => {
        const newQuoteData = get(r, "data");
        setProject({
          ...project,
          quote: newQuoteData,
        });
        setIsCreatingQuote(false);
        successNotification("Quote submitted successfully");
      });
  };

  const closeProject = () => {
    apiRequest.post("/project/", {
      project_id: id,
      status: "cancelled",
      cancellation_reason: closeProjectReason,
    });
    setProject({
      ...project,
      status: "cancelled",
    });
    successNotification("Project closed");
  };

  const approveQuote = () => {
    apiRequest.post("/update_project_quote/", {
      project_id: id,
      quote_id: quote.id,
      status: "approved",
    });
    setProject({
      ...project,
      quote: {
        ...quote,
        status: "approved",
      },
    });
    successNotification("Quote Accepted");
  };

  const rejectQuote = () => {
    apiRequest.post("/update_project_quote/", {
      project_id: id,
      quote_id: showRejectQuoteModal,
      status: "rejected",
    });
    setProject({
      ...project,
      quotes: {
        ...quote,
        status: "rejected",
        client_note: rejectQuoteReason,
      },
    });
    successNotification("Quote rejected");
  };

  return (
    <AdminWrapper contentWidth="100%">
      {showApproveQuoteModal && (
        <Modal
          header={{
            title: "Approve Quote",
          }}
          hide={() => setShowApproveQuoteModal(null)}
          buttons={[
            {
              text: "Cancel",
              onClick: () => setShowApproveQuoteModal(null),
              type: "basic",
            },
            {
              text: "Approve & Request Invoice",
              onClick: () => {
                setShowApproveQuoteModal(null);
                approveQuote();
              },
            },
          ]}
        >
          <Text
            data={{
              text: `Are you sure you want to approve the quote from ${get(
                project,
                "recipient_name"
              )} for $${formatNumber(get(quote, "price"), 2)} USD?`,
              fontStyle: "headingMd",
            }}
          />
          <Text
            data={{
              text: "This quote covers all work and features described in your Project Overview.",
              fontStyle: "bodyLg",
              margin: "20px 0 20px 0",
            }}
          />
          <Text
            data={{
              text: "Once approved, Frontly's team will be in touch with an invoice to collect payment and begin the project.",
              fontStyle: "bodyLg",
            }}
          />
        </Modal>
      )}

      {showCloseProjectModal && (
        <Modal
          header={{
            title: "Close Project",
            description:
              "Provide a reason for closing this project. The other party will be notified.",
          }}
          hide={() => setShowCloseProjectModal(false)}
          buttons={[
            {
              text: "Cancel",
              onClick: () => setShowCloseProjectModal(false),
              type: "basic",
            },
            {
              text: "Close Project",
              onClick: () => {
                setShowCloseProjectModal(false);
                closeProject();
              },
            },
          ]}
        >
          <AdminForm
            margin={"20px 0 0 0"}
            sectionPadding={"0"}
            onChange={(k, v) => setCloseProjectReason(v)}
            fields={[
              {
                label: "Reason For Closing",
                id: "reason",
                description:
                  "Provide an explanation for why you are closing this project.",
                componentId: "TextArea",
                minHeight: "150px",
                value: closeProjectReason,
              },
            ]}
          />
        </Modal>
      )}

      {showRejectQuoteModal && (
        <Modal
          header={{
            title: "Reject Quote",
            description:
              "Provide a reason for rejecting this quote. The expert will be notified.",
          }}
          hide={() => setShowRejectQuoteModal(false)}
          buttons={[
            {
              text: "Cancel",
              onClick: () => setShowRejectQuoteModal(false),
              type: "basic",
            },
            {
              text: "Reject Quote",
              onClick: () => {
                setShowRejectQuoteModal(null);
                rejectQuote();
              },
            },
          ]}
        >
          <AdminForm
            margin={"20px 0 0 0"}
            sectionPadding={"0"}
            onChange={(k, v) => setRejectQuoteReason(v)}
            fields={[
              {
                label: "Rejection Reason",
                id: "reason",
                description:
                  "Provide an explanation for why you are rejecting this quote, and let the expert know if you're looking for them to revise it or if this is a final rejection.",
                componentId: "TextArea",
                minHeight: "150px",
                value: rejectQuoteReason,
              },
            ]}
          />
        </Modal>
      )}

      {showEditQuoteModal && (
        <Modal
          header={{
            title: "Submit A Project Quote",
            description:
              "Provide a quote for the client based on the project outline",
          }}
          hide={() => setShowEditQuoteModal(false)}
          buttons={[
            {
              text: "Cancel",
              onClick: () => setShowEditQuoteModal(false),
              type: "basic",
            },
            {
              text: "Submit Quote",
              onClick: () => {
                setShowEditQuoteModal(false);
                createQuote(quoteData);
              },
            },
          ]}
        >
          <AdminForm
            margin={"20px 0 0 0"}
            sectionPadding={"0"}
            onChange={(k, v) => setQuoteData({ ...quoteData, [k]: v })}
            fields={[
              {
                label: "Delivery Date",
                id: "delivery_date",
                description:
                  "Provide a realistic timeline that you are confident you can deliver the project by.",
                componentId: "DateTimePicker",
                value: get(quoteData, "delivery_date"),
                dateFormat: {
                  inputDate: "YYYY-MM-DD",
                  customFormat: null,
                  formatType: "default",
                },
              },
              {
                label: "Price (USD)",
                id: "price",
                description:
                  "Based on the current project outline. If the outline changes, you can update the quote.",
                componentId: "Input",
                type: "number",
                value: get(quoteData, "price"),
              },
            ]}
          />
        </Modal>
      )}

      {isFetching && (
        <>
          <BackButton
            width="150px"
            margin="0 0 20px 0"
            text="Projects"
            onClick={() => navigate("/projects")}
          />
          <Label>Project - Loading...</Label>
          <Spinner color="var(--grey5)" size={40} padding={"30px 0 0 0"} />
        </>
      )}

      {!isFetching && (
        <>
          <BackButton
            width="150px"
            margin="0 0 20px 0"
            text="Projects"
            onClick={() => navigate("/projects")}
          />
          <Row alignItems="center" justifyContent="space-between">
            <Label>
              Project - {get(project, "name")} (
              {startCase(get(project, "status"))})
            </Label>

            {get(project, "status") === "pending" && (
              <Row alignItems="center" gap="15px">
                {/* {isExpertProfile && (
                  <Button
                    data={{
                      text: "Create Project Quote",
                      icon: "FiPlus",
                      isFetching: isCreatingQuote,
                      onClick: () => setShowEditQuoteModal(true),
                    }}
                  />
                )} */}
                <Button
                  data={{
                    text: "Close Project",
                    icon: "FiXCircle",
                    type: "basic",
                    onClick: () => setShowCloseProjectModal(true),
                  }}
                />
              </Row>
            )}
          </Row>
          <Row gap="30px" margin="25px 0 0 0">
            <LeftSection>
              {/* {quote && (
                <QuoteContainer>
                  <Row justifyContent="space-between" alignItems="center">
                    <Row gap="15px" alignItems="center">
                      <Text
                        data={{
                          text: "Pricing Quote",
                          fontStyle: "headingLg",
                        }}
                      />
                      <StatusBadge
                        className="statusBadge"
                        color={get(statusColorMap, get(quote, "status"))}
                      >
                        {startCase(get(quote, "status"))}
                      </StatusBadge>
                    </Row>
                    <Text
                      data={{
                        text: `Received on ${moment(
                          get(quote, "created_date")
                        ).format("YYYY-MM-DD")}`,
                        fontStyle: "bodyMd",
                        color: "var(--grey7)",
                      }}
                    />
                  </Row>

                  <Row gap="15px" alignItems="center" margin="10px 0 10px 0">
                    <QuoteBubble
                      label="Project Budget"
                      value={`${formatNumber(get(quote, "price"), 2)} USD`}
                      icon={"FiDollarSign"}
                    />
                    <QuoteBubble
                      label="Estimated Delivery Date"
                      value={moment(get(quote, "delivery_date")).format(
                        "YYYY-MM-DD"
                      )}
                      icon={"FiCalendar"}
                    />
                  </Row>

                  {get(quote, "status") === "pending" && !isExpertProfile && (
                    <Row gap="15px">
                      <Button
                        data={{
                          text: "Approve",
                          onClick: () => setShowApproveQuoteModal(true),
                        }}
                      />
                      <Button
                        data={{
                          text: "Reject",
                          type: "basic",
                          onClick: () => setShowRejectQuoteModal(true),
                        }}
                      />
                    </Row>
                  )}
                </QuoteContainer>
              )}
               */}
              <OutlinePanel>
                <Text
                  data={{
                    text: "Project Summary",
                    fontStyle: "headingLg",
                    margin: "0 0 20px 0",
                  }}
                />
                <Text
                  data={{
                    text: get(project, "summary"),
                    whiteSpace: "pre-line",
                  }}
                />
                {/* {projectPlan && <RenderPlan plan={get(projectPlan, "response")} />}x */}
              </OutlinePanel>
            </LeftSection>

            <PreviewPanel>
              {isExpertProfile ? (
                <>
                  <Text
                    data={{
                      text: "Get In Touch With The Client",
                      fontStyle: "headingLg",
                      margin: "0 0 20px 0",
                    }}
                  />
                  <Text
                    data={{
                      text: "To continue the conversation with the client, please reach out to them directly via email.",
                      fontStyle: "bodyLg",
                      margin: "0 0 20px 0",
                    }}
                  />
                  <Text
                    data={{
                      text: "Client Email:",
                      fontStyle: "headingLg",
                      margin: "0 0 10px 0",
                    }}
                  />
                  <Text
                    data={{
                      text: get(project, "recipient_email"),
                      fontStyle: "headingLg",
                      color: "var(--primary-admin)",
                      allowSelect: true,
                    }}
                  />
                  <Text
                    data={{
                      text: "The client has been provided your email as well if they need to get in touch.",
                      fontStyle: "bodyLg",
                      margin: "20px 0 0 0",
                    }}
                  />
                </>
              ) : (
                <>
                  <Text
                    data={{
                      text: "Get In Touch With The Expert",
                      fontStyle: "headingLg",
                      margin: "0 0 20px 0",
                    }}
                  />
                  <Text
                    data={{
                      text: "To continue the conversation with the expert, please reach out to them directly via email.",
                      fontStyle: "bodyLg",
                      margin: "0 0 20px 0",
                    }}
                  />
                  <Text
                    data={{
                      text: "Expert Email:",
                      fontStyle: "headingLg",
                      margin: "0 0 10px 0",
                    }}
                  />
                  <Text
                    data={{
                      text: get(project, "recipient_email"),
                      fontStyle: "headingLg",
                      color: "var(--primary-admin)",
                      allowSelect: true,
                    }}
                  />
                  <Text
                    data={{
                      text: "The expert has been provided your email as well if they need to get in touch.",
                      fontStyle: "bodyLg",
                      margin: "20px 0 0 0",
                    }}
                  />
                </>
              )}

              {/* <Text
                data={{
                  text: `Conversation with ${get(project, "recipient_name")}`,
                  fontStyle: "headingLg",
                  margin: "0 0 20px 0",
                }}
              />
              <MessagesContainer>
                {messages.length === 0 && (
                  <Text
                    data={{ text: "There are no messages in this project yet" }}
                  />
                )}
                {messages.map((m) => {
                  const isCurrent = isExpertProfile
                    ? m.expert_id === project.expert
                    : m.client_id === orgId;

                  const messageType = get(m, "type", "chat");

                  const fileFormat =
                    messageType === "file" && getFileFormat({ url: m.message });

                  return (
                    <>
                      <Tooltip
                        anchorSelect={`.message${m.id}`}
                        place="left"
                        style={{ zIndex: 9999 }}
                      >
                        {moment(m.date).fromNow()}
                      </Tooltip>
                      {messageType === "chat" && (
                        <MessageContainer
                          id={`message${m.id}`}
                          className={`message${m.id}`}
                          currentUser={isCurrent}
                        >
                          {m.message}
                        </MessageContainer>
                      )}
                      {messageType === "file" && (
                        <ChatFile
                          id={`message${m.id}`}
                          className={`message${m.id}`}
                          currentUser={isCurrent}
                          onClick={() => window.open(m.message, "_blank")}
                        >
                          <Icon
                            data={{
                              hover: true,
                              icon: ["PNG", "JPG", "JPEG", "WEBP"].includes(
                                fileFormat
                              )
                                ? "FiImage"
                                : "FiFile",
                              size: 20,
                              color: "var(--grey7)",
                            }}
                          />
                          <Text
                            data={{
                              cursor: "pointer",
                              text: get(m, "message", "").split("/").pop(),
                              fontStyle: "headingSm",
                            }}
                          />
                          <Text
                            data={{
                              text: fileFormat,
                              fontStyle: "headingSm",
                              color: "var(--grey7)",
                            }}
                          />
                        </ChatFile>
                      )}
                    </>
                  );
                })}
              </MessagesContainer>
              <StyledTextArea
                onChange={(e) => setNewMessage(e.target.value)}
                value={newMessage}
                placeholder="Type your message here"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    sendMessage("chat", {
                      message: newMessage,
                    });
                  }
                }}
              />
              <Row margin={"20px 0 0 0"} gap={"15px"}>
                <Button
                  data={{
                    text: "Send Message",
                    icon: "BsSend",
                    disabled: newMessage.length < 2,
                    onClick: () =>
                      sendMessage("chat", {
                        message: newMessage,
                      }),
                  }}
                />

                <FileUpload
                  data={{
                    value: "",
                    hideFilename: true,
                    text: "Add File",
                    icon: "FiPlus",
                    size: "medium",
                    buttonType: "basic",
                    isAdmin: true,
                    onAssetUploaded: (newFile) => {
                      const url = get(newFile, "url");
                      sendMessage("file", {
                        message: url,
                      });
                    },
                  }}
                />
              </Row> */}
            </PreviewPanel>
          </Row>
        </>
      )}
    </AdminWrapper>
  );
};

export default Project;

const QuoteBubble = ({ label, value, icon }) => {
  const classId = `quoteBubble${snakeCase(label)}`;

  return (
    <>
      <Tooltip
        anchorSelect={`.${classId}`}
        place="bottom"
        style={{ zIndex: 9999 }}
      >
        {label}
      </Tooltip>
      <BubbleContainer className={classId}>
        <Icon
          data={{ icon, size: 20, color: "var(--grey7)", cursor: "pointer" }}
        />
        <Text
          data={{
            text: value,
            color: "var(--grey8)",
            fontStyle: "headingSm",
            hover: true,
          }}
        />
      </BubbleContainer>
    </>
  );
};

const BubbleContainer = styled.div`
  padding: 10px;
  border: 1px solid var(--grey21);
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
`;

const StatusBadge = styled.div`
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  background: ${(p) => p.color};
  color: white;
  font-size: 14px;
  font-weight: 500;
  width: fit-content;
`;

const StyledTextArea = styled.textarea`
  resize: none;
  min-height: 50px;
  height: fit-content;
  background: var(--grey1);
  border: 1px solid var(--grey21);
  width: 100%;
  padding: 15px;
  font-size: 14px;
  outline: none;
  font-weight: 400;
  border-radius: 15px;
  color: ${colors.default};
  &::placeholder {
    color: var(--grey7);
  }
`;

const MessagesContainer = styled.div`
  overflow-y: scroll;
  height: 100%;
  gap: 15px;
  display: flex;
  margin: 0 0 25px 0;
  flex-direction: column;
`;

const MessageContainer = styled.div`
  padding: 10px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: fit-content;
  align-self: ${(p) => (p.currentUser ? "flex-end" : "flex-start")};
  background: ${(p) =>
    p.currentUser ? "var(--primary-admin)" : "var(--grey1)"};
  color: ${(p) => (p.currentUser ? "white" : colors.default)};
  border: 1px solid
    ${(p) => (p.currentUser ? "var(--primary-admin)" : "var(--grey21)")};
`;

const ChatFile = styled.div`
  padding: 10px;
  border-radius: 15px;
  gap: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
  align-self: ${(p) => (p.currentUser ? "flex-end" : "flex-start")};
  background: white;
  color: ${colors.default};
  border: 1px solid var(--grey21);
  cursor: pointer;
`;

const OutlinePanel = styled.div`
  padding: 20px;
  border: 1px solid var(--grey21);
  background: white;
  border-radius: 15px;
`;

const QuoteContainer = styled.div`
  padding: 15px;
  border: 1px solid var(--grey21);
  background: white;
  border-radius: 15px;
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

const LeftSection = styled.div`
  min-width: 500px;
  max-width: 500px;
  gap: 30px;
  display: flex;
  flex-direction: column;
`;

const PreviewPanel = styled.div`
  border: 1px solid var(--grey21);
  background: white;
  border-radius: 15px;
  overflow: hidden;
  width: 100%;
  padding: 20px;
`;
