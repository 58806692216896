import {
  customRecordClickAction,
  detailViewSettings,
  fetchIfHidden,
  label,
  labelColor,
  listFilterResources,
  recordClickAction,
  spreadsheetSelect,
} from "./shared";

import { get } from "lodash";

export const Chart = {
  componentId: "Chart",
  resources: [
    label,
    labelColor,
    spreadsheetSelect,
    ...listFilterResources,
    { ...recordClickAction, displayCondition: (field) => !field.groupByColumn },
    {
      ...customRecordClickAction,
      displayCondition: (field) =>
        !field.groupByColumn && field.recordClickAction === "action",
    },
    {
      ...detailViewSettings,
      displayCondition: (field) =>
        !field.groupByColumn &&
        ["custom", "default"].includes(field.recordClickAction),
    },
    {
      id: "chartType",
      label: "Chart Style",
      section: "chartType",
      componentId: "Select",
      defaultValue: "BarChart",
      wizard: true,
      hideEmptyItem: true,
      options: [
        // NEW
        { label: "Bar", value: "BarChart" },
        { label: "Pie", value: "PieChart" },
        // OLD
        { label: "Line", value: "Line" },
        // { label: "Area", value: "Area" },
        // { label: "Bar (Old)", value: "Bar" },
        // { label: "Doughnut (Old)", value: "Doughnut" },
        // { label: "Pie (Old)", value: "Pie" },
        // { label: "PolarArea (Old)", value: "PolarArea" },
        // APEX
        // { label: "Bar (Beta)", value: "BarApex" },
        // { label: "Line (Beta)", value: "LineApex" },
        // { label: "Column (Beta)", value: "ColumnApex" },
        // { label: "Area (Beta)", value: "AreaApex" },
      ],
      requiresSheet: true,
    },
    {
      id: "variant",
      label: "Variant",
      section: "chartType",
      componentId: "Select",
      hideEmptyItem: true,
      wizard: true,
      options: [
        {
          label: "Horizontal",
          value: "Horizontal",
        },
        {
          label: "Vertical",
          value: "Vertical",
        },
      ],
      requiresSheet: true,
      displayCondition: (field) => field.chartType === "BarChart",
    },
    {
      id: "variant",
      label: "Variant",
      section: "setup",
      componentId: "Select",
      wizard: true,
      options: [
        {
          label: "Filled",
          value: "Filled",
        },
        {
          label: "Doughnut",
          value: "Doughnut",
        },
      ],
      requiresSheet: true,
      displayCondition: (field) => field.chartType === "PieChart",
    },
    {
      id: "labelKey",
      label: "Label Field",
      section: "setup",
      componentId: "SpreadsheetColumnSelect",
      requiresSheet: true,
      hint: "The spreadsheet field to use for the chart segment label",
    },
    {
      id: "valuePrefix",
      key: "valuePrefix",
      label: "Value Prefix",
      componentId: "DynamicString",
      required: true,
      defaultValue: "",
      orientation: "horizontal",
      width: "150px",
      section: "display",
      hint: "Text to display before the value",
      advanced: true,
    },
    {
      id: "valueSuffix",
      key: "valueSuffix",
      label: "Value Suffix",
      componentId: "DynamicString",
      required: true,
      defaultValue: "",
      orientation: "horizontal",
      width: "150px",
      section: "display",
      hint: "Text to display before the value",
      advanced: true,
    },
    {
      id: "valueKey",
      label: "Value Field",
      section: "setup",
      componentId: "SpreadsheetColumnSelect",
      requiresSheet: true,
      hint: "Select the spreadsheet column that contains the numeric value to use in your chart segments",
    },
    {
      id: "colorScheme",
      section: "colorTheme",
      label: "Color Theme",
      required: true,
      isStyle: true,
      componentId: "Select",
      defaultValue: 5,
      options: [
        { label: "Default", value: 5 },
        { label: "Contrast", value: 1 },
        { label: "Blues", value: 2 },
        { label: "Greens", value: 3 },
        { label: "Flower", value: 4 },
        { label: "Custom", value: 6 },
      ],
      requiresSheet: true,
      displayCondition: (field) =>
        get(field, "customColorScheme", []).length === 0
    },
    {
      id: "customColorScheme",
      label: "Custom Color Theme",
      section: "colorTheme",
      required: true,
      isStyle: true,
      componentId: "ColorThemeEditor",
      requiresSheet: true,
      displayCondition: (field) => get(field, "chartType") !== "Line" &&
        get(field, "colorScheme") === 6,
    },
    {
      id: "fillColor",
      label: "Custom Color",
      section: "colorTheme",
      componentId: "ColorPicker",
      orientation: "horizontal",
      defaultValue: "",
      isStyle: true,
      displayCondition: (field) => get(field, "chartType") === "Line" &&
        get(field, "colorScheme") === 6,
    },
    {
      id: "enableFill",
      label: "Enable Fill",
      section: "colorTheme",
      componentId: "Switch",
      hint: "Create a shaded region between the line and the x-axis",
      requiresSheet: true,
      defaultValue: true,
      isStyle: true,
      orientation: "horizontal",
      displayCondition: (field) => get(field, "chartType") === "Line",
    },
    {
      id: "barGap",
      label: "Bar Gap",
      componentId: "Input",
      defaultValue: 15,
      requiresSheet: true,
      section: "options",
      orientation: "horizontal",
      width: "114px",
      isStyle: true,
      displayCondition: (field) => get(field, "chartType") === "BarChart",
    },
    {
      id: "barBorderRadius",
      label: "Bar Border Radius",
      componentId: "Input",
      defaultValue: 12,
      requiresSheet: true,
      section: "options",
      orientation: "horizontal",
      width: "114px",
      isStyle: true,
      displayCondition: (field) => get(field, "chartType") === "BarChart",
    },
    {
      id: "flexContainerWidth",
      label: "Flex Container Width",
      componentId: "Switch",
      defaultValue: true,
      requiresSheet: true,
      section: "options",
      orientation: "horizontal",
      isStyle: true,
      displayCondition: (field) =>
        get(field, "chartType") === "BarChart" &&
        get(field, "variant", "Horizontal") === "Vertical",
    },
    {
      id: "flexBarThickness",
      label: "Flex Bar Width",
      componentId: "Switch",
      defaultValue: true,
      requiresSheet: true,
      section: "options",
      orientation: "horizontal",
      isStyle: true,
      displayCondition: (field) =>
        get(field, "chartType") === "BarChart" &&
        get(field, "variant", "Horizontal") === "Vertical",
    },
    {
      id: "barThickness",
      label: "Bar Thickness",
      componentId: "Input",
      defaultValue: 10,
      requiresSheet: true,
      section: "options",
      orientation: "horizontal",
      width: "113px",
      isStyle: true,
      displayCondition: (field) => get(field, "chartType") === "BarChart",
    },
    {
      label: "Show Legend",
      id: "showLegend",
      componentId: "Switch",
      defaultValue: true,
      requiresSheet: true,
      section: "options",
      orientation: "horizontal",
      isStyle: true,
      displayCondition: (field) => get(field, "chartType") === "PieChart",
    },
    {
      label: "Show Labels",
      id: "showLabels",
      componentId: "Switch",
      defaultValue: true,
      requiresSheet: true,
      section: "options",
      isStyle: true,
      orientation: "horizontal",
      displayCondition: (field) => ["BarChart"].includes(field.chartType),
    },
    {
      id: "groupByColumn",
      label: "Group By",
      section: "setup",
      hint: "Optional - Group results by one of your spreadsheet fields",
      componentId: "SpreadsheetColumnSelect",
      required: false,
      requiresSheet: true,
    },
    {
      id: "position",
      label: "Position",
      section: "options",
      hint: "The position of the legend relative to the chart",
      componentId: "Select",
      orientation: "horizontal",
      width: "113px",
      isStyle: true,
      required: false,
      hideEmptyItem: true,
      options: [
        { label: "Above", value: "above" },
        { label: "Below", value: "below" },
        { label: "Right", value: "right" },
        { label: "Left", value: "left" },
      ],
      defaultValue: "left",
      displayCondition: (field) =>
        get(field, "chartType") === "PieChart" &&
        get(field, "orientation", "horizontal") === "horizontal" &&
        get(field, "showLegend"),
    },
    {
      id: "characterLimit",
      label: "Label Character Limit",
      section: "options",
      hint: "Specify the maximum number of characters or digits to display. Leave blank to show the full value.",
      componentId: "Input",
      orientation: "horizontal",
      width: "150px",
      required: false,
      isStyle: true,
      defaultValue: "",
      displayCondition: (field) => get(field, "chartType") !== "Line" &&
        get(field, "showLegend"),
    },
    {
      id: "legendWidth",
      label: "Label Container Width",
      section: "options",
      componentId: "Input",
      orientation: "horizontal",
      width: "150px",
      hint: "Define the width of the labels for this block",
      required: false,
      isStyle: true,
      defaultValue: "",
      displayCondition: (field) => get(field, "chartType") !== "Line" &&
        ["right", "left"].includes(get(field, "position")) &&
        get(field, "showLegend"),
    },
    {
      id: "groupByMetric",
      label: "Grouping Metric",
      section: "setup",
      hint: "The metric to display for your grouped results.",
      componentId: "Select",
      required: false,
      options: [
        { label: "Sum", value: "sum" },
        { label: "Average", value: "average" },
        { label: "Count", value: "count" },
        { label: "Percent", value: "percent" },
      ],
      defaultValue: "count",
      requiresSheet: true,
      displayCondition: (field) => field.groupByColumn,
    },
    {
      id: "decimalPlaces",
      label: "Decimal Places",
      componentId: "Input",
      defaultValue: 0,
      section: "setup",
      advanced: true,
      hint: "The number of decimal places to display in chart values",
    },
    {
      id: "scaling",
      label: "Scaling",
      section: "scaling",
      requiresSheet: true,
      componentId: "Select",
      hideEmptyItem: true,
      options: [
        { label: "Dynamic", value: "dynamic" },
        { label: "Static", value: "static" },
      ],
      advanced: true,
      defaultValue: "dynamic",
    },
    {
      id: "bufferMargin",
      label: "Buffer Margin",
      componentId: "Input",
      defaultValue: "10",
      section: "scaling",
      advanced: true,
      displayCondition: (field) => get(field, "scaling") === "dynamic",
    },
    {
      id: "xMin",
      label: "Minimum Value",
      componentId: "Input",
      defaultValue: "",
      section: "X-Axis Range",
      advanced: true,
      displayCondition: (field) => get(field, "scaling") === "static",
    },
    {
      id: "xMax",
      label: "Maximum Value",
      componentId: "Input",
      defaultValue: "",
      section: "X-Axis Range",
      advanced: true,
      displayCondition: (field) => get(field, "scaling") === "static",
    },
    {
      id: "yMin",
      label: "Minimum Value",
      componentId: "Input",
      defaultValue: "",
      section: "Y-Axis Range",
      advanced: true,
      displayCondition: (field) => get(field, "scaling") === "static",
    },
    {
      id: "yMax",
      label: "Maximum Value",
      componentId: "Input",
      defaultValue: "",
      section: "Y-Axis Range",
      advanced: true,
      displayCondition: (field) => get(field, "scaling") === "static",
    },
    {
      id: "sorting",
      label: "Sorting",
      section: "sorting",
      hint: "Sort the results in the chart",
      requiresSheet: true,
      componentId: "Select",
      hideEmptyItem: true,
      options: [
        { label: "None", value: null },
        { label: "Ascending", value: "asc" },
        { label: "Descending", value: "desc" },
      ],
      advanced: true,
    },
    {
      id: "sortBy",
      label: "Sort By",
      componentId: "Select",
      section: "sorting",
      requiresSheet: true,
      advanced: true,
      defaultValue: "value",
      options: [
        { label: "Value", value: "value" },
        { label: "Grouping Field", value: "key" },
      ],
      displayCondition: (field) => field.groupByColumn,
    },
    fetchIfHidden,
  ],
};
