const shipmentTracking = {
  id: 18,
  hue: 30,
  name: "Shipment Tracking",
  key: "shipment-tracking",
  description: "Monitor and manage shipments in real-time",
  long_description:
    "Track and manage shipments efficiently in real-time. This template provides detailed insight into each shipment's status, delivery schedules, and helps ensure on-time deliveries.",
  features: [
    {
      name: "Real-time Tracking",
      description: "Track shipment statuses in real-time.",
    },
    {
      name: "Delivery Schedules",
      description: "View delivery schedules and upcoming shipments.",
    },
    {
      name: "Status Alerts",
      description: "Get alerts when shipments are delayed or updated.",
    },
  ],
  categories: ["Logistics", "Supply Chain", "Management"],
  data_sources: [
    {
      key: "shipments",
      data: [
        {
          shipment_id: "SHP001",
          destination: "New York",
          status: "In Transit",
        },
        {
          shipment_id: "SHP002",
          destination: "Los Angeles",
          status: "Delivered",
        },
        {
          shipment_id: "SHP003",
          destination: "Chicago",
          status: "Pending",
        },
        {
          shipment_id: "SHP004",
          destination: "Atlanta",
          status: "In Transit",
        },
      ],
    },
  ],
  pages: [
    {
      name: "Shipments",
      type: "table",
      data_source: "shipments",
    },
    {
      name: "Delivery Schedule",
      type: "calendar",
      data_source: "shipments",
    },
  ],
};

const employeeOnboarding = {
  id: 19,
  hue: 60,
  name: "Employee Onboarding",
  key: "employee-onboarding",
  description: "Track new employee onboarding processes",
  long_description:
    "Easily manage and track the onboarding process for new employees. This tool streamlines onboarding tasks, tracks employee progress, and ensures a smooth transition into the company.",
  features: [
    {
      name: "Task Management",
      description: "Manage and track onboarding tasks for each new employee.",
    },
    {
      name: "Progress Tracking",
      description:
        "Monitor the progress of each employee through the onboarding pipeline.",
    },
    {
      name: "Custom Steps",
      description: "Define custom onboarding steps for different departments.",
    },
  ],
  categories: ["Human Resources", "Onboarding", "Management"],
  data_sources: [
    {
      key: "onboarding_steps",
      data: [
        {
          employee_name: "Jessica Smith",
          status: "Pending",
          step: "HR Orientation",
        },
        {
          employee_name: "David Brown",
          status: "Completed",
          step: "IT Setup",
        },
        {
          employee_name: "Paul Davis",
          status: "In Progress",
          step: "Training",
        },
      ],
    },
  ],
  pages: [
    {
      name: "Onboarding Steps",
      type: "table",
      data_source: "onboarding_steps",
    },
    {
      name: "Progress Tracker",
      type: "kanban",
      data_source: "onboarding_steps",
      block_data: {
        label_field: "employee_name",
      },
    },
  ],
};

const customerFeedback = {
  id: 20,
  hue: 90,
  name: "Customer Feedback",
  key: "customer-feedback",
  description: "Gather and analyze customer feedback",
  long_description:
    "Gather, manage, and analyze customer feedback to improve products and services. This tool provides a structured way to collect and review feedback to drive customer satisfaction.",
  features: [
    {
      name: "Feedback Collection",
      description: "Easily gather feedback from customers.",
    },
    {
      name: "Analytics",
      description: "Analyze feedback to understand customer sentiment.",
    },
    {
      name: "Actionable Insights",
      description: "Use feedback data to drive product improvements.",
    },
  ],
  categories: ["Customer Service", "Feedback", "Analysis"],
  data_sources: [
    {
      key: "feedback",
      data: [
        {
          customer_name: "John Doe",
          rating: 4,
          comments: "Good service overall",
        },
        {
          customer_name: "Anna White",
          rating: 5,
          comments: "Excellent product quality",
        },
        {
          customer_name: "Mark Taylor",
          rating: 3,
          comments: "Average experience",
        },
      ],
    },
  ],
  pages: [
    {
      name: "Feedback",
      type: "table",
      data_source: "feedback",
    },
    {
      name: "Feedback Analytics",
      type: "analytics",
      block_data: {
        stat_label: "Average Rating",
        stat_value: "4.2",
        chart_label: "Ratings",
      },
    },
  ],
};

const equipmentMaintenance = {
  id: 21,
  hue: 120,
  name: "Equipment Maintenance",
  key: "equipment-maintenance",
  description: "Manage and track maintenance schedules",
  long_description:
    "Keep track of equipment maintenance schedules and tasks to ensure all equipment is functional and maintained on time. This template helps to streamline scheduled maintenance tasks and track their progress.",
  features: [
    {
      name: "Maintenance Scheduling",
      description: "Schedule and track maintenance tasks for equipment.",
    },
    {
      name: "Status Tracking",
      description: "Monitor the status of ongoing and completed maintenance.",
    },
    {
      name: "Alerts",
      description: "Get alerts for upcoming or overdue maintenance tasks.",
    },
  ],
  categories: ["Maintenance", "Operations", "Field Service"],
  data_sources: [
    {
      key: "maintenance_tasks",
      data: [
        {
          equipment: "Air Conditioning Unit",
          status: "Scheduled",
          date: "2024-09-10",
        },
        {
          equipment: "Generator",
          status: "Completed",
          date: "2024-08-20",
        },
        {
          equipment: "Elevator",
          status: "In Progress",
          date: "2024-09-05",
        },
      ],
    },
  ],
  pages: [
    {
      name: "Maintenance Tasks",
      type: "table",
      data_source: "maintenance_tasks",
    },
    {
      name: "Task Progress",
      type: "kanban",
      data_source: "maintenance_tasks",
      block_data: {
        label_field: "equipment",
      },
    },
  ],
};

const salesPipeline = {
  id: 22,
  hue: 150,
  name: "Sales Pipeline",
  key: "sales-pipeline",
  description: "Monitor and manage sales opportunities",
  long_description:
    "Track potential sales opportunities and manage the sales process with a visual pipeline. This tool helps you stay on top of your sales funnel and ensures that no opportunity is lost.",
  features: [
    {
      name: "Pipeline Management",
      description: "Manage and track sales opportunities visually.",
    },
    {
      name: "Kanban View",
      description: "View the entire sales pipeline in a kanban board.",
    },
    {
      name: "Stage Customization",
      description: "Customize the stages of your sales pipeline.",
    },
  ],
  categories: ["Sales", "CRM", "Management"],
  data_sources: [
    {
      key: "opportunities",
      data: [
        {
          opportunity_name: "New Website Project",
          client: "TechCorp",
          value: "$10,000",
          status: "Negotiation",
        },
        {
          opportunity_name: "Mobile App Development",
          client: "StartupX",
          value: "$25,000",
          status: "Lead Generation",
        },
        {
          opportunity_name: "Software Development",
          client: "EnterpriseY",
          value: "$50,000",
          status: "Proposal",
        },
      ],
    },
  ],
  pages: [
    {
      name: "Opportunities",
      type: "kanban",
      data_source: "opportunities",
      block_data: {
        label_field: "opportunity_name",
      },
    },
    {
      name: "Opportunity Details",
      type: "form",
      data_source: "opportunities",
    },
  ],
};

const inventoryManagement = {
  id: 2,
  hue: 180,
  name: "Inventory Management",
  key: "inventory-management",
  description: "Track inventory levels and manage orders",
  long_description:
    "Manage your product inventory and orders efficiently. This tool provides insights into stock levels, reorder points, and pending orders to ensure smooth business operations.",
  features: [
    {
      name: "Stock Management",
      description: "Track product stock levels and manage reorder points.",
    },
    {
      name: "Order Tracking",
      description: "View and manage customer orders.",
    },
    {
      name: "Inventory Analytics",
      description:
        "Analyze inventory trends and identify best-selling products.",
    },
  ],
  categories: ["Retail", "Inventory", "Logistics"],
  data_sources: [
    {
      key: "inventory",
      data: [
        {
          product: "Laptop",
          stock: 50,
          reorder_level: 10,
        },
        {
          product: "Smartphone",
          stock: 100,
          reorder_level: 20,
        },
        {
          product: "Headphones",
          stock: 200,
          reorder_level: 30,
        },
      ],
    },
    {
      key: "orders",
      data: [
        {
          order_id: "1001",
          customer: "Alice Johnson",
          status: "Shipped",
        },
        {
          order_id: "1002",
          customer: "Bob Wilson",
          status: "Processing",
        },
        {
          order_id: "1003",
          customer: "Charlie Brown",
          status: "Delivered",
        },
      ],
    },
  ],
  pages: [
    {
      name: "Inventory",
      type: "table",
      data_source: "inventory",
    },
    {
      name: "Orders",
      type: "table",
      data_source: "orders",
    },
    {
      name: "Inventory Analytics",
      type: "analytics",
      block_data: {
        stat_label: "Total Stock",
        stat_value: "350",
        chart_label: "Stock by Category",
      },
    },
  ],
};

const healthcareScheduling = {
  id: 3,
  hue: 210,
  name: "Healthcare Scheduling",
  key: "healthcare-scheduling",
  description: "Manage patient appointments and track medical records",
  long_description:
    "Simplify patient appointment scheduling and medical record tracking with this tool. Ensure timely patient care and organized records for better healthcare outcomes.",
  features: [
    {
      name: "Appointment Scheduling",
      description: "Schedule and manage patient appointments efficiently.",
    },
    {
      name: "Medical Records",
      description: "View and update patient medical history and records.",
    },
    {
      name: "Analytics",
      description: "Analyze appointment trends and patient data.",
    },
  ],
  categories: ["Healthcare", "Scheduling", "Patient Care"],
  data_sources: [
    {
      key: "appointments",
      data: [
        {
          patient_name: "John Doe",
          date: "2024-09-15",
          time: "10:00 AM",
          doctor: "Dr. Smith",
        },
        {
          patient_name: "Jane Smith",
          date: "2024-09-16",
          time: "11:00 AM",
          doctor: "Dr. Adams",
        },
      ],
    },
    {
      key: "medical_records",
      data: [
        {
          name: "John Doe",
          medical_history: "Hypertension",
          last_visit: "2024-01-15",
        },
        {
          name: "Jane Smith",
          medical_history: "Asthma",
          last_visit: "2024-03-10",
        },
      ],
    },
  ],
  pages: [
    {
      name: "Appointments",
      type: "calendar",
      data_source: "appointments",
    },
    {
      name: "Medical Records",
      type: "table",
      data_source: "medical_records",
    },
    {
      name: "Healthcare Analytics",
      type: "analytics",
      block_data: {
        stat_label: "Total Appointments",
        stat_value: "120",
        chart_label: "Appointments per Day",
      },
    },
  ],
};
const employeePerformance = {
  id: 4,
  hue: 240,
  name: "Employee Performance",
  key: "employee-performance",
  description: "Track employee performance reviews and feedback",
  long_description:
    "Track and manage employee performance reviews to ensure high productivity and employee development. This tool helps evaluate employee performance and provides insights for continuous improvement.",
  features: [
    {
      name: "Performance Reviews",
      description: "Track and manage employee performance reviews.",
    },
    {
      name: "Analytics",
      description: "Analyze employee performance trends over time.",
    },
    {
      name: "Feedback Management",
      description:
        "Collect and manage feedback for employee development and growth.",
    },
  ],
  categories: ["Human Resources", "Performance", "Management"],
  data_sources: [
    {
      key: "reviews",
      data: [
        {
          name: "Emily Clark",
          role: "Software Engineer",
          performance: "Excellent",
          status: "Completed",
        },
        {
          name: "Michael Lee",
          role: "Project Manager",
          performance: "Good",
          status: "In Review",
        },
        {
          name: "Samantha Green",
          role: "HR Specialist",
          performance: "Satisfactory",
          status: "Pending Review",
        },
      ],
    },
  ],
  pages: [
    {
      name: "Reviews",
      type: "table",
      data_source: "reviews",
    },
    {
      name: "Performance Analytics",
      type: "analytics",
      block_data: {
        stat_label: "Top Performers",
        stat_value: "3",
        chart_label: "Performance Breakdown",
      },
    },
    {
      name: "Performance Progress",
      type: "kanban",
      data_source: "reviews",
      block_data: {
        label_field: "name",
      },
    },
  ],
};

const propertyManagement = {
  id: 5,
  name: "Property Management",
  key: "property-management",
  description: "Manage property listings and tenant information",
  long_description:
    "Streamline property management by tracking property listings and tenant details in one place. This tool helps landlords and property managers maintain organization and maximize occupancy.",
  features: [
    {
      name: "Property Listings",
      description: "Manage and update property listings.",
    },
    {
      name: "Tenant Management",
      description: "Track tenant information, leases, and rent status.",
    },
    {
      name: "Property Analytics",
      description: "Analyze property occupancy and performance trends.",
    },
  ],
  categories: ["Real Estate", "Management", "Leasing"],
  data_sources: [
    {
      key: "properties",
      data: [
        {
          property: "101 Maple Street",
          status: "Available",
          rent: "$1200/month",
        },
        {
          property: "202 Oak Avenue",
          status: "Occupied",
          rent: "$1500/month",
        },
        {
          property: "303 Pine Road",
          status: "Under Maintenance",
          rent: "$1400/month",
        },
      ],
    },
    {
      key: "tenants",
      data: {
        name: "Alice Walker",
        lease_start: "2024-06-01",
        lease_end: "2025-05-31",
      },
    },
  ],
  pages: [
    {
      name: "Properties",
      type: "table",
      data_source: "properties",
    },
    {
      name: "Tenants",
      type: "form",
      data_source: "tenants",
    },
    {
      name: "Property Analytics",
      type: "analytics",
      block_data: {
        stat_label: "Total Properties",
        stat_value: "15",
        chart_label: "Properties per Status",
      },
    },
  ],
};

const eventManagement = {
  id: 6,
  name: "Event Management",
  key: "event-management",
  description: "Organize and track event details and attendees",
  long_description:
    "Efficiently manage events by tracking event details and attendee information. This tool simplifies event planning and provides analytics to gauge event performance.",
  features: [
    {
      name: "Event Tracking",
      description:
        "Track event details, such as dates, locations, and attendees.",
    },
    {
      name: "Attendee Management",
      description: "Keep track of attendees and their registration status.",
    },
    {
      name: "Event Analytics",
      description: "Analyze event performance and attendee feedback.",
    },
  ],
  categories: ["Event Planning", "Management", "Analytics"],
  data_sources: [
    {
      key: "events",
      data: [
        "Annual Company Meeting",
        "Product Launch",
        "Team Building Workshop",
      ],
    },
    {
      key: "attendees",
      data: [
        {
          attendee_name: "John Doe",
          event: "Annual Company Meeting",
          status: "Registered",
        },
        {
          attendee_name: "Sarah Johnson",
          event: "Product Launch",
          status: "Confirmed",
        },
      ],
    },
  ],
  pages: [
    {
      name: "Events",
      type: "kanban",
      data_source: "events",
    },
    {
      name: "Event Analytics",
      type: "analytics",
      block_data: {
        stat_label: "Total Events",
        stat_value: "3",
        chart_label: "Events per Type",
      },
    },
    {
      name: "Attendee List",
      type: "table",
      data_source: "attendees",
    },
  ],
};

const marketingCampaigns = {
  id: 8,
  name: "Marketing Campaigns",
  key: "marketing-campaigns",
  description: "Track and manage marketing campaign performance",
  long_description:
    "Manage and monitor the performance of all your marketing campaigns in one place. This tool helps you analyze leads, budget allocations, and overall campaign effectiveness, ensuring that your marketing efforts drive results.",
  features: [
    {
      name: "Campaign Management",
      description: "Track and manage all active and completed campaigns.",
    },
    {
      name: "Performance Analytics",
      description:
        "Analyze the performance of each campaign to optimize results.",
    },
    {
      name: "Budget Allocation",
      description: "Track campaign budgets and ensure efficient spend.",
    },
  ],
  categories: ["Marketing", "Campaign Management", "Analytics"],
  pages: [
    {
      name: "Analytics",
      type: "analytics",
      block_data: {
        stat_label: "Total Leads",
        stat_value: "350",
        chart_label: "Leads per Campaign",
      },
    },
    {
      name: "Campaigns",
      type: "table",
      data: [
        {
          campaign_name: "Spring Sale",
          status: "Active",
          budget: "$10,000",
        },
        {
          campaign_name: "Holiday Promotion",
          status: "Planned",
          budget: "$15,000",
        },
        {
          campaign_name: "Back to School",
          status: "Completed",
          budget: "$8,000",
        },
      ],
    },
    {
      name: "Campaign Progress",
      type: "kanban",
      data: ["Planned", "In Progress", "Completed"],
    },
  ],
};
const restaurantPortal = {
  id: 9,
  name: "Restaurant Portal",
  key: "restaurant-portal",
  description: "Manage restaurant orders and track delivery status",
  long_description:
    "Easily manage restaurant orders and track delivery status with this tool. The Restaurant Portal helps restaurant owners and managers streamline order processing, track total orders, and monitor revenue.",
  features: [
    {
      name: "Order Management",
      description: "View and manage customer orders efficiently.",
    },
    {
      name: "Delivery Tracking",
      description:
        "Track the status of deliveries and ensure timely fulfillment.",
    },
    {
      name: "Order Analytics",
      description:
        "Analyze order metrics to understand sales trends and performance.",
    },
  ],
  categories: ["Restaurant", "Food & Beverage", "Management"],
  pages: [
    {
      name: "Orders",
      type: "table",
      data: [
        {
          order_id: "ORD001",
          customer: "Michael Brown",
          total_amount: "$25",
        },
        {
          order_id: "ORD002",
          customer: "Sarah Connor",
          total_amount: "$45",
        },
        {
          order_id: "ORD003",
          customer: "John Wick",
          total_amount: "$60",
        },
      ],
    },
    {
      name: "Order Analytics",
      type: "analytics",
      flipped: true,
      block_data: {
        stat_label: "Total Orders",
        stat_value: "120",
        chart_label: "Orders per Day",
      },
    },
    {
      name: "Order Progress",
      type: "kanban",
      data: ["New", "In Preparation", "Delivered"],
    },
  ],
};

const customerSupport = {
  id: 10,
  name: "Customer Support",
  key: "customer-support",
  description: "Manage customer support tickets and track agent performance",
  pages: [
    {
      name: "Agent Performance",
      description: "Track performance of support agents",
      type: "analytics",
      block_data: {
        stat_label: "Tickets Resolved",
        stat_value: "85",
        chart_label: "Tickets per Agent",
      },
    },
    {
      name: "Tickets",
      description: "View and manage support tickets",
      type: "table",
      data: [
        {
          ticket_id: "TCK001",
          customer: "Alice Thompson",
          status: "Open",
        },
        {
          ticket_id: "TCK002",
          customer: "Bob Harrison",
          status: "Closed",
        },
        {
          ticket_id: "TCK003",
          customer: "Clara Evans",
          status: "Pending",
        },
      ],
    },
  ],
};

const retailSales = {
  id: 11,
  name: "Retail Sales",
  key: "retail-sales",
  description: "Manage retail sales and view store performance metrics",
  pages: [
    {
      name: "Sales",
      description: "View and manage retail sales",
      type: "table",
      data: [
        {
          sale_id: "S001",
          product: "Shoes",
          total: "$80",
        },
        {
          sale_id: "S002",
          product: "T-Shirt",
          total: "$25",
        },
        {
          sale_id: "S003",
          product: "Jeans",
          total: "$60",
        },
      ],
    },
    {
      name: "Sales Analytics",
      description: "Track sales performance metrics",
      type: "analytics",
      block_data: {
        stat_label: "Total Revenue",
        stat_value: "$3,200",
        chart_label: "Sales per Product",
      },
    },
  ],
};

const facilityManagement = {
  id: 12,
  name: "Facility Management",
  key: "facility-management",
  description: "Manage facilities and track maintenance schedules",
  pages: [
    {
      name: "Maintenance Analytics",
      description: "Track maintenance schedules and performance",
      type: "analytics",
      block_data: {
        stat_label: "Total Maintenance Tasks",
        stat_value: "45",
        chart_label: "Tasks per Facility",
      },
    },
    {
      name: "Facilities",
      description: "View and manage facilities",
      type: "table",
      data: [
        {
          facility_name: "Building A",
          status: "Operational",
          maintenance_due: "2024-09-30",
        },
        {
          facility_name: "Building B",
          status: "Under Maintenance",
          maintenance_due: "2024-10-15",
        },
        {
          facility_name: "Building C",
          status: "Operational",
          maintenance_due: "2024-12-01",
        },
      ],
    },
  ],
};

const blogPostGenerator = {
  id: 23,
  name: "Blog Post Generator",
  key: "blog-post-generator",
  screenshot:
    "https://res.cloudinary.com/frontly/image/upload/v1716862962/Screenshot_2024-05-27_at_7.22.23_PM_zjfkqd.png",
  isRealApp: true,
  description: "Generate and manage blog posts with AI-powered assistance",
  pages: [
    {
      name: "Blog Posts",
      description: "View and manage generated blog posts",
      type: "table",
      data: [
        {
          title: "How to Boost Your SEO",
          status: "Published",
          word_count: "1200",
        },
        {
          title: "Top 10 Marketing Strategies",
          status: "Draft",
          word_count: "800",
        },
        {
          title: "Understanding AI in 2024",
          status: "Scheduled",
          word_count: "1500",
        },
      ],
    },
    {
      name: "Create New Post",
      description: "Fill in details for a new blog post",
      type: "form",
      data: {
        title: "How to Boost Your SEO",
        keywords: "SEO, Marketing, Business",
        word_count: "1200",
      },
    },
  ],
};
const fleetManagement = {
  id: 13,
  name: "Fleet Management",
  key: "fleet-management",
  description: "Track and manage vehicle fleets and maintenance schedules",
  long_description:
    "Ensure optimal vehicle performance with the Fleet Management tool. Track vehicle statuses, monitor maintenance schedules, and ensure all vehicles are operational.",
  features: [
    {
      name: "Vehicle Tracking",
      description: "Track and manage vehicle information in the fleet.",
    },
    {
      name: "Maintenance Scheduling",
      description: "Schedule and monitor vehicle maintenance tasks.",
    },
    {
      name: "Fleet Analytics",
      description: "Analyze fleet performance and maintenance statistics.",
    },
  ],
  categories: ["Logistics", "Fleet Management", "Maintenance"],
  pages: [
    {
      name: "Vehicles",
      type: "table",
      data: [
        {
          vehicle_id: "V001",
          model: "Ford Transit",
          status: "Operational",
        },
        {
          vehicle_id: "V002",
          model: "Chevy Express",
          status: "In Repair",
        },
        {
          vehicle_id: "V003",
          model: "Ram ProMaster",
          status: "Operational",
        },
      ],
    },
    {
      name: "Fleet Analytics",
      type: "analytics",
      block_data: {
        stat_label: "Total Vehicles",
        stat_value: "30",
        chart_label: "Operational vs Maintenance",
      },
    },
  ],
};

const loanProcessing = {
  id: 14,
  name: "Loan Processing",
  key: "loan-processing",
  description: "Manage and track loan applications",
  pages: [
    {
      name: "Loan Applications",
      description: "View and manage customer loan applications",
      type: "table",
      data: [
        {
          application_id: "LA001",
          customer_name: "David Lee",
          status: "Approved",
        },
        {
          application_id: "LA002",
          customer_name: "Anna Kim",
          status: "Pending",
        },
        {
          application_id: "LA003",
          customer_name: "Mike Davis",
          status: "Rejected",
        },
      ],
    },
  ],
};
const gymMembership = {
  id: 15,
  name: "Gym Membership Management",
  key: "gym-membership",
  description: "Track and manage gym memberships and attendance",
  long_description:
    "Manage gym memberships and attendance tracking effortlessly. This tool helps gym administrators keep track of member statuses, subscriptions, and gym usage.",
  features: [
    {
      name: "Membership Management",
      description: "Track gym memberships, renewals, and cancellations.",
    },
    {
      name: "Attendance Tracking",
      description: "Monitor member attendance and gym usage trends.",
    },
    {
      name: "Analytics",
      description: "Analyze gym attendance and membership growth.",
    },
  ],
  categories: ["Fitness", "Membership", "Tracking"],
  pages: [
    {
      name: "Members",
      type: "table",
      data: [
        {
          member_id: "M001",
          name: "John Doe",
          membership_status: "Active",
        },
        {
          member_id: "M002",
          name: "Jane Smith",
          membership_status: "Inactive",
        },
        {
          member_id: "M003",
          name: "Paul Johnson",
          membership_status: "Active",
        },
      ],
    },
    {
      name: "Membership Analytics",
      type: "analytics",
      block_data: {
        stat_label: "Active Members",
        stat_value: "250",
        chart_label: "Monthly Attendance",
      },
    },
  ],
};

const travelAgency = {
  id: 16,
  name: "Travel Agency Booking",
  key: "travel-agency",
  description: "Manage travel bookings and client information",
  long_description:
    "Easily manage travel bookings and client details with the Travel Agency Booking tool. This tool allows you to track bookings, payments, and customer details for a seamless travel booking experience.",
  features: [
    {
      name: "Booking Management",
      description: "Track and manage client travel bookings.",
    },
    {
      name: "Client Information",
      description: "Maintain customer profiles and trip preferences.",
    },
    {
      name: "Booking Analytics",
      description: "Analyze booking trends and customer travel preferences.",
    },
  ],
  categories: ["Travel", "Booking", "Management"],
  pages: [
    {
      name: "Bookings",
      type: "table",
      data: [
        {
          booking_id: "B001",
          client: "Alice Walker",
          destination: "Paris",
        },
        {
          booking_id: "B002",
          client: "Bob Harris",
          destination: "Tokyo",
        },
        {
          booking_id: "B003",
          client: "Catherine Lee",
          destination: "New York",
        },
      ],
    },
    {
      name: "Booking Analytics",
      type: "analytics",
      block_data: {
        stat_label: "Total Bookings",
        stat_value: "45",
        chart_label: "Bookings per Destination",
      },
    },
  ],
};
const legalCaseManagement = {
  id: 17,
  name: "Legal Case Management",
  key: "legal-case-management",
  description: "Manage legal cases and track case status",
  long_description:
    "Stay organized and manage legal cases efficiently with this tool. It tracks case details, statuses, and deadlines, helping law firms and legal teams keep case progress on track.",
  features: [
    {
      name: "Case Management",
      description: "Track case progress and important details.",
    },
    {
      name: "Document Management",
      description: "Store and manage case-related documents.",
    },
    {
      name: "Case Analytics",
      description: "Analyze case statistics and performance.",
    },
  ],
  categories: ["Legal", "Case Management", "Law"],
  pages: [
    {
      name: "Cases",
      type: "table",
      data: [
        {
          case_id: "C001",
          client_name: "John Smith",
          status: "Open",
        },
        {
          case_id: "C002",
          client_name: "Sandra Lee",
          status: "Closed",
        },
        {
          case_id: "C003",
          client_name: "Michael Brown",
          status: "In Progress",
        },
      ],
    },
    {
      name: "Case Analytics",
      type: "analytics",
      block_data: {
        stat_label: "Total Cases",
        stat_value: "10",
        chart_label: "Cases by Status",
      },
    },
  ],
};

const webPageSummary = {
  id: 24,
  hue: 200,
  name: "Web Page Summary",
  key: "web-page-summary",
  screenshot:
    "https://res.cloudinary.com/frontly/image/upload/v1737586695/web-summary-screen_xggm30.png",
  isRealApp: true,
  description: "Scrape and summarize text from a web page with AI",
  long_description:
    "Automatically scrape text from web pages and generate concise summaries using AI. The Web Page Summary tool helps users quickly understand the content of web pages without needing to read the entire article. This tool is ideal for research, content analysis, or simply saving time by summarizing lengthy articles.",
  features: [
    {
      name: "Web Scraping",
      description: "Scrape web pages to extract text content.",
    },
    {
      name: "AI-Powered Summaries",
      description: "Generate AI-based summaries of scraped content.",
    },
    {
      name: "URL Management",
      description:
        "Easily add and manage web pages for scraping and summarization.",
    },
  ],
  categories: ["Content", "Web Scraping", "Research"],
  data_sources: [
    {
      key: "scraped_pages",
      data: [
        {
          url: "https://apple.com",
          status: "Scraped",
          summary: "This page discusses advanced AI techniques.",
        },
        {
          url: "https://google.com/article-1",
          status: "To Do",
          summary: "This article covers the basics of web scraping.",
        },
        {
          url: "https://meta.com/article-2",
          status: "Pending",
          summary: "N/A",
        },
        {
          url: "https://x.com/article-3",
          status: "Scraped",
          summary: "This page discusses advanced AI techniques.",
        },
      ],
    },
  ],
  pages: [
    {
      name: "Scraped Pages",
      type: "table",
      data_source: "scraped_pages",
    },
    {
      name: "Summarize Website",
      type: "form",
      data_source: "scraped_pages",
      fields: ["url"],
    },
  ],
};

const onlineCourseManagement = {
  id: 25,
  hue: 300,
  name: "Online Course Management",
  key: "online-course-management",
  description: "Manage courses, students, and track learning progress",
  long_description:
    "Manage your online courses, student progress, and course content efficiently. This tool provides instructors and administrators with insights into student performance and course effectiveness.",
  features: [
    {
      name: "Course Management",
      description: "Manage course content and availability.",
    },
    {
      name: "Student Tracking",
      description: "Track student enrollment and progress.",
    },
    {
      name: "Performance Analytics",
      description: "Analyze student performance and course effectiveness.",
    },
  ],
  categories: ["Education", "E-Learning", "Course Management"],
  data_sources: [
    {
      key: "courses",
      data: [
        {
          course_name: "Introduction to Python",
          students_enrolled: 150,
          status: "Active",
        },
        {
          course_name: "Data Science Fundamentals",
          students_enrolled: 200,
          status: "Active",
        },
        {
          course_name: "Web Development Basics",
          students_enrolled: 120,
          status: "Completed",
        },
      ],
    },
  ],
  pages: [
    {
      name: "Courses",
      type: "table",
      data_source: "courses",
    },
    {
      name: "Course Analytics",
      type: "analytics",
      block_data: {
        stat_label: "Total Students",
        stat_value: "470",
        chart_label: "Students per Course",
      },
    },
  ],
};
const realEstateListings = {
  id: 26,
  name: "Real Estate Listings",
  key: "real-estate-listings",
  screenshot:
    "https://res.cloudinary.com/frontly/image/upload/v1715283518/Screenshot_2024-05-08_at_2.19.59_PM_xtnbcc.png",
  isRealApp: true,
  description: "Manage and showcase real estate property listings",
  pages: [
    {
      name: "Listings",
      description: "View and manage property listings",
      type: "table",
      data: [
        {
          property_name: "Sunset Villa",
          location: "Los Angeles, CA",
          price: "$1,200,000",
        },
        {
          property_name: "Downtown Condo",
          location: "New York, NY",
          price: "$850,000",
        },
        {
          property_name: "Country House",
          location: "Austin, TX",
          price: "$650,000",
        },
      ],
    },
  ],
};

const membershipManagement = {
  id: 27,
  name: "Membership Management",
  key: "membership-management",
  description: "Track membership details and subscription statuses",
  pages: [
    {
      name: "Members",
      description: "View and manage member details",
      type: "table",
      data: [
        {
          member_name: "Alice Johnson",
          subscription: "Annual",
          status: "Active",
        },
        {
          member_name: "Bob Harris",
          subscription: "Monthly",
          status: "Expired",
        },
        {
          member_name: "Clara Evans",
          subscription: "Lifetime",
          status: "Active",
        },
      ],
    },
    {
      name: "Add New Member",
      description: "Add details for a new member",
      type: "form",
      data: {
        member_name: "",
        subscription: "",
        status: "",
      },
    },
  ],
};

const jobApplicationTracker = {
  id: 28,
  name: "Job Application Tracker",
  key: "job-application-tracker",
  description: "Track and manage job applications and candidate statuses",
  long_description:
    "Manage and track all job applications in one place with the Job Application Tracker. This tool streamlines the hiring process by centralizing all candidate information, tracking their progress through various stages, and making it easier to manage large volumes of applications. From application submissions to interviews and offers, this tool helps you stay organized and ensure no candidate is left behind.",
  features: [
    {
      name: "Application Management",
      description:
        "Easily manage job applications from submission to interview and hiring.",
    },
    {
      name: "Candidate Tracking",
      description:
        "Keep track of each candidate's progress through the hiring pipeline.",
    },
    {
      name: "Custom Statuses",
      description:
        "Assign custom statuses to candidates such as 'Applied,' 'Interviewing,' or 'Hired.'",
    },
    {
      name: "Search and Filter",
      description:
        "Quickly search and filter applications by role, status, or candidate.",
    },
  ],
  categories: ["Human Resources", "Recruitment", "Management"],
  pages: [
    {
      name: "Apply",
      description: "Apply to a job",
      type: "form",
      data: {
        candidate_name: "",
        position: "",
        status: "",
        date_applied: "",
      },
    },
    {
      name: "Job Applications",
      description: "View and manage job applications",
      type: "table",
      data: [
        {
          candidate_name: "John Doe",
          position: "Software Engineer",
          status: "Interviewing",
          date_applied: "2023-01-15",
        },
        {
          candidate_name: "Jane Smith",
          position: "Product Manager",
          status: "Applied",
          date_applied: "2023-01-10",
        },
        {
          candidate_name: "Paul Davis",
          position: "Data Analyst",
          status: "Hired",
          date_applied: "2022-12-01",
        },
        {
          candidate_name: "Emily Clark",
          position: "UX Designer",
          status: "Interviewing",
          date_applied: "2023-01-05",
        },
        {
          candidate_name: "Michael Johnson",
          position: "DevOps Engineer",
          status: "Applied",
          date_applied: "2023-01-08",
        },
      ],
    },
  ],
};

const mortgageApplications = {
  id: 30,
  name: "Mortgage Applications",
  key: "mortgage-applications",
  description: "Manage and track mortgage applications and approvals",
  long_description:
    "Streamline the mortgage application process with the Mortgage Applications tool. This tool helps you manage and track all mortgage applications, from initial submission to final approval. It centralizes all applicant information, tracks their progress through various stages, and ensures a smooth and efficient process. Stay organized and provide a seamless experience for applicants.",
  features: [
    {
      name: "Application Management",
      description:
        "Easily manage mortgage applications from submission to approval.",
    },
    {
      name: "Applicant Tracking",
      description:
        "Keep track of each applicant's progress through the mortgage process.",
    },
    {
      name: "Custom Statuses",
      description:
        "Assign custom statuses to applications such as 'Submitted,' 'Under Review,' or 'Approved.'",
    },
    {
      name: "Search and Filter",
      description:
        "Quickly search and filter applications by status, applicant, or property.",
    },
  ],
  pages: [
    {
      name: "Apply",
      description: "Submit a new mortgage application",
      type: "form",
      data: {
        applicant_name: "",
        property_address: "",
        loan_amount: "",
        status: "",
        date_submitted: "",
        review_date: "",
        loan_officer: "",
      },
    },
    {
      name: "Mortgage Applications",
      description: "View and manage mortgage applications",
      type: "table",
      data: [
        {
          applicant_name: "John Doe",
          property_address: "123 Main St",
          loan_amount: "$250,000",
          status: "Under Review",
          date_submitted: "2023-02-15",
          review_date: "2023-02-20",
          loan_officer: "Alice Brown",
        },
        {
          applicant_name: "Jane Smith",
          property_address: "456 Oak Ave",
          loan_amount: "$300,000",
          status: "Submitted",
          date_submitted: "2023-02-10",
          review_date: null,
          loan_officer: "Bob Harris",
        },
        {
          applicant_name: "Paul Davis",
          property_address: "789 Pine Rd",
          loan_amount: "$200,000",
          status: "Approved",
          date_submitted: "2023-01-01",
          review_date: "2023-01-10",
          loan_officer: "Clara Evans",
        },
      ],
    },
  ],
};

export const demoApps = [
  webPageSummary,
  {
    id: 2,
    hue: 150,
    name: "Real Estate Listings",
    key: "real-estate-listings",
    screenshot:
      "https://res.cloudinary.com/frontly/image/upload/h_600/f_jpg/v1715283518/Screenshot_2024-05-08_at_2.19.59_PM_xtnbcc.png",
    isRealApp: true,
    description:
      "A beautiful, modern real estate listings app that can be customized for any industry",
    long_description:
      "Manage and showcase real estate property listings with ease. This tool allows real estate agents to keep track of property details and client inquiries.",
    features: [
      {
        name: "Custom Blocks",
        description: "Create custom blocks for property details.",
      },
      {
        name: "Block Versions",
        description: "Manage different versions of property listings.",
      },
      {
        name: "Custom Actions",
        description: "Perform actions based on property status.",
      },
      {
        name: "Local State",
        description: "Manage local state for property interactions.",
      },
    ],
    categories: ["Listings", "Agencies", "Contractors", "Marketing"],
    data_sources: [
      {
        key: "listings",
        data: [
          {
            property_name: "Sunset Villa",
            location: "Los Angeles, CA",
            price: "$1,200,000",
          },
          {
            property_name: "Downtown Condo",
            location: "New York, NY",
            price: "$850,000",
          },
        ],
      },
    ],
    pages: [
      {
        name: "Listings",
        type: "table",
        data_source: "listings",
      },
    ],
  },
  {
    id: 4,
    hue: 40,
    name: "Responsive Analytics Dashboard",
    key: "responsive-analytics-dashboard",
    backgroundColor: "#F8F7F2",
    bottomGradient: true,
    rightGradient: true,
    screenshot:
      "https://res.cloudinary.com/frontly/image/upload/v1716866534/Screenshot_2024-05-27_at_8.20.04_PM_n9n3c2.png",
    isRealApp: true,
    description: "A mobile-responsive analytics dashboard for your data",
    long_description:
      "Visualize and analyze data with a mobile-responsive dashboard. This tool provides various charts and graphs to help you understand your data better.",
    features: [
      {
        name: "Charts",
        description: "Display data in various chart formats.",
      },
      {
        name: "Analytics",
        description: "Perform data analysis and generate insights.",
      },
      {
        name: "Stats",
        description: "Show statistical data and trends.",
      },
    ],
    categories: ["Analytics", "Data Visualization", "Charts"],
    pages: [
      {
        name: "Dashboard",
        type: "analytics",
        block_data: {
          stat_label: "Total Views",
          stat_value: "5000",
          chart_label: "Views per Day",
        },
      },
    ],
  },
  {
    id: 5,
    hue: 259,
    name: "Applicant Tracker",
    key: "applicant-tracker",
    bottomGradient: true,
    rightGradient: true,
    screenshot:
      "https://res.cloudinary.com/frontly/image/upload/v1695277815/demo-apps/Screenshot_2023-09-20_at_11.30.05_PM_g1qfnz.png",
    isRealApp: true,
    description: "Job applicant tracker for HR team with analytics",
    long_description:
      "Manage job applications and track candidate progress through the hiring pipeline. This tool helps HR teams streamline the recruitment process.",
    features: [
      {
        name: "Applicant Tracking",
        description: "Track job applications and candidate statuses.",
      },
      {
        name: "Interview Scheduling",
        description: "Schedule interviews with candidates.",
      },
      {
        name: "Collaborative Hiring",
        description: "Collaborate with team members on hiring decisions.",
      },
    ],
    categories: ["Human Resources", "Recruitment"],
    data_sources: [
      {
        key: "applications",
        data: [
          {
            candidate_name: "John Doe",
            position: "Software Engineer",
            status: "Interviewing",
          },
          {
            candidate_name: "Jane Smith",
            position: "Product Manager",
            status: "Applied",
          },
        ],
      },
    ],
    pages: [
      {
        name: "Applications",
        type: "table",
        data_source: "applications",
      },
      {
        name: "Application Analytics",
        type: "analytics",
        block_data: {
          stat_label: "Total Applications",
          stat_value: "100",
          chart_label: "# Per Position",
        },
      },
    ],
  },
  {
    id: 6,
    hue: 150,
    name: "AI Business Idea Generator",
    key: "business-idea-generator",
    screenshot:
      "https://res.cloudinary.com/frontly/image/upload/v1709689463/Screenshot_2024-03-05_at_5.44.12_PM_b08vne.png",
    isRealApp: true,
    description: "Generate and refine business ideas with AI",
    long_description:
      "Use AI to generate and refine business ideas. This tool helps entrepreneurs brainstorm and develop innovative business concepts.",
    features: [
      {
        name: "OpenAI Request",
        description: "Generate business ideas using AI.",
      },
      {
        name: "Custom Actions",
        description: "Perform actions based on generated ideas.",
      },
    ],
    categories: ["Artificial Intelligence", "Internal Tools"],
    data_sources: [
      {
        key: "ideas",
        data: [
          {
            idea: "AI-powered personal assistant",
            status: "Concept",
            date: "2025-01-01",
          },
          {
            idea: "Sustainable packaging solutions",
            status: "Research",
            date: "2024-01-01",
          },
        ],
      },
    ],
    pages: [
      {
        name: "Ideas",
        type: "table",
        data_source: "ideas",
      },
    ],
  },
  {
    id: 7,
    hue: 221,
    name: "Client Project Portal",
    screenshot:
      "https://res.cloudinary.com/frontly/image/upload/v1695278075/demo-apps/Screenshot_2023-09-20_at_11.34.20_PM_grkzaq.png",
    key: "agency-one",
    isRealApp: true,
    description: "Give clients a secure login to review project updates",
    long_description:
      "Provide clients with a secure portal to review project updates and communicate with the project team. This tool enhances client engagement and satisfaction.",
    features: [
      {
        name: "Secure Login",
        description: "Provide secure access to client portals.",
      },
      {
        name: "Project Tracking",
        description: "Track project progress and updates.",
      },
      {
        name: "Client Communication",
        description: "Facilitate communication between clients and teams.",
      },
    ],
    categories: ["Client Management", "Project Management"],
    data_sources: [
      {
        key: "projects",
        data: [
          {
            project_name: "Website Redesign",
            status: "In Progress",
          },
          {
            project_name: "Mobile App Development",
            status: "Completed",
          },
          {
            project_name: "AI Chatbot Integration",
            status: "Pending",
          },
        ],
      },
    ],
    pages: [
      {
        name: "Projects",
        type: "kanban",
        data_source: "projects",
        block_data: {
          label_field: "project_name",
        },
      },
    ],
  },
  {
    id: 8,
    hue: 165,
    name: "Product Returns",
    key: "returns",
    isRealApp: true,
    screenshot:
      "https://res.cloudinary.com/frontly/image/upload/v1695278155/demo-apps/Screenshot_2023-09-20_at_11.35.46_PM_jskeu0.png",
    description: "A portal for customers to manage product returns",
    long_description:
      "Streamline the product return process with a customer portal. This tool helps businesses manage returns efficiently and improve customer satisfaction.",
    features: [
      {
        name: "Automated Return Process",
        description: "Automate the return process for customers.",
      },
      {
        name: "Customer Account Integration",
        description: "Integrate with customer accounts for seamless returns.",
      },
      {
        name: "Return Status Tracking",
        description: "Track the status of product returns.",
      },
    ],
    categories: ["E-commerce", "Customer Service"],
    data_sources: [
      {
        key: "returns",
        data: [
          {
            return_id: "R001",
            product: "Laptop",
            status: "Pending",
          },
          {
            return_id: "R002",
            product: "Headphones",
            status: "Approved",
          },
          {
            return_id: "R003",
            product: "Smartwatch",
            status: "Rejected",
          },
        ],
      },
    ],
    pages: [
      {
        name: "Returns",
        type: "table",
        data_source: "returns",
      },
    ],
  },
  {
    id: 9,
    hue: 0,
    name: "Quote Calculator",
    key: "nyc-moving",
    screenshot:
      "https://res.cloudinary.com/frontly/image/upload/v1695236460/demo-apps/quote-calculator_yz8t2g.png",
    isRealApp: true,
    description: "Give clients an instant quote from spreadsheet calculations",
    long_description:
      "Provide clients with instant quotes using spreadsheet calculations. This tool helps businesses offer quick and accurate pricing to potential customers.",
    features: [
      {
        name: "Instant Quotes",
        description: "Generate instant quotes for clients.",
      },
      {
        name: "Customizable Calculation Formulas",
        description: "Customize formulas for quote calculations.",
      },
      {
        name: "Integration with CRM",
        description: "Integrate with CRM systems for seamless operations.",
      },
    ],
    categories: ["Sales", "Client Services"],
    data_sources: [
      {
        key: "quotes",
        data: [
          {
            name: "Alice Walker",
            move_in_date: "2024-06-01",
            lease_end: "2025-05-31",
          },
          {
            name: "Jack Robertson",
            move_in_date: "2025-06-01",
            lease_end: "2025-05-31",
          },
        ],
      },
    ],
    pages: [
      {
        name: "Get An Instant Quote",
        type: "form",
        data_source: "quotes",
        fields: ["name", "move_in_date", "lease_end"],
      },
    ],
  },
];

export const templates = [
  // onlineCourseManagement,
  // blogPostGenerator,
  // shipmentTracking,
  // employeeOnboarding,
  // customerFeedback,
  ...demoApps,
  // equipmentMaintenance,
  // salesPipeline,
  // inventoryManagement,
  // healthcareScheduling,
  // employeePerformance,
  // marketingCampaigns,
  // loanProcessing,
  // restaurantPortal,
  // customerSupport,
  // retailSales,
  // facilityManagement,
  // propertyManagement,
  // eventManagement,
  // fleetManagement,
  // gymMembership,
  // travelAgency,
  // legalCaseManagement,
  // realEstateListings,
  // membershipManagement,
  // jobApplicationTracker,
  // mortgageApplications, // Added new template here
];

export const demoSubdomains = demoApps.map((a) => a.key);
