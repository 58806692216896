import { apiRequest, getConfigParams } from "./apiRequests";
import { get, isEmpty } from "lodash";
import { isFrontlyAdmin, publicRoutes } from "./utils";
import { rApp, rClearConfig, rIsFetchingConfig, rSetConfig } from "./recoil";
import { useRecoilValue, useSetRecoilState } from "recoil";

import Cookies from "js-cookie";
import { decryptConfig } from "./encryptionUtils";
import { useLocation } from "react-router-dom";

const useFetchAppConfig = () => {
  const app = useRecoilValue(rApp);
  const setApp = useSetRecoilState(rApp);
  const clearConfig = useSetRecoilState(rClearConfig);
  const setConfig = useSetRecoilState(rSetConfig);
  const setIsFetchingConfig = useSetRecoilState(rIsFetchingConfig);

  const location = useLocation();
  const path = get(location, "pathname");

  const isPublicRoute =
    publicRoutes.includes(path) ||
    (isFrontlyAdmin && path.includes("/expert/")) ||
    (isFrontlyAdmin && path.includes("/templates"));

  const isVerifyEmailRoute = path.includes("/verify-email");

  const fetchAppConfig = ({ appId, callback }) => {
    if (isVerifyEmailRoute) {
      return;
    }

    if ((!isPublicRoute || !isFrontlyAdmin) && (appId || isEmpty(app))) {
      clearConfig();

      setApp({});

      setIsFetchingConfig(true);

      // Override params here so that we can pass in the appId to switch between apps in admin mode
      const customParams = {
        params: getConfigParams(appId),
      };

      const getConfigEndpoint = () => {
        const token = Cookies.get("accessToken");
        if (token) {
          return "config/";
        }
        return "anonymous_config/";
      };

      apiRequest.get(getConfigEndpoint(), customParams).then((response) => {
        const encryptedConfigBase64 = get(response, "data");

        const decryptedConfig = decryptConfig(encryptedConfigBase64);
        // This sets everything
        setConfig(decryptedConfig);

        setIsFetchingConfig(false);

        if (isFrontlyAdmin) {
          // Keep track of the app they are most recently editing
          localStorage.setItem("appId", get(decryptedConfig, ["app", "id"]));
          callback && callback();
        }
      });
    }
  };

  return fetchAppConfig;
};

export default useFetchAppConfig;
