import {
  rApp,
  rConfirmationModalData,
  rOrganization,
  rUser,
} from "app/utils/recoil";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import AdminForm from "../components/AdminForm";
import { Column } from "app/components";
import SettingsCard from "./components/SettingsCard";
import SettingsSection from "./SettingsSection";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { successNotification } from "app/utils/Notification";
import { useState } from "react";

const Advanced = () => {
  const [app, setApp] = useRecoilState(rApp);

  const organization = useRecoilValue(rOrganization);

  const user = useRecoilValue(rUser);

  const [apiKey, setApiKey] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const refreshApiKey = () => {
    setConfirmationModalData({
      title: "Request New API Key",
      text: "WARNING - This will immediately invalidate your current API key (if you have one) and replace it with a new one. Are you sure?",
      confirm: () => {
        setIsRefreshing(true);
        apiRequest
          .post("/refresh_api_key/", {
            is_refreshing: true,
          })
          .then((res) => {
            const newApiKey = get(res, ["data", "api_key"]);
            setApiKey(newApiKey);
            setIsRefreshing(false);
          });
      },
    });
  };

  const [changes, setChanges] = useState({});

  const save = (sectionKey) => {
    successNotification("Saved");

    const sectionChanges = get(changes, sectionKey);
    apiRequest.post("/app_settings/", sectionChanges);

    setChanges({
      ...changes,
      [sectionKey]: null,
    });
  };

  const allowCustomJavascript = get(organization, "allow_custom_javascript");

  const fields = [
    {
      id: "new_markdown_editor",
      label: "New Markdown Editor",
      hint: "Use the new improved markdown editor.",
      componentId: "Switch",
      value: get(app, "new_markdown_editor"),
    },
    {
      id: "enable_block_versions",
      label: "Enable Block Versions",
      hint: "Enable the 'block versions' feature which allows conditional settings for a specific block.",
      componentId: "Switch",
      value: get(app, "enable_block_versions"),
    },
    {
      id: "disable_admin_animations",
      label: "Increase Admin Performance",
      hint: "Disable animations in the admin panel and reduce the number of displayed rows to increase performance. This will not affect the user-facing app. This is only useful if you have a lot of data or blocks, or are experiencing unresponsive behavior in the admin panel.",
      componentId: "Switch",
      value: get(app, "disable_admin_animations"),
    },
    {
      id: "include_full_relation_records",
      label: "Include Full Relation Records",
      hint: "This is an advanced setting which is only rarely needed. If you are not sure, leave it off. By default, when data relations are loaded, only the minimal data is used.",
      componentId: "Switch",
      value: get(app, "include_full_relation_records"),
    },
    {
      id: "show_active_block_delete_button",
      label: "Show Active Block Delete Button",
      hint: "Show a delete button on the active block in the admin panel instead of the collapse sidebar button.",
      componentId: "Switch",
      value: get(app, "show_active_block_delete_button"),
    },
    {
      id: "number_format",
      label: "Number Format",
      componentId: "Select",
      value: get(app, "number_format"),
      options: [
        {
          label: "Euro",
          value: "euro",
        },
      ],
    },
    {
      id: "custom_javascript",
      label: "Custom Javascript",
      hint: "Add custom Javascript to your app. This will be added to the head of your app. Enter valid javascript functions only",
      componentId: "TextArea",
      orientation: "vertical",
      value: get(app, "custom_javascript"),
      displayCondition: () => allowCustomJavascript,
    },
  ];

  const apiFields = [
    {
      id: "allow_create_users_webhook",
      label: "Allow Create User Webhook",
      hint: "Enable a webhook that allows users to be created via API - This is an advanced feature",
      componentId: "Switch",
      value: get(app, "allow_create_users_webhook"),
    },
    {
      id: "api_key",
      label: "API Key - COPY THIS NOW, YOU WILL NOT SEE IT AGAIN",
      hint: "For authenticating requests from third-party services",
      componentId: "Text",
      fontSize: 20,
      fontWeight: 600,
      cursor: "pointer",
      onClick: () => {
        navigator.clipboard.writeText(apiKey);
        successNotification("Copied to clipboard");
      },
      margin: "0",
      padding: "0",
      fontColor: "var(--primary-admin)",
      text: apiKey,
      displayCondition: () => apiKey,
    },
    {
      id: "request_new_api_key",
      text: "Request New API Key",
      label: "Request New API Key",
      componentId: "Button",
      isFetching: isRefreshing,
      width: "180px",
      icon: "FiKey",
      onClick: refreshApiKey,
      displayCondition: () => !apiKey,
    },
  ].filter(
    (f) => !f.displayCondition || (f.displayCondition && f.displayCondition())
  );

  let sections = [
    {
      key: "other",
      title: "Other",
      subtitle: "Other assorted settings",
      fields: fields,
    },
  ];

  // Limit to owners
  if (get(user, "role") === "owner") {
    sections = [
      {
        key: "api",
        title: "API Endpoints",
        subtitle: "Create users via API through a webhook",
        fields: apiFields,
      },
      ...sections,
    ];
  }

  return (
    <SettingsSection
      hideCard
      width="100%"
      title="Advanced"
      description="Configure advanced settings for your app"
    >
      <Column style={{ width: "500px" }} gap="30px">
        {sections.map((section, i) => {
          const sectionChanges = get(changes, section.key);
          return (
            <SettingsCard label={section.title} description={section.subtitle}>
              <AdminForm
                labelStyle="headingSm"
                sectionPadding="0px"
                fields={section.fields.filter(
                  (f) =>
                    !f.displayCondition ||
                    (f.displayCondition && f.displayCondition(f))
                )}
                submitText={"Save Changes"}
                submit={sectionChanges ? () => save(section.key) : null}
                onChange={(k, v) => {
                  setChanges({
                    ...changes,
                    [section.key]: {
                      ...get(changes, section.key),
                      [k]: v,
                    },
                  });
                  setApp({
                    [k]: v,
                  });
                }}
              />
            </SettingsCard>
          );
        })}
      </Column>
    </SettingsSection>
  );
};

export default Advanced;
