import { Button, Drawer, Icon, Menu, Row, Switch, Text } from "app/components";
import { Container, Draggable } from "@edorivai/react-smooth-dnd";
import { arrayMove, safeArray } from "app/utils/utils";
import { colors, spacing } from "app/utils/theme";
import { get, startCase } from "lodash";
import {
  rActiveDetailViewId,
  rActiveEditField,
  rApp,
  rAppDateFormat,
  rConfirmationModalData,
  rSavedSpreadsheets,
} from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import AdminForm from "./AdminForm";
import { InfoBox } from "../settings/InfoBox";
import { getFormFields } from "app/renderingApp/blocks/Form/utils";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";
import useActiveBlock from "app/utils/useActiveBlock";
import useActiveBlockSheet from "app/utils/useActiveBlockSheet";
import useDynamicText from "app/renderingApp/useDynamicText";

export const getRelationHeaders = (
  sheet,
  app,
  spreadsheets,
  editField,
  showOnlyRelationFields = false
) => {
  // This oonly gets the related headers for the form dropdowns
  if (showOnlyRelationFields) {
    return getFormHiddenFilterRelationHeaders(
      sheet,
      app,
      spreadsheets,
      editField,
      showOnlyRelationFields
    );
  }

  // Get all related headers
  let relatedHeaders = [];

  const sheetId = get(sheet, "id");

  const relations = get(app, "data_relations", []) || [];

  relations
    .filter((r) => [r.sheet1, r.sheet2].includes(sheetId))
    .forEach((r) => {
      const isRelated = r.sheet2 === sheetId;
      if (isRelated) {
        // Check if editField matches relation field and if so, skip it
        const matchesEditField = r.column1 === editField;
        if (!matchesEditField) {
          const relationId = get(r, "relationId") || get(r, "column2");

          const relatedSheet = spreadsheets.find((s) => s.id === r.sheet1);
          const relatedSheetHeaders = get(relatedSheet, "headers", []);
          relatedSheetHeaders.forEach((h) =>
            relatedHeaders.push(`${relationId}__${h}`)
          );
        }
      }
    });

  return relatedHeaders;
};

export const getFormHiddenFilterRelationHeaders = (
  sheet,
  app,
  spreadsheets,
  editField
) => {
  // Get all related headers
  let relatedHeaders = [];

  const sheetId = get(sheet, "id");

  const relations = get(app, "data_relations", []) || [];

  // Get primary relations
  relations
    .filter((r) => r.sheet2 === sheetId && r.column2 === editField)
    .forEach((r) => {
      const relatedSheet = spreadsheets.find((s) => s.id === r.sheet1);
      const relatedSheetHeaders = get(relatedSheet, "headers", []);
      relatedSheetHeaders.forEach((h) => relatedHeaders.push(h));

      relations
        .filter((r2) => r2.sheet2 === r.sheet1)
        .forEach((r2) => {
          const relatedSheet2 = spreadsheets.find((s) => s.id === r2.sheet1);
          const relatedSheetHeaders2 = get(relatedSheet2, "headers", []);

          // TODO - NEED TO CHECK IF THIS RELATION FALLBACK WORKS
          const relationId = get(r2, "relationId") || get(r2, "column2");

          relatedSheetHeaders2.forEach((h) =>
            relatedHeaders.push(`${relationId}__${h}`)
          );
        });
    });

  return relatedHeaders;
};

const StaticFields = ({ data }) => {
  const [editField, setEditField] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const [showAdvanced, setShowAdvanced] = useState(false);

  const menuStyle = "popup";

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const appDateFormat = useRecoilValue(rAppDateFormat);

  const activeBlockSheet = useActiveBlockSheet();

  const activeBlock = useActiveBlock();

  const activeDetailViewId = useRecoilValue(rActiveDetailViewId);

  const app = useRecoilValue(rApp);

  const [activeEditField, setActiveEditField] =
    useRecoilState(rActiveEditField);

  useEffect(() => {
    setShowAdvanced(false);
  }, [activeEditField]);

  const { processDynamicText } = useDynamicText();

  const fieldBlockId = get(activeEditField, "blockId");

  const matchesFormContext = () => {
    const context1 = get(activeEditField, "formContext");
    const context2 = get(data, "formContext");

    if (context1 || context2) {
      return context1 === context2;
    }

    return true;
  };

  const matchesContext = matchesFormContext();

  const matchesBlock =
    matchesContext &&
    (fieldBlockId === activeDetailViewId ||
      (fieldBlockId === get(activeBlock, "id") &&
        fieldBlockId === get(data, "block")));

  useEffect(() => {
    if (activeEditField && matchesBlock) {
      setEditField(activeEditField.id);
      setAnchorElement(activeEditField.target);
    }
  }, [activeEditField]);

  useEffect(() => {
    if (activeEditField && !matchesBlock) {
      setActiveEditField(null);
    }
  }, [activeEditField]);

  // Pass in customSpreadsheetId to use instead of active block
  const customSpreadsheetId = get(data, "customSpreadsheetId");

  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const sheet = savedSpreadsheets.find((s) => s.id === customSpreadsheetId);

  // Pick sheet based on whether customSpreadsheetId or block
  const currentSheet = sheet || activeBlockSheet;

  const relatedHeaders = data.showRelationFields
    ? getRelationHeaders(currentSheet, app, savedSpreadsheets)
    : [];

  // SHEET FIELD DATA
  const sheetFieldData = get(currentSheet, "field_data", {});
  const sheetFieldDataConfig = get(sheetFieldData, "config", {});
  const sheetOrder = get(sheetFieldData, "order", []);

  // LOCAL FIELD DATA
  const value = get(data, "value", {});
  const config = get(value, "config", {});
  const localOrder = get(value, "order", []);

  const activeFieldData = get(sheetFieldDataConfig, editField);

  const headers = [...get(currentSheet, "headers", [])];

  const { displayFields, displayOrder } = getFormFields({
    appDateFormat,
    processDynamicText,
    relatedHeaders,
    sheetOrder,
    localOrder,
    sheetHeaders: headers,
    sheetFieldData: sheetFieldDataConfig,
    localFieldData: config,
    showInactive: true,
  });

  const updateField = (fieldKey, key, value) => {
    let matchingField = { ...get(config, fieldKey, {}), [key]: value };

    // HIDING FOR NOW, FOR SECURITY REASONS
    // If 'Select' dropdown, auto detect options
    // if (key === "componentId" && value === "Select") {
    //   const sheetData = get(currentSheet, "data", []);
    //   let options = [];
    //   const optionsSet = new Map();
    //   sheetData.forEach((item) => {
    //     optionsSet.set(String(item[fieldKey]));
    //   });

    //   optionsSet.forEach((_, key) => {
    //     if (key) {
    //       options.push({
    //         label: key,
    //         value: key,
    //       });
    //     }
    //   });
    //   matchingField = { ...matchingField, options };
    // }

    data.onChange({
      config: { ...config, [fieldKey]: matchingField },
      order: localOrder,
    });
  };

  const toggleFieldsActiveState = (state) => {
    const updatedConfig = { ...config };
    displayFields.forEach((field) => {
      updatedConfig[field.key] = { ...updatedConfig[field.key], active: state };
    });
    data.onChange({
      config: updatedConfig,
      order: localOrder,
    });
  };

  const activeField = { ...activeFieldData, ...get(config, editField, {}) };

  const initialFields = get(data, "keys", []).map((f) => ({
    ...f,
    value:
      get(activeField, f.id) || get(sheetFieldDataConfig, [editField, f.id]),
  }));

  const editFields = initialFields
    .filter((f) => !f.advanced || showAdvanced)
    .filter(
      (f) =>
        !f.displayCondition ||
        (f.displayCondition &&
          f.displayCondition(activeField, editField, {
            app,
            sheetId: get(activeBlockSheet, "id"),
          }))
    );

  const [expanded, setExpanded] = useState(data.expanded);

  const relationFieldKeys = safeArray(app, "data_relations")
    .filter((r) => r.sheet2 === get(currentSheet, "id"))
    .map((r) => r.column2);

  const editFieldIsRelation = relationFieldKeys.includes(editField);

  const MenuContainer = menuStyle === "drawer" ? Drawer : Menu;

  // Drawer
  const drawerProps = {
    open: true,
    width: "300px",
    hide: () => {
      setEditField(null);
      setActiveEditField(null);
    },
    label: startCase(editField),
    background: "var(--grey1)",
  };

  // Menu
  const menuProps = {
    anchorElement,
    hide: () => {
      setAnchorElement(null);
      setEditField(null);
      setActiveEditField(null);
    },
    label: startCase(editField),
    anchorOrigin: {
      vertical: "top",
      horizontal: "right",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right",
    },
  };

  const popupProps = menuStyle === "drawer" ? { data: drawerProps } : menuProps;

  const hasAdvancedSettings =
    initialFields.filter((f) => f.advanced).length > 0;

  return (
    <div>
      {editField && (
        <MenuContainer {...popupProps}>
          {editFieldIsRelation && (
            <InfoBox padding="10px" margin="0 0 10px 0">
              This is a related field. Some settings won't apply.
            </InfoBox>
          )}
          <AdminForm
            sectionPadding="0px"
            fields={editFields}
            editField={editField}
            onChange={(k, v) => updateField(editField, k, v)}
          />

          {hasAdvancedSettings && (
            <Text
              data={{
                text: `${showAdvanced ? "Hide" : "Show"} Advanced Settings`,
                color: "var(--primary-admin)",
                margin: "10px 0 0 0",
                fontStyle: "headingSm",
                onClick: () => setShowAdvanced(!showAdvanced),
              }}
            />
          )}
        </MenuContainer>
      )}

      {displayFields.length > 0 && (
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            borderTop: `1px solid var(--grey21)`,
            background: "white",
          }}
          dragHandleSelector=".drag-item"
          lockAxis="y"
          onDrop={(e) => {
            const { addedIndex, removedIndex } = e;
            const movedItems = arrayMove(
              displayOrder,
              removedIndex,
              addedIndex
            );
            data.onChange({ ...value, order: movedItems });
          }}
        >
          {displayFields
            .filter((f, i) => expanded || i < 4)
            .map((f) => (
              <Draggable key={f.key}>
                <Field
                  {...{
                    field: f,
                    setAnchorElement,
                    setEditField,
                    updateField,
                    getDescription: data.getDescription,
                  }}
                />
              </Draggable>
            ))}
          {displayFields.length > 4 && !expanded && (
            <FieldContainer onClick={() => setExpanded(true)}>
              <Row alignItems="center">
                <Icon
                  data={{
                    icon: "FiPlus",
                    color: "var(--grey21)",
                    size: 18,
                  }}
                />
                <Text
                  data={{
                    text: `Show ${displayFields.length - 4} More`,
                    cursor: "pointer",
                    margin: "0 3px 0 3px",
                    fontStyle: "headingSm",
                  }}
                />
              </Row>
            </FieldContainer>
          )}
        </Container>
      )}

      {/* ALLOW CLEARING LOCAL ORDERING */}
      {safeArray(localOrder).length > 0 && (
        <Button
          data={{
            margin: "10px 0 0 0",
            text: "Clear Ordering",
            type: "basic",
            size: "small",
            onClick: () =>
              setConfirmationModalData({
                title: "Clear Ordering",
                text: "This will clear the custom ordering for this block and revert to any sheet-level ordering. Are you sure?",
                confirm: () => {
                  successNotification("Ordering Cleared");
                  data.onChange({ ...value, order: [] });
                },
              }),
          }}
        />
      )}

      {displayFields.length === 0 && <Text data={{ text: "No Fields" }} />}

      {displayFields.length > 0 && (
        <Row justifyContent="space-between" gap="30px">
          <Text
            data={{
              text: "Select All",
              margin: "5px 0 0 0",
              color: "var(--primary-admin)",
              fontStyle: "headingXs",
              cursor: "pointer",
              onClick: () => toggleFieldsActiveState(true),
            }}
          />
          <Text
            data={{
              text: "Deselect All",
              margin: "5px 0 0 0",
              color: "var(--primary-admin)",
              fontStyle: "headingXs",
              cursor: "pointer",
              onClick: () => toggleFieldsActiveState(false),
            }}
          />
        </Row>
      )}
    </div>
  );
};

export default StaticFields;

const Field = ({
  field,
  setAnchorElement,
  setEditField,
  updateField,
  getDescription,
}) => {
  const [showEdit, setShowEdit] = useState(false);

  return (
    <FieldContainer
      disabled={!field.active}
      onMouseEnter={() => {
        if (field.active && !showEdit) {
          setShowEdit(true);
        }
      }}
      onMouseLeave={() => {
        if (field.active && showEdit) {
          setShowEdit(false);
        }
      }}
      className="drag-item"
      onClick={(e) => {
        if (field.active) {
          setAnchorElement(e.currentTarget);
          setEditField(field.key);
        }
      }}
    >
      <Row alignItems="center">
        <Icon
          data={{
            icon: "RxDragHandleDots2",
            color: "var(--grey21)",
            size: 18,
          }}
        />
        <Text
          data={{
            text: startCase(field.key),
            cursor: "pointer",
            margin: "0 3px 0 3px",
          }}
        />
        {getDescription && (
          <Text
            data={{
              text: getDescription(field),
              cursor: "pointer",
              margin: "0 3px 0 3px",
              color: "var(--grey7)",
            }}
          />
        )}
        {showEdit && (
          <Icon
            data={{
              icon: "FiEdit",
              color: "var(--grey7)",
              size: 14,
            }}
          />
        )}
      </Row>
      <Switch
        data={{
          value: field.active,
          onChange: (v) => updateField(field.key, "active", v),
        }}
      />
    </FieldContainer>
  );
};

const FieldContainer = styled.div`
  padding: ${spacing.s2} 0px ${spacing.s2} 0px;
  border-bottom: 1px solid var(--grey21);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  transition: 200ms all;
  &:hover {
    background: var(--grey1);
  }
  :not(:last-child) {
    border-bottom: 1px solid var(--grey1);
  }
`;
