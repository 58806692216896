import { colors, spacing } from "app/utils/theme";

import { getPixels } from "app/utils/utils";
import styled from "styled-components";

export const ActiveIndicator = styled.div`
  position: absolute;
  background: transparent;
  z-index: 9998;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px solid ${colors.frontlyBlue};
  pointer-events: none;
  opacity: 0;
  ${(p) => p.active && `opacity: 1;`}
  transition: opacity 0.2s;
  border-radius: ${(p) => p.borderRadius || "0px"};
`;

export const HoverIndicator = styled.div`
  position: absolute;
  background: ${colors.frontlyBlue};
  z-index: 9998;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${(p) => p.color};
`;

export const BlockLabel = styled.div`
  color: ${(p) => p.color || (p.darkMode ? "white" : colors.default)};
  font-size: 20px;
  font-weight: 600;
  margin: 0 20px 0 0;
  ${(p) => p.customCss}
`;

export const BlockLabelInput = styled.input`
  color: ${(p) => p.color || (p.darkMode ? "white" : colors.default)};
  min-width: 60px;
  padding: 0px;
  background: transparent;
  width: ${(p) => getPixels(p.width)};
  font-size: 20px;
  font-weight: 600;
  margin: 0 20px 0 0;
  border: 0px;
  cursor: default;
  &:focus {
    outline: none;
    background: ${(p) =>
      p.darkMode ? "rgba(255, 255, 255, 0.02)" : "rgba(0,0,0, 0.02)"};
  }

  ${(p) =>
    p.isFrontlyAdmin &&
    `
  &:hover {
    background: ${(p) =>
      p.darkMode ? "rgba(255, 255, 255, 0.02)" : "rgba(0,0,0, 0.02)"};
    cursor: text;
  }
`}
  ${(p) => p.customCss}
`;

export const FiltersRow = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  flex: 1;
`;

export const BlockHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing.s5};
`;

export const BlockContainer = styled.div`
  position: relative;
  ${(p) =>
    p.newLayoutSystem &&
    p.parentComponentId === "Row" &&
    `
    flex-grow: ${p.fillRemainingSpace ? 1 : 0};
    flex-basis: ${p.width ? getPixels(p.width) : "auto"};
    flex-shrink: 1;
  `}

  ${(p) =>
    p.newLayoutSystem &&
    p.parentComponentId === "Column" &&
    `
    width: ${p.width || "auto"};
    ${p.fillRemainingSpace && `width: 100%;`}
  `}

  ${(p) =>
    !p.newLayoutSystem &&
    `
    width: ${p.width || "100%"};
    ${p.fillRemainingSpace && p.parentComponentId === "Row" && `flex: 1;`}
    ${
      p.fillRemainingSpace && p.parentComponentId === "Column" && `width: 100%;`
    }
  `}

  ${(p) =>
    p.newGridSystem &&
    `
    grid-column: span ${p.columnSpan};
    grid-row: span ${p.rowSpan};
  `}

  height: ${(p) => p.height || "auto"};
  display: flex;
  flex-direction: column;
  overflow: ${(p) => (p.inLayout || p.height !== "auto" ? "auto" : "initial")};
  border-radius: ${(p) => p.borderRadius};
  border-width: ${(p) => p.borderWidth};
  border-color: ${(p) => p.borderColor};
  border-style: ${(p) => p.borderStyle || "solid"};

  box-shadow: ${(p) => p.boxShadow};

  margin: ${(p) => p.margin};
  padding: ${(p) => p.padding};
  background: ${(p) => p.background};

  ${(p) =>
    !p.showBackground &&
    `
    background: transparent;
    padding: 0px;
    ${p.forceBorder ? "" : "border: 0px;"}
    box-shadow: none;
    border-radius: 0px;
    overflow: visible;
    `}

  ${(p) => (p.hovering || p.onClick) && `cursor: pointer;`}

  ${(p) =>
    p.newLayoutSystem &&
    ["Row", "Column"].includes(p.componentId) &&
    "overflow: hidden;"}

  ${(p) =>
    p.backgroundImage &&
    `
    background-repeat: no-repeat;
    background-image: url(${p.backgroundImage});
    background-size: ${p.backgroundSize};
    background-position: ${p.backgroundPosition};
  `};

  ${(p) => p.isDragging && "opacity: 0.4;"}

  ${(p) => p.addVisiblePadding && "padding: 20px;"}

  ${(p) => p.stopPointerEvents && "pointer-events: none;"}

  ${(p) => p.customCss}
`;

export const WarningBubble = styled.div`
  background: #df516e;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 3px;
  border-radius: 5px;
  margin-top: 5px;
`;
