import { Icon, Modal, SearchBar, Tabs, Text } from "app/components";
import {
  allBlocks,
  allowedInsideCustomReusable,
  fixBlocks,
  fixCustomBlocks,
  getAllBlocksBeneath,
  getBlockLabel,
  getBlocksWithPlaceholder,
  getHighest,
  safeArray,
} from "app/utils/utils";
import { boxShadow, colors, spacing } from "app/utils/theme";
import {
  rActiveBlockId,
  rActiveDetailViewId,
  rApp,
  rCustomBlocks,
  rOrganization,
  rSubscription,
  rUser,
} from "app/utils/recoil";
import { useRecoilValue, useSetRecoilState } from "recoil";

import AiBlockGenerator from "./AIBlockGenerator";
import { errorNotification } from "app/utils/Notification";
import { get } from "lodash";
import { getBlockBundle } from "../DuplicateBlock";
import marketplaceBlocks from "app/adminApp/marketplaceBlocks.json";
import styled from "styled-components";
import useActiveBlock from "app/utils/useActiveBlock";
import useActiveCustomBlock from "app/utils/useActiveCustomBlock";
import useAddBlock from "app/utils/useAddBlock";
import usePage from "app/utils/usePage";
import useSetPageNew from "app/utils/useSetPageNew";
import { useState } from "react";

export const NewBlock = ({
  hide,
  layoutPosition = null,
  layoutParent = null,
  onBlockSelect = null,
  blockTypes = null,
  excludeTabs = [],
  title = null,
  placeholderId = null,
}) => {
  const addBlock = useAddBlock();

  const { activeCustomBlock } = useActiveCustomBlock();

  const page = usePage();

  const user = useRecoilValue(rUser);

  const { setPage } = useSetPageNew();

  const activeApp = useRecoilValue(rApp);
  const activeBlock = useActiveBlock();

  const customBlocks = useRecoilValue(rCustomBlocks);

  const activeDetailViewId = useRecoilValue(rActiveDetailViewId);
  const activeBlockId = useRecoilValue(rActiveBlockId);

  const subscription = useRecoilValue(rSubscription);

  const organization = useRecoilValue(rOrganization);
  const aiUsage = get(organization, "ai_usage", 0);

  const aiLimit = get(subscription, "ai_requests_limit", 5);
  const overAiLimit = aiUsage >= aiLimit;

  const setActiveBlockId = useSetRecoilState(rActiveBlockId);

  const [search, setSearch] = useState("");
  const [tab, setTab] = useState("all");

  // This is a temporary solution, needs recursion to function properly
  const thisBlock = get(page, "blocks", []).find((b) => b.id === activeBlockId);

  // If the active block is a layout block, collect ids for the blocks beneath it
  let detailBlocks = [];
  if (activeDetailViewId) {
    detailBlocks = getAllBlocksBeneath(
      activeDetailViewId,
      get(page, "blocks", [])
    );
  }

  const blocks = get(page, "blocks", []).filter((b) => {
    // TODO - This needs further thought
    if (activeDetailViewId) {
      return (
        detailBlocks.includes(b.id) &&
        get(thisBlock, "layoutParent") !== b.id &&
        b.id !== activeBlockId
      );
    } else {
      return (
        //
        !b.layoutParent &&
        //
        get(thisBlock, "layoutParent") !== b.id &&
        //
        b.componentId !== "Layout" &&
        //
        (!b.parent || b.parent === activeDetailViewId) &&
        //
        b.id !== activeBlockId
      );
    }
  });

  const addCustomBlock = (block) => {
    const pageBlocks = get(page, "blocks", []);

    if (block.reusable) {
      const newBlockId = getHighest(pageBlocks, "id") + 1;

      let newBlockObj = {
        id: newBlockId,
        componentId: "Custom",
        customBlock: block.id,
        label: block.name,
      };

      if (block.repeating) {
        newBlockObj.resultsPerPage = 10;
        newBlockObj.showCreate = true;
        newBlockObj.showSearch = true;
      }

      // Add to custom view if present
      if (activeDetailViewId) {
        newBlockObj.parent = activeDetailViewId;
      }

      if (
        activeBlockId &&
        ["Row", "Column"].includes(activeBlock.componentId)
      ) {
        newBlockObj.layoutParent = activeBlockId;
      }

      // ADD REUSABLE BLOCK
      setPage({
        ...page,
        blocks: [...get(page, "blocks", []), newBlockObj],
      });

      setActiveBlockId(newBlockId); // Set the newly duplicated block as active

      hide();
    } else {
      // ADD REGULAR BLOCK

      const savedBlocks = fixCustomBlocks(fixBlocks(get(block, "blocks", [])));

      const savedActions = get(block, "actions", []);

      const rootBlock = savedBlocks.find((b) => !b.layoutParent && !b.parent);

      const actions = [...get(page, "actions", []), ...savedActions];
      const blocks = [...get(page, "blocks", []), ...savedBlocks];

      const bundle = getBlockBundle({
        block: rootBlock,
        actions,
        blocks,
        blocksToDuplicate: savedBlocks,
      });

      const newBlockId = get(bundle, "blockId");

      const finalActions = [
        ...get(page, "actions", []),
        ...get(bundle, "actions", []),
      ];
      const finalBlocks = [
        ...get(page, "blocks", []),
        ...get(bundle, "blocks", []),
      ];

      let newBlocks = handleBlocks(finalBlocks, newBlockId);

      // Replace placeholder block with new block data
      if (placeholderId) {
        newBlocks = getBlocksWithPlaceholder(
          newBlocks,
          placeholderId,
          newBlockId
        );
      }

      setPage({
        ...page,
        actions: finalActions,
        blocks: newBlocks,
      });

      setActiveBlockId(newBlockId); // Set the newly duplicated block as active

      hide();
    }
  };

  const handleBlocks = (bl, blockId) => {
    return bl.map((b) => {
      if (b.id === blockId) {
        let updatedBlock = {
          ...b,
        };

        if (layoutParent) {
          updatedBlock.layoutParent = layoutParent;
        }

        if (layoutPosition) {
          updatedBlock.layoutPosition = layoutPosition;
        }

        if (activeDetailViewId) {
          updatedBlock.parent = activeDetailViewId;
        }

        // If the block is in a layout block, remove the parent
        if (updatedBlock.layoutParent && updatedBlock.parent) {
          delete updatedBlock["parent"];
        }

        return updatedBlock;
      }
      return b;
    });
  };

  const update = (blockId) => {
    setPage({
      ...page,
      blocks: handleBlocks(get(page, "blocks", []), blockId),
    });
    hide();
  };

  // // Input blocks
  // const inputBlocks = ["Input", "TextArea", "Placeholder", "Switch", "Select"];

  let displayBlocks = allBlocks.filter((b) => {
    let matchesSearch = false;

    // SEARCH
    if (search !== "") {
      const matchesType = b.type.toLowerCase().includes(search.toLowerCase());
      const matchesDescription = b.description
        .toLowerCase()
        .includes(search.toLowerCase());

      matchesSearch = matchesType || matchesDescription;

      if (!matchesType && !matchesDescription) {
        return false;
      }
    }

    if (matchesSearch) {
      return true;
    }

    if (activeCustomBlock && activeCustomBlock.repeating) {
      if (!allowedInsideCustomReusable.includes(b.type)) {
        return false;
      }
    }

    if (
      b.type === "Progress" &&
      !get(activeApp, "subdomain") === "magpiefamilylaw"
    ) {
      return false;
    }

    if (
      b.type === "SmartBlock" &&
      get(user, "email") !== "patrick@frontlyapp.com"
    ) {
      return false;
    }

    if (
      b.type === "AIBlock" &&
      (!get(activeApp, "open_ai_api_key_configured") ||
        !get(activeApp, "ai_context_beta"))
    ) {
      return false;
    }

    // EXCLUDE DEPRECATED / INVALID
    if (["TextButtonRow", "Layout", "Custom"].includes(b.type)) {
      return false;
    }

    // BLOCK TYPES
    if (blockTypes && !blockTypes.includes(b.type)) {
      return false;
    }

    // TABS
    if (tab !== "all" && tab !== b.category) {
      return false;
    }

    return true;
  });

  const showAddExistingBlock =
    !activeDetailViewId &&
    blocks.length > 0 &&
    (layoutPosition ||
      ["Row", "Column", "Layout"].includes(get(activeBlock, "componentId")));

  const filteredMarketplaceBlocks = marketplaceBlocks.filter((b) => {
    const passesSearch =
      search.toLowerCase() === "" ||
      get(b, "name", "")
        .toString()
        .toLowerCase()
        .includes(search.toLowerCase()) ||
      get(b, "description", "")
        .toString()
        .toLowerCase()
        .includes(search.toLowerCase());

    return passesSearch;
  });

  const [showAiModal, setShowAiModal] = useState(false);

  let modalContent = (
    <>
      {showAddExistingBlock && (
        <>
          <Text
            data={{
              text: "Add Existing Block To Layout",
              fontStyle: "headingLg",
              margin: "20px 0 20px 0",
            }}
          />
          <BlocksContainer>
            {blocks.map((b) => (
              <BlockTile
                onClick={() => update(b.id)}
                title={getBlockLabel(b)}
                description={b.componentId}
                icon={get(
                  allBlocks.find((bl) => bl.type === b.componentId),
                  "icon"
                )}
              />
            ))}
          </BlocksContainer>
          <Text
            data={{
              text: "Create New Block",
              fontStyle: "headingLg",
              margin: "30px 0 20px 0",
            }}
          />
        </>
      )}

      <Tabs
        data={{
          margin: "15px 0 0 0",
          tabs: [
            {
              label: "All",
              active: tab === "all",
              onClick: () => setTab("all"),
            },
            {
              label: "Data Display",
              active: tab === "dataDisplay",
              onClick: () => setTab("dataDisplay"),
            },
            {
              label: "Form",
              active: tab === "form",
              onClick: () => setTab("form"),
            },
            {
              label: "Layout",
              active: tab === "layout",
              onClick: () => setTab("layout"),
            },
            {
              label: "Analytics",
              active: tab === "analytics",
              onClick: () => setTab("analytics"),
            },
            {
              label: "Media",
              active: tab === "media",
              onClick: () => setTab("media"),
            },
            {
              label: "Other",
              active: tab === "other",
              onClick: () => setTab("other"),
            },
            {
              label: "Custom",
              active: tab === "custom",
              onClick: () => setTab("custom"),
            },
            {
              label: "Marketplace",
              active: tab === "marketplace",
              onClick: () => setTab("marketplace"),
            },
          ].filter((t) => !excludeTabs.includes(t.label.toLowerCase())),
        }}
      />
      <SearchBar
        data={{
          value: search,
          placeholder: "Search for blocks",
          margin: "10px 0 20px 0",
          onChange: (v) => setSearch(v),
        }}
      />

      <BlocksContainer count={displayBlocks.length}>
        {tab === "all" && search === "" && (
          <BlockTile
            onClick={() =>
              overAiLimit
                ? errorNotification(
                    "You have reached your monthly AI Token limit. Upgrade to increase your AI tokens."
                  )
                : setShowAiModal(true)
            }
            title={"Generate With AI"}
            description={"Generate a block from a prompt with AI"}
            icon={"HiSparkles"}
          />
        )}
        {displayBlocks.map((b) => (
          <BlockTile
            onClick={() =>
              onBlockSelect
                ? onBlockSelect(b.type)
                : addBlock({
                    type: b.type,
                    callback: hide,
                    layoutPosition,
                    layoutParent,
                    placeholderId,
                  })
            }
            title={b.type}
            description={b.description}
            icon={b.icon}
          />
        ))}
      </BlocksContainer>
      {["custom", "all"].includes(tab) && (
        <>
          {tab !== "custom" && (
            <Text
              data={{
                text: "Custom Blocks",
                fontStyle: "headingLg",
                margin: "30px 0 20px 0",
              }}
            />
          )}
          <BlocksContainer>
            {safeArray(customBlocks)
              .filter((b) => {
                // Filter out repeating blocks if the active block is a repeating block
                // And filter out the active custom block
                if (activeCustomBlock && activeCustomBlock.repeating) {
                  const m = safeArray(customBlocks).find(
                    (cb) => cb.id === b.id
                  );
                  if ((m && m.repeating) || activeCustomBlock.id === b.id) {
                    return false;
                  }
                  return true;
                }
                return true;
              })
              .filter((b) => {
                const passesSearch =
                  search.toLowerCase() === "" ||
                  get(b, "name", "")
                    .toString()
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                  get(b, "description", "")
                    .toString()
                    .toLowerCase()
                    .includes(search.toLowerCase());

                const passesTabs = tab === "custom" || tab === "all";

                return passesSearch && passesTabs;
              })
              .map((b) => (
                <BlockTile
                  onClick={() => addCustomBlock(b)}
                  title={b.name}
                  description={b.description}
                  icon="FiBox"
                />
              ))}
          </BlocksContainer>
        </>
      )}
      {["marketplace", "all"].includes(tab) && (
        <>
          {tab !== "marketplace" && (
            <Text
              data={{
                text: "Marketplace Blocks",
                fontStyle: "headingLg",
                margin: "30px 0 20px 0",
              }}
            />
          )}
          <BlockMarketplaceContainer>
            {filteredMarketplaceBlocks.map((b) => (
              <MarketplaceBlock block={b} onClick={() => addCustomBlock(b)} />
            ))}
          </BlockMarketplaceContainer>
        </>
      )}
    </>
  );

  let modalTitle = title || "Add New Block";
  let modalDescription = "Blocks make up the content of your page";

  if (showAiModal) {
    modalTitle = "Generate A Block With AI";
    modalDescription =
      "Describe the block you're looking for and we'll generate it for you with AI";
    modalContent = (
      <AiBlockGenerator
        hide={() => setShowAiModal(false)}
        hideAll={() => {
          hide();
          setShowAiModal(false);
        }}
      />
    );
  }

  return (
    <Modal
      minWidth="850px"
      titleFontSize="24px"
      header={{
        title: modalTitle,
        description: modalDescription,
      }}
      hide={hide}
      background="var(--grey1)"
    >
      {modalContent}
    </Modal>
  );
};

const MarketplaceBlock = ({ block, onClick }) => {
  return (
    <MarketBlockContainer onClick={onClick}>
      <BlockName>{block.name}</BlockName>
      <BlockDescription>{block.description}</BlockDescription>
      <BlockImage src={block.image} />
    </MarketBlockContainer>
  );
};

const MarketBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  padding: ${spacing.s4};
  box-shadow: ${boxShadow.card};
`;

const BlockName = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const BlockDescription = styled.div`
  font-size: 12px;
  font-weight: 300;
  margin: 5px 0 15px 0;
`;

const BlockImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: contain;
  border-radius: 8px;
  cursor: pointer;
  background: #f0f0f3;
  border: 1px solid var(--grey3);
  padding: 10px;
`;

const IconWrapper = styled.div`
  min-width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fbfcfe;
  border: 1px solid rgb(196, 213, 243);
  border-radius: 4px;
`;

const BlockMarketplaceContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: auto;
  grid-gap: ${spacing.s4};
  ${(p) =>
    p.count && p.count < 3 && "grid-template-columns: 300px 300px 300px;"}
  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
`;

export const BlocksContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: auto;
  grid-gap: ${spacing.s4};
  ${(p) =>
    p.count && p.count < 3 && "grid-template-columns: 300px 300px 300px;"}
  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
`;

const Block = styled.div`
  display: flex;
  align-items: center;
  padding: ${spacing.s4};
  gap: ${spacing.s4};
  background: white;
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
  box-shadow: ${boxShadow.card};
`;

export const BlockTile = ({
  title,
  description,
  icon,
  onClick,
  disabledMessage = null,
}) => {
  return (
    <Block
      onClick={() =>
        disabledMessage ? errorNotification(disabledMessage) : onClick()
      }
    >
      <IconWrapper>
        <Icon
          data={{
            icon,
            color: colors.frontlyBlue,
            size: 26,
            hover: true,
            isBlue: true,
          }}
        />
      </IconWrapper>
      <div>
        <Text
          data={{
            text: title,
            fontStyle: "headingMd",
            cursor: "pointer",
          }}
        />
        <Text
          data={{
            text: description,
            fontStyle: "bodySm",
            color: "var(--grey8)",
            cursor: "pointer",
            margin: "3px 0 0 0",
          }}
        />
      </div>
    </Block>
  );
};
