import { rOrganization, rSubscription, rUser } from "app/utils/recoil";

import AdminWrapper from "./AdminWrapper";
import { AppsContent } from "./Apps";
import DataSourcesCard from "./DataSourcesCard";
import RecentAiSessions from "./RecentAiSessions";
import { TemplatesSection } from "../appManagement/Templates";
import { get } from "lodash";
import moment from "moment";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

const Home = () => {
  const organization = useRecoilValue(rOrganization);
  const signupDate = get(organization, "signup_date");
  const trialExpiry = moment(signupDate).add(7, "days").fromNow(true);
  const daysSinceSignup = moment().diff(signupDate, "days");
  const subscription = useRecoilValue(rSubscription);

  const showTrialBanner = !subscription && daysSinceSignup < 7;
  const showExpiredBanner = !subscription && daysSinceSignup >= 7;

  const user = useRecoilValue(rUser);

  const navigate = useNavigate();

  return (
    <AdminWrapper padding="0px">
      {showTrialBanner && (
        <TrialBanner onClick={() => navigate("/billing")}>
          Your free trial ends in {trialExpiry}. Upgrade now to continue
          accessing your apps.
        </TrialBanner>
      )}
      {showExpiredBanner && (
        <TrialBanner onClick={() => navigate("/billing")}>
          Your free trial has expired. Upgrade now to continue accessing your
          apps.
        </TrialBanner>
      )}
      <Sections>
        {/* <RecentAiSessions /> */}
        <DataSourcesCard />
        {get(user, "can_create_apps") && <TemplatesSection />}
        {/* <Resources /> */}
        <AppsContent itemsPerPage={8} />
      </Sections>
    </AdminWrapper>
  );
};

export default Home;

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 40px;
`;

const TrialBanner = styled.div`
  background: var(--primary-admin);
  color: white;
  padding: 10px 20px 10px 20px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
`;
