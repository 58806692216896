import { boxShadow, colors } from "app/utils/theme";
import { get, startCase } from "lodash";
import {
  rApp,
  rDarkMode,
  rSavedSpreadsheets,
  rTranslations,
  rUser,
  userStateSelector,
} from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { AdminForm } from "../components";
import ClientNavigationWrapper from "app/renderingApp/ClientNavigationWrapper";
import Password from "./Password";
import { Text } from "app/components";
import { apiRequest } from "app/utils/apiRequests";
import { safeString } from "app/utils/utils";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";

const EditActiveUser = () => {
  const translations = useRecoilValue(rTranslations);

  const user = useRecoilValue(rUser);

  const activeApp = useRecoilValue(rApp);

  const userSchema = get(activeApp, "user_schema", []) || [];

  const darkMode = useRecoilValue(rDarkMode);

  const [changes, setChanges] = useState(false);

  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const usersSheetId = get(activeApp, ["users_sheet_config", "spreadsheet_id"]);
  const usersSheetHeaders = get(
    savedSpreadsheets.find((s) => s.id === usersSheetId),
    "headers",
    []
  );

  let fields = [
    {
      id: "first_name",
      label: get(translations, "firstName", "First Name"),
    },
    {
      id: "last_name",
      label: get(translations, "lastName", "Last Name"),
    },
    {
      id: "email",
      disabled: true,
      label: get(translations, "email", "Email"),
    },
    ...userSchema
      .filter((s) => s.editableByUser)
      .map((s) => ({
        id: s.name,
        label: s.name,
        componentId: get(s, "type"),
        orientation: get(s, "type") === "Switch" ? "horizontal" : null,
        options: get(s, "options", []),
      })),
  ];

  const enabledUsersSheetEditProfileFields = safeString(
    get(activeApp, "users_sheet_edit_profile_fields", "")
  )
    .split(",")
    .map((f) => f.trim())
    .filter((h) => h);

  // Add user sheet fields
  fields = [
    ...fields,
    ...usersSheetHeaders
      .filter((h) =>
        enabledUsersSheetEditProfileFields.length === 0
          ? true
          : enabledUsersSheetEditProfileFields.includes(h)
      )
      .filter((h) => ![...fields.map((f) => f.id), "frontly_id"].includes(h))
      .map((h) => ({
        id: h,
        label: startCase(h),
      })),
  ];

  const [localData, setLocalData] = useState({});

  const setUser = useSetRecoilState(userStateSelector);

  useEffect(() => {
    let initialUserObject = {};
    fields.forEach((f) => {
      initialUserObject[f.id] = get(user, f.id);
    });
    setLocalData({ ...initialUserObject });
  }, []);

  return (
    <ClientNavigationWrapper isEditProfile>
      <Container darkMode={darkMode}>
        <Text
          data={{
            text: get(translations, "editProfile", "Edit Profile"),
            fontStyle: "headingLg",
            margin: "0 0 15px 0",
            color: darkMode ? "white" : colors.default,
          }}
        />
        <AdminForm
          isLiveApp={true}
          darkMode={darkMode}
          sectionPadding={"0px"}
          fields={fields.map((f) => ({ ...f, value: get(localData, f.id) }))}
          onChange={(k, v) => {
            setLocalData({ ...localData, [k]: v });
            setChanges(true);
          }}
          submitText={get(translations, "saveProfile", "Save Profile")}
          disableSubmit={!changes}
          submit={() => {
            setUser({ ...user, ...localData });
            apiRequest.post("/update_user/", localData);
            successNotification("Profile Updated");
          }}
        />
      </Container>
      <Container darkMode={darkMode}>
        <Text
          data={{
            text: get(translations, "editProfile", "Edit Profile"),
            fontStyle: "headingLg",
            margin: "0 0 15px 0",
            color: darkMode ? "white" : colors.default,
          }}
        />
        <Password width={"400px"} darkMode={darkMode} isLiveApp={true} />
      </Container>
    </ClientNavigationWrapper>
  );
};

export default EditActiveUser;

const Container = styled.div`
  background: ${(p) => (p.darkMode ? colors.darkModeBackground : "white")};
  box-shadow: ${(p) => (p.darkMode ? "none" : boxShadow.card)};
  border: 1px solid ${(p) => (p.darkMode ? colors.darkModeLightBorder : "none")};
  border-radius: 10px;
  flex: 1;
  padding: 30px;
  width: 450px;
  @media (max-width: 768px) {
    width: 100%;
    padding: 15px;
  }
`;
